import {
  DETAIL_PERKULIAHAN_START,
  DETAIL_PERKULIAHAN_SUCCESS,
  DETAIL_PERKULIAHAN_FAIL,
  DETAIL_PER_WEEK_START,
  DETAIL_PER_WEEK_SUCCESS,
  DETAIL_PER_WEEK_FAIL,
} from "./types";
import axios from "../../../api/axios-orders";
import { setAlert } from "../alert";

export const getDetailPerkuliahaan = (id_class) => async (dispatch) => {
  dispatch({
    type: DETAIL_PERKULIAHAN_START,
  });
  try {
    const response = await axios.get(`/api/class/${id_class}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({
      type: DETAIL_PERKULIAHAN_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({
      type: DETAIL_PERKULIAHAN_FAIL,
      payload: error.response.message,
    });
    dispatch(setAlert("Can't get data detail", "error"));
  }
};

export const getDetailPerWeek = (id_class) => async (dispatch) => {
  dispatch({
    type: DETAIL_PER_WEEK_START,
  });
  try {
    const response = await axios.get(`/api/class/${id_class}/per-week`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({
      type: DETAIL_PER_WEEK_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({
      type: DETAIL_PER_WEEK_FAIL,
      payload: error.response.message,
    });
    dispatch(setAlert("Can't get data detail", "error"));
  }
};
