import {
  SET_DETAIL_MATA_KULIAH_START,
  SET_DETAIL_MATA_KULIAH_SUCCESS,
  SET_DETAIL_MATA_KULIAH_FAIL,
} from "../../actions/admin/actionTypes";
import { updateObject } from "../../../utils";

const initialState = {
  loading: false,
  data: [],
  error: false,
};

const detailMataKuliahStart = (state, action) => {
  return updateObject(state, {
    ...state,
    loading: true,
    error: false,
  });
};

const detailMataKuliahSuccess = (state, action) => {
  return updateObject(state, {
    data: action.payload,
    current_page: action.current_page,
    has_previous_page: action.has_previous_page,
    has_next_page: action.has_next_page,
    previous_page: action.previous_page,
    next_page: action.next_page,
    last_page: action.last_page,
    total_item: action.total_item,
    loading: false,
    error: false,
  });
};

const detailMataKuliahFail = (state, action) => {
  return updateObject(state, {
    ...state,
    loading: false,
    error: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DETAIL_MATA_KULIAH_START:
      return detailMataKuliahStart(state, action);
    case SET_DETAIL_MATA_KULIAH_SUCCESS:
      return detailMataKuliahSuccess(state, action);
    case SET_DETAIL_MATA_KULIAH_FAIL:
      return detailMataKuliahFail(state, action);
    default:
      return state;
  }
};

export default reducer;
