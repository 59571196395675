import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

// component
import {
  TableUniversitas,
  DialogCreate,
  DialogUpdate,
  DialogDelete,
} from "./component";

// actions
import {
  getUniversitas,
  createUniversitas,
  updateUniversitas,
  deleteUniversitas,
  syncUniversitas,
} from "../../../store/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: 5,
    },
  },

  searchContainer: {
    [theme.breakpoints.up("md")]: {
      textAlign: "end",
    },
    [theme.breakpoints.only("sm")]: {
      textAlign: "center",
    },
  },

  searchField: {
    width: 300,
  },

  title: {
    fontWeight: 700,
    marginBottom: theme.spacing(5),
  },

  mb: {
    marginBottom: theme.spacing(5),
  },

  pagination: {
    padding: theme.spacing(0.5),
    "& .MuiPagination-ul": {
      justifyContent: "center",
    },
  },
}));

const Universitas = ({
  getUniversitas,
  createUniversitas,
  updateUniversitas,
  deleteUniversitas,
  universitas,
  syncUniversitas,
}) => {
  const classes = useStyles();

  const [page, setPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [dialogCreate, setDialogCreate] = useState({ open: false });
  const [dialogUpdate, setDialogUpdate] = useState({ open: false, data: null });
  const [dialogDelete, setDialogDelete] = useState({ open: false, id: null });

  const handlePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    getUniversitas(page, rowPerPage);
  }, [getUniversitas, page, rowPerPage]);

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        Daftar Universitas
      </Typography>
      <Grid className={classes.mb} container spacing={2}>
        <Grid item xs={12} md={6}>
          {/* <Button
            variant="contained"
            color="secondary"
            onClick={() => setDialogCreate({ open: true })}
          >
            Tambah Universitas
          </Button> */}
        </Grid>
      </Grid>
      <Card>
        <CardContent>
          <TableUniversitas
            data={universitas}
            setDialogUpdate={setDialogUpdate}
            setDialogDelete={setDialogDelete}
            syncUniversitas={syncUniversitas}
          />
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.pagination}
          >
            <Grid item xs={12}>
              <Pagination
                count={universitas.last_page}
                page={page}
                onChange={handlePage}
                color="primary"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <DialogCreate
        dialog={dialogCreate}
        onClose={() => setDialogCreate({ open: false })}
        action={createUniversitas}
      />
      <DialogUpdate
        dialog={dialogUpdate}
        onClose={() => setDialogUpdate({ open: false, data: null })}
        action={updateUniversitas}
      />
      <DialogDelete
        dialog={dialogDelete}
        onClose={() => setDialogDelete({ open: false, id: null })}
        action={deleteUniversitas}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  universitas: state.universitas,
});

export default connect(mapStateToProps, {
  getUniversitas,
  createUniversitas,
  updateUniversitas,
  deleteUniversitas,
  syncUniversitas,
})(Universitas);
