import {
  RIWAYAT_START,
  RIWAYAT_SUCCESS,
  RIWAYAT_FAIL,
  RIWAYAT_DETAIL_START,
  RIWAYAT_DETAIL_SUCCESS,
  RIWAYAT_DETAIL_FAIL,
} from "../../actions/mahasiswa/types";
import { updateObject } from "../../../utils";

const initialState = {
  data: [],
  loading: false,
  error: false,
  last_id: "",
  detail: {
    data: null,
    loading: false,
    error: false,
  },
};

const riwayatStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: false,
  });
};

const riwayatSuccess = (state, action) => {
  return updateObject(state, {
    data: action.payload,
    last_id: action.last_id,
    loading: false,
  });
};

const riwayatFail = (state, action) => {
  return updateObject(state, {
    data: [],
    last_id: "",
    loading: false,
    error: true,
  });
};

const riwayatDetailStart = (state, action) => {
  return updateObject(state, {
    detail: {
      ...state.detail,
      loading: true,
      error: false,
    },
  });
};

const riwayatDetailSuccess = (state, action) => {
  return updateObject(state, {
    detail: {
      ...state.detail,
      loading: false,
      data: action.payload,
    },
  });
};

const riwayatDetailFail = (state, action) => {
  return updateObject(state, {
    detail: {
      ...state.detail,
      loading: false,
      error: true,
    },
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RIWAYAT_START:
      return riwayatStart(state, action);
    case RIWAYAT_SUCCESS:
      return riwayatSuccess(state, action);
    case RIWAYAT_FAIL:
      return riwayatFail(state, action);
    case RIWAYAT_DETAIL_START:
      return riwayatDetailStart(state, action);
    case RIWAYAT_DETAIL_SUCCESS:
      return riwayatDetailSuccess(state, action);
    case RIWAYAT_DETAIL_FAIL:
      return riwayatDetailFail(state, action);

    default:
      return state;
  }
};

export default reducer;
