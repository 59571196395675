import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Avatar } from "@material-ui/core";
import Logo from "../../../../assets/img/logo.png";

// import { connect } from 'react-redux'
// import { getProfile } from '../../../../actions/profile'

const nameColorWhite = "#fff";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
    marginTop: theme.spacing(0),
    padding: theme.spacing(0),
  },
  img: {
    position: "absolute",
  },
}));

const Profile = (props) => {
  const { className, classRoot, username, useremail, ...rest } = props;

  const classes = useStyles();

  return (
    <RouterLink to="/">
      <div {...rest} className={clsx(classes.root, classRoot)}>
        <img
          src={Logo}
          alt="logo-bmb"
          className={clsx(classes.img, className)}
        />
      </div>
    </RouterLink>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
};

export default Profile;
