import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import LinesEllipsis from "react-lines-ellipsis";

import CustomRouterLink from "../../../../components/CustomRouterLink";
import { LoadPerkuliahan } from "../Skeleton";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 1, 0),
  },
  containerEmpty: {
    height: "200px",
  },
  card: {
    borderRadius: theme.spacing(1),
  },
  media: {
    width: "100%",
    height: "auto",
    aspectRatio: "16/9",
    display: "block",
    position: "relative",
  },
  textPerkuliahan: {
    fontWeight: "bold",
    fontSize: "16px",
    marginBottom: "0px",
    textAlign: "center",
    letterSpacing: "-0.05px",
    lineHeight: "21px",
  },
  textUniversitas: {
    fontSize: "14px",
    fontWeight: 500,
    textAlign: "center",
    letterSpacing: "-0.05px",
    lineHeight: "21px",
  },
  textJadwal: {
    marginTop: theme.spacing(1),
    fontWeight: 400,
    fontSize: "14px",
  },
  containCourse: {
    height: "45px",
  },
  content: {
    height: "150px",
    padding: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1, 0.5, 0, 0.5),
      height: "180px",
    },
  },
  statusbg: {
    width: "100px",
    padding: theme.spacing(0.5),
    borderRadius: "0 3px 3px 0",
    position: "absolute",
    bottom: 0,
  },
  open: {
    backgroundColor: "#2cb978",
    color: "#fff",
  },
  close: {
    backgroundColor: "#c03546",
    color: "#fff",
  },
  soon: {
    backgroundColor: "#7d7d7d",
    color: "#fff",
  },
  textStatus: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "13px",
  },
  disable: {
    opacity: 0.5,
  },
}));

const ListPerkuliahan = ({ list, xs, sm, md, lg }) => {
  const classes = useStyles();
  return (
    <Grid container justify="center">
      {list.data.map((value) => {
        // let logo = value.logo;

        // logoClass.map((e) => {
        //   if (e.class_id === value.class_id) {
        //     logo = e.image.default;
        //   }
        // });
        return (
          <Grid
            item
            lg={lg}
            md={md}
            sm={sm}
            xs={xs}
            key={value.class_uuid}
            className={classes.container}
          >
            <Card
              className={clsx(
                classes.card,
                value.status === "soon" && classes.disable,
                value.status === "close" && classes.disable
              )}
            >
              <CardActionArea
                component={CustomRouterLink}
                to={`/perkuliahan/${value.class_uuid}`}
                disabled={value.status === "soon" || value.status === "close"}
              >
                <CardMedia
                  className={classes.media}
                  title="Image Perkuliahan"
                  image={
                    value.course_image !== "" ? value.course_image : value.logo
                  }
                >
                  <div
                    className={clsx(
                      classes.statusbg,
                      value.status === "open" && classes.open,
                      value.status === "close" && classes.close,
                      value.status === "soon" && classes.soon
                    )}
                  >
                    <p className={classes.textStatus}>
                      {value.status === "soon"
                        ? "Belum ada Penjadwalan"
                        : value.status}
                    </p>
                  </div>
                </CardMedia>

                <CardContent className={classes.content}>
                  <div className={classes.containCourse}>
                    <LinesEllipsis
                      className={classes.textPerkuliahan}
                      text={`${value.course_name} (${value.class_code})`}
                      maxLine="2"
                      ellipsis="..."
                      trimRight
                      basedOn="letters"
                    />
                  </div>
                  <LinesEllipsis
                    className={classes.textUniversitas}
                    text={value.university_name}
                    maxLine="2"
                    ellipsis="..."
                    trimRight
                    basedOn="letters"
                  />
                  <Typography
                    gutterBottom
                    align="center"
                    variant="subtitle2"
                    className={classes.textJadwal}
                  >
                    {`${value.day}, ${value.period.slice(
                      0,
                      5
                    )}-${value.period.slice(9, 14)}`}
                  </Typography>
                  <Grid container justify="center">
                    <Rating name="rating" value={value.rating} readOnly />
                  </Grid>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        );
      })}
      {list.loading &&
        Array(3)
          .fill("a")
          .map((_, index) => (
            <Grid
              item
              lg={lg}
              md={md}
              sm={sm}
              xs={xs}
              key={index}
              className={classes.container}
            >
              <LoadPerkuliahan />
            </Grid>
          ))}
      {!list.loading && list.data.length === 0 && (
        <Grid
          container
          className={classes.containerEmpty}
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12}>
            <Typography align="center">Data tidak tersedia</Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(ListPerkuliahan);
