import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  CircularProgress,
  Grid,
  Button,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

import { TableHead } from "./component";
import { CustomRouterLink } from "../../../../../components";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    padding: theme.spacing(0.5),
    "& .MuiPagination-ul": {
      justifyContent: "center",
    },
  },
}));

const TableProdiPenyedia = ({ data, page, handlePage }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableHead />
            <TableBody>
              {data.loading ? (
                <TableRow>
                  <TableCell align="center" colSpan={10}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : data.data.length > 0 ? (
                data.data.map((row, index) => {
                  return (
                    <TableRow tabIndex={-1} key={index}>
                      <TableCell>{row.faculty_name}</TableCell>
                      <TableCell>{row.major_name}</TableCell>
                      <TableCell>{row.course_code}</TableCell>
                      <TableCell>{row.course_name}</TableCell>
                      <TableCell>{row.total_sks}</TableCell>
                      <TableCell>{row.semester}</TableCell>
                      <TableCell>{row.lecturer}</TableCell>
                      <TableCell>
                        {row.university_taker.map(
                          (value, index) =>
                            `${value.name}${
                              index + 1 === row.university_taker.length
                                ? ""
                                : ","
                            } `
                        )}
                        {row.university_taker.length === 0 && "-"}
                      </TableCell>
                      <TableCell>
                        <Button
                          color="primary"
                          component={CustomRouterLink}
                          to={`/prodi/detail-jumlah-mahasiswa/${row.uuid}`}
                        >
                          {row.total_student}
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={10}>
                    Data Kosong
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.pagination}
        >
          <Grid item xs={8}>
            <Pagination
              className={classes.pagination}
              count={data.last_page}
              page={page}
              onChange={handlePage}
              color="primary"
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default TableProdiPenyedia;
