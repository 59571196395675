import {
  SET_PRODI_MATA_KULIAH_START,
  SET_PRODI_MATA_KULIAH_SUCCESS,
  SET_PRODI_MATA_KULIAH_FAIL,
} from "../prodi/types";
import axios from "../../../api/axios-orders";
import { setAlert } from "../alert";

export const setProdiMataKuliahStart = () => {
  return {
    type: SET_PRODI_MATA_KULIAH_START,
  };
};

export const getProdiMataKuliah =
  ({ page = 1, size = 10, search = "" }) =>
  async (dispatch) => {
    dispatch(setProdiMataKuliahStart());
    try {
      const res = await axios.get(
        `major/course?page=${page}&page_size=${size}&search=${search}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      dispatch({
        type: SET_PRODI_MATA_KULIAH_SUCCESS,
        payload: res.data.data,
        current_page: page,
        has_previous_page: res.data.has_previous_page,
        has_next_page: res.data.has_next_page,
        previous_page: res.data.previous_page,
        next_page: res.data.next_page,
        last_page: res.data.last_page,
        total_item: res.data.total_item,
      });
    } catch (error) {
      dispatch({
        type: SET_PRODI_MATA_KULIAH_FAIL,
        payload: error.response.data.message,
      });
      dispatch(setAlert("Can't get data!", "error"));
    }
  };

export const uploadLogoMataKuliah =
  (uuid, data, history) => async (dispatch) => {
    dispatch(setProdiMataKuliahStart());
    try {
      await axios.patch(`/major/course/${uuid}`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      dispatch(setAlert("Logo Berhasil Di Upload", "success"));
      history.push("/prodi/mata-kuliah");
      dispatch(getProdiMataKuliah({}));
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };
