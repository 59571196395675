import {
  SET_ARTICLE,
  SET_ARTICLE_ONE,
  SET_ARTICLE_CATEGORIES,
  SET_LOADING,
  SET_LOADING_DONE,
} from "./actionTypes";
import axios from "../../../api/axios-orders";
import { setAlert } from "../alert";

export const setLoading = () => {
  return {
    type: SET_LOADING,
  };
};

export const setLoadingDone = () => {
  return {
    type: SET_LOADING_DONE,
  };
};

export const getArticle =
  (page = 1, size = 10, search = "") =>
  async (dispatch) => {
    try {
      const res = await axios.get(
        `/admin/article?page=${page}&page_size=${size}&search=${search}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      dispatch({
        type: SET_ARTICLE,
        payload: res.data.data,
        current_page: page,
        has_previous_page: res.data.has_previous_page,
        has_next_page: res.data.has_next_page,
        previous_page: res.data.previous_page,
        next_page: res.data.next_page,
        last_page: res.data.last_page,
        total_item: res.data.total_item,
      });
    } catch (error) {
      dispatch(setAlert("Can't get data article!", "error"));
    }
  };

export const getArticleOne = (slug, history) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/article/${slug}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({
      type: SET_ARTICLE_ONE,
      payload: res.data.data,
    });
  } catch (error) {
    if (error.response.status === 404) {
      return history.push("/not-found");
    }
    dispatch(setAlert("Can't get data article!", "error"));
  }
};

export const getArticleCategories = () => async (dispatch) => {
  dispatch(setLoading());
  try {
    const res = await axios.get(`/admin/article/category`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({
      type: SET_ARTICLE_CATEGORIES,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch(setAlert("Can't get data categories article!", "error"));
  }
};

export const createArticle = (data, history) => async (dispatch) => {
  dispatch(setLoading());
  try {
    await axios.post("/admin/article", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    dispatch(setAlert("Artikel Berhasil Dibuat", "success"));
    history.push("/admin/manage-artikel");
    dispatch(getArticle());
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
  }
};

export const editArticle = (data, id, history) => async (dispatch) => {
  dispatch(setLoading());
  try {
    await axios.put(`/admin/article/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    dispatch(setAlert("Artikel Berhasil Di Update", "success"));
    history.push("/admin/manage-artikel");
    dispatch(getArticle());
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
  }
};

export const deleteArticle = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`/admin/article/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    dispatch(setAlert("Artikel Berhasil Di Hapus", "success"));
    dispatch(getArticle());
  } catch (error) {
    dispatch(setAlert("Artikel Gagal Di Hapus", "error"));
  }
};
