import {
  SET_PRINT_START,
  SET_PRINT_FAIL,
  SET_PRINT_SUCCESS,
} from "../actions/actionTypes";
import { updateObject } from "../../utils";

const initialState = {
  loadingPrint: false,
  errorPrint: false,
};

const printStart = (state, action) => {
  return updateObject(state, {
    ...state,
    loadingPrint: true,
    errorPrint: false,
  });
};

const printSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    loadingPrint: false,
    errorPrint: false,
  });
};

const printFail = (state, action) => {
  return updateObject(state, {
    ...state,
    loadingPrint: false,
    errorPrint: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRINT_START:
      return printStart(state, action);
    case SET_PRINT_FAIL:
      return printFail(state, action);
    case SET_PRINT_SUCCESS:
      return printSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
