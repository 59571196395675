import {
  SET_DASHBOARD_CHART_UNIVERSITAS_START,
  SET_DASHBOARD_CHART_UNIVERSITAS_SUCCESS,
  SET_DASHBOARD_CHART_UNIVERSITAS_FAIL,
  SET_DASHBOARD_CHART_PRODI_START,
  SET_DASHBOARD_CHART_PRODI_SUCCESS,
  SET_DASHBOARD_CHART_PRODI_FAIL,
  SET_DATA_CHART_UNIVERSITAS,
  SET_DATA_CHART_PRODI,
} from "../../actions/admin/actionTypes";
import { updateObject } from "../../../utils";

const initialState = {
  loadingChartUniversitas: false,
  dataChartUniversitas: {},
  dataUniversitas: [],
  dataProdi: [],
  errorChartUniversitas: false,
  loadingChartProdi: false,
  dataChartProdi: {},
  errorChartProdi: false,
};

// Function for dashboard chart university

const dashboardChartUniversitasStart = (state, action) => {
  return updateObject(state, {
    ...state,
    loadingChartUniversitas: true,
    errorChartUniversitas: false,
  });
};

const dashboardChartUniversitasSuccess = (state, action) => {
  return updateObject(state, {
    dataChartUniversitas: action.payload,
    loadingChartUniversitas: false,
    errorChartUniversitas: false,
  });
};

const dataChartUniversitas = (state, action) => {
  return updateObject(state, {
    dataUniversitas: action.payload,
  });
};

const dashboardChartUniversitasFail = (state, action) => {
  return updateObject(state, {
    ...state,
    loadingChartUniversitas: false,
    errorChartUniversitas: true,
  });
};

// Function for dashboard chart major

const dashboardChartProdiStart = (state, action) => {
  return updateObject(state, {
    ...state,
    loadingChartProdi: true,
    errorChartProdi: false,
  });
};

const dataChartProdi = (state, action) => {
  return updateObject(state, {
    dataProdi: action.payload,
  });
};

const dashboardChartProdiSuccess = (state, action) => {
  return updateObject(state, {
    dataChartProdi: action.payload,
    loadingChartProdi: false,
    errorChartProdi: false,
  });
};

const dashboardChartProdiFail = (state, action) => {
  return updateObject(state, {
    ...state,
    loadingChartProdi: false,
    errorChartProdi: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA_CHART_UNIVERSITAS:
      return dataChartUniversitas(state, action);
    case SET_DATA_CHART_PRODI:
      return dataChartProdi(state, action);
    case SET_DASHBOARD_CHART_UNIVERSITAS_START:
      return dashboardChartUniversitasStart(state, action);
    case SET_DASHBOARD_CHART_UNIVERSITAS_SUCCESS:
      return dashboardChartUniversitasSuccess(state, action);
    case SET_DASHBOARD_CHART_UNIVERSITAS_FAIL:
      return dashboardChartUniversitasFail(state, action);
    case SET_DASHBOARD_CHART_PRODI_START:
      return dashboardChartProdiStart(state, action);
    case SET_DASHBOARD_CHART_PRODI_SUCCESS:
      return dashboardChartProdiSuccess(state, action);
    case SET_DASHBOARD_CHART_PRODI_FAIL:
      return dashboardChartProdiFail(state, action);
    default:
      return state;
  }
};

export default reducer;
