import {
  AUTH_START,
  AUTH_FAIL,
  AUTH_MAHASISWA_PRODI_SUCCESS,
  AUTH_ADMIN_APTISI_SUCCESS,
  AUTH_GUEST_SUCCESS,
  AUTH_LOGOUT,
  SET_LOADING_PASSWORD,
  SET_LOADING_PASSWORD_DONE,
} from "./actionTypes";
import axios from "../../api/axios-orders";
import { setAlert } from "./alert";

export const authStart = () => {
  return {
    type: AUTH_START,
  };
};

export const authFail = (error) => {
  return {
    type: AUTH_FAIL,
    error: error,
  };
};

export const authMahasiswaProdiSuccess = (data) => {
  return {
    type: AUTH_MAHASISWA_PRODI_SUCCESS,
    payload: data,
  };
};

export const authAdminAptisiSuccess = (data) => {
  return {
    type: AUTH_ADMIN_APTISI_SUCCESS,
    payload: data,
  };
};

export const authGuestSuccess = (data) => {
  return {
    type: AUTH_GUEST_SUCCESS,
    payload: data,
  };
};

export const authMahasiswaProdi = (data, history) => async (dispatch) => {
  dispatch(authStart());
  try {
    const response = await axios.post("/auth/login", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (response.data.role === "mahasiswa") {
      localStorage.setItem("token_sia", response.data.token_sia);
    }
    localStorage.setItem("access_token", response.data.token.access_token);
    localStorage.setItem("role", response.data.role);
    dispatch(authMahasiswaProdiSuccess(response.data));
    history.push("/");
  } catch (error) {
    dispatch(authFail(null));
    dispatch(setAlert("Email atau Password Salah", "error"));
  }
};

export const authAdminAptisi = (data, history) => async (dispatch) => {
  dispatch(authStart());
  try {
    const response = await axios.post("/auth/login/admin", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const role_name = response.data.data.role;
    localStorage.setItem("access_token", response.data.token.access_token);
    localStorage.setItem("data", JSON.stringify(response.data.data));
    localStorage.setItem("role", response.data.data.role);

    dispatch(authAdminAptisiSuccess(response.data));
    if (role_name === "admin") {
      history.push("/admin");
    } else {
      history.push("/aptisi");
    }
  } catch (error) {
    dispatch(authFail(null));
    dispatch(setAlert("Email atau Password Salah", "error"));
  }
};

export const authGuest = (token, history) => async (dispatch) => {
  try {
    if (token === undefined) {
      return;
    }
    const response = await axios.get(`/auth/callback/google/${token}`);
    localStorage.setItem("access_token", response.data.token.access_token);
    localStorage.setItem("data", JSON.stringify(response.data.data));
    localStorage.setItem("role", "guest");
    dispatch(authGuestSuccess(response.data));
    history.push("/");
  } catch (error) {
    dispatch(setAlert("Something wen't wrong", "error"));
  }
};

export const authLogout = () => {
  return {
    type: AUTH_LOGOUT,
  };
};

export const changePassword = (data) => async (dispatch) => {
  dispatch({
    type: SET_LOADING_PASSWORD,
  });
  try {
    await axios.patch(`/aptisi/user/password/change`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    dispatch({
      type: SET_LOADING_PASSWORD_DONE,
    });
    dispatch(setAlert("Password Berhasil Di Ubah", "success"));
  } catch (error) {
    dispatch({
      type: SET_LOADING_PASSWORD_DONE,
    });
    if (
      error.response.data.message ===
      "crypto/bcrypt: hashedPassword is not the hash of the given password"
    ) {
      return dispatch(setAlert("Password Salah", "error"));
    }
    dispatch(setAlert("Password Gagal Di Ubah", "error"));
  }
};
