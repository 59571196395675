// LOADING
export const SET_LOADING = "SET_LOADING";
export const SET_LOADING_DONE = "SET_LOADING_DONE";

// ARTICLE
export const SET_ARTICLE = "SET_ARTICLE";
export const SET_ARTICLE_ONE = "SET_ARTICLE_ONE";
export const SET_ARTICLE_CATEGORIES = "SET_ARTICLE_CATEGORIES";

// BANNER
export const SET_BANNER = "SET_BANNER";
export const SET_LOADING_UPLOAD = "SET_LOADING_UPLOAD";

// MANAGEMENT USER
export const SET_USER = "SET_USER";

// UNIVERSITAS
export const SET_UNIVERSITAS = "SET_UNIVERSITAS";

// FILTER
export const FILTER_UNIVERSITAS_START = "FILTER_UNIVERSITAS_START";
export const FILTER_UNIVERSITAS_SUCCESS = "FILTER_UNIVERSITAS_SUCCESS";
export const FILTER_UNIVERSITAS_FAIL = "FILTER_UNIVERSITAS_FAIL";
export const FILTER_FAKULTAS_START = "FILTER_FAKULTAS_START";
export const FILTER_FAKULTAS_SUCCESS = "FILTER_FAKULTAS_SUCCESS";
export const FILTER_FAKULTAS_FAIL = "FILTER_FAKULTAS_FAIL";
export const FILTER_PRODI_START = "FILTER_PRODI_START";
export const FILTER_PRODI_SUCCESS = "FILTER_PRODI_SUCCESS";
export const FILTER_PRODI_FAIL = "FILTER_PRODI_FAIL";
export const FILTER_MATA_KULIAH_START = "FILTER_MATA_KULIAH_START";
export const FILTER_MATA_KULIAH_SUCCESS = "FILTER_MATA_KULIAH_SUCCESS";
export const FILTER_MATA_KULIAH_FAIL = "FILTER_MATA_KULIAH_FAIL";
export const CLEAR_FILTER_UNIVERSITAS = "CLEAR_FILTER_UNIVERSITAS";
export const CLEAR_FILTER_FAKULTAS = "CLEAR_FILTER_FAKULTAS";
export const CLEAR_FILTER_PRODI = "CLEAR_FILTER_PRODI";

// TRANSAKSI
export const SET_TRANSAKSI_START = "SET_TRANSAKSI_START";
export const SET_TRANSAKSI_SUCCESS = "SET_TRANSAKSI_SUCCESS";
export const SET_TRANSAKSI_FAIL = "SET_TRANSAKSI_FAIL";
export const SET_PRINT_START = "SET_PRINT_START";
export const SET_PRINT_SUCCESS = "SET_PRINT_SUCCESS";
export const SET_PRINT_FAIL = "SET_PRINT_FAIL";

// DETAIL_MATA_KULIAH
export const SET_DETAIL_MATA_KULIAH_START = "SET_DETAIL_MATA_KULIAH_START";
export const SET_DETAIL_MATA_KULIAH_SUCCESS = "SET_DETAIL_MATA_KULIAH_SUCCESS";
export const SET_DETAIL_MATA_KULIAH_FAIL = "SET_DETAIL_MATA_KULIAH_FAIL";

// DETAIL_JUMLAH_MAHASISWA
export const SET_DETAIL_JUMLAH_MAHASISWA_START =
  "SET_DETAIL_JUMLAH_MAHASISWA_START";
export const SET_DETAIL_JUMLAH_MAHASISWA_SUCCESS =
  "SET_DETAIL_JUMLAH_MAHASISWA_SUCCESS";
export const SET_DETAIL_JUMLAH_MAHASISWA_FAIL =
  "SET_DETAIL_JUMLAH_MAHASISWA_FAIL";
export const SET_DETAIL_JUMLAH_MAHASISWA_CHART_START =
  "SET_DETAIL_JUMLAH_MAHASISWA_CHART_START";
export const SET_DETAIL_JUMLAH_MAHASISWA_CHART_SUCCESS =
  "SET_DETAIL_JUMLAH_MAHASISWA_CHART_SUCCESS";
export const SET_DETAIL_JUMLAH_MAHASISWA_CHART_FAIL =
  "SET_DETAIL_JUMLAH_MAHASISWA_CHART_FAIL";

// DASHBOARD
export const SET_DATA_CHART_UNIVERSITAS = "SET_DATA_CHART_UNIVERSITAS";
export const SET_DATA_CHART_PRODI = "SET_DATA_CHART_PRODI";
export const SET_DASHBOARD_CHART_UNIVERSITAS_START =
  "SET_DASHBOARD_CHART_UNIVERSITAS_START";
export const SET_DASHBOARD_CHART_UNIVERSITAS_FAIL =
  "SET_DASHBOARD_CHART_UNIVERSITAS_FAIL";
export const SET_DASHBOARD_CHART_UNIVERSITAS_SUCCESS =
  "SET_DASHBOARD_CHART_UNIVERSITAS_SUCCESS";
export const SET_DASHBOARD_CHART_PRODI_START =
  "SET_DASHBOARD_CHART_PRODI_START";
export const SET_DASHBOARD_CHART_PRODI_SUCCESS =
  "SET_DASHBOARD_CHART_PRODI_SUCCESS";
export const SET_DASHBOARD_CHART_PRODI_FAIL = "SET_DASHBOARD_CHART_PRODI_FAIL";
