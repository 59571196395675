import {
  SHOW_ARTIKEL_START,
  SET_SHOW_ARTIKEL,
  SHOW_ARTIKEL_FAIL,
  SHOW_ONE_ARTIKEL_START,
  SET_ONE_SHOW_ARTIKEL,
  SHOW_ONE_ARTIKEL_FAIL,
  NEWS_ARTIKEL_START,
  SET_NEWS_ARTIKEL,
  NEWS_ARTIKEL_FAIL,
} from "./types";
import axios from "../../../api/axios-orders";
import { setAlert } from "../alert";

export const getShowArtikel = (article, limit) => async (dispatch) => {
  dispatch({
    type: SHOW_ARTIKEL_START,
  });
  try {
    const response = await axios.get(
      `api/article?article=${article}&limit=${limit}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    const data = response.data.data;
    let last_id = "";
    if (data.length !== 0) {
      last_id = data[data.length - 1].id;
    }
    dispatch({
      type: SET_SHOW_ARTIKEL,
      payload: data,
      last_id: last_id,
      hasMore: data.length === limit,
    });
  } catch (error) {
    dispatch({
      type: SHOW_ARTIKEL_FAIL,
      // payload: error.response.data.message,
    });
    dispatch(setAlert("Can't get data article", "error"));
  }
};

export const getOneShowArtikel = (slug) => async (dispatch) => {
  dispatch({
    type: SHOW_ONE_ARTIKEL_START,
  });
  try {
    const response = await axios.get(`/api/article/${slug}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({
      type: SET_ONE_SHOW_ARTIKEL,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({
      type: SHOW_ONE_ARTIKEL_FAIL,
      payload: error.response.message,
    });
    dispatch(setAlert("Can't get data article", "error"));
  }
};

export const getNewsArtikel = () => async (dispatch) => {
  dispatch({
    type: NEWS_ARTIKEL_START,
  });
  try {
    const response = await axios.get(`api/article?limit=3`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({
      type: SET_NEWS_ARTIKEL,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({
      type: NEWS_ARTIKEL_FAIL,
      // payload: error.response.data.message,
    });
    dispatch(setAlert("Can't get data article", "error"));
  }
};
