/* eslint-disable array-callback-return */
import {
  RIWAYAT_START,
  RIWAYAT_SUCCESS,
  RIWAYAT_FAIL,
  RIWAYAT_DETAIL_START,
  RIWAYAT_DETAIL_SUCCESS,
  RIWAYAT_DETAIL_FAIL,
} from './types';
import axios from '../../../api/axios-orders';
import { setAlert } from '../alert';

export const getRiwayat =
  (limit = '', last_id = '') =>
  async (dispatch) => {
    dispatch({
      type: RIWAYAT_START,
    });
    try {
      const response = await axios.get(
        `/student/transaction/history?limit=${limit}&id=${last_id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      if (response.data.data === null) {
        dispatch({
          type: RIWAYAT_SUCCESS,
          payload: [],
        });
      } else {
        dispatch({
          type: RIWAYAT_SUCCESS,
          payload: response.data.data,
        });
      }
    } catch (error) {
      dispatch({
        type: RIWAYAT_FAIL,
      });
      dispatch(setAlert("Can't get data history", 'error'));
    }
  };

export const getRiwayatDetail = (uuid) => async (dispatch) => {
  dispatch({
    type: RIWAYAT_DETAIL_START,
  });
  try {
    const response = await axios.get(
      `/student/transaction/history/${uuid}/detail`,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }
    );
    dispatch({
      type: RIWAYAT_DETAIL_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({
      type: RIWAYAT_DETAIL_FAIL,
    });
    dispatch(setAlert("something wen't wrong", 'error'));
  }
};

export const sendRatingKelas =
  (data, class_uuid, transaksi_uuid, history) => async (dispatch) => {
    try {
      await axios.post(`/student/class/${class_uuid}/rate`, data, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      });
      history.push(`/riwayat/${transaksi_uuid}`);
      dispatch(setAlert('Rating dan ulasan berhasil dikirim', 'success'));
    } catch (error) {
      if (error.response.data.message === "can't store rate twice") {
        dispatch(
          setAlert('Anda tidak bisa memberika rating dua kali', 'error')
        );
      } else {
        dispatch(setAlert("something wen't wrong", 'error'));
      }
    }
  };
