import {
  SET_DATA_MATA_KULIAH,
  SET_DATA_UNIVERSITAS,
  SET_DASHBOARD_CHART_MATA_KULIAH_START,
  SET_DASHBOARD_CHART_MATA_KULIAH_SUCCESS,
  SET_DASHBOARD_CHART_MATA_KULIAH_FAIL,
  SET_DASHBOARD_CHART_UNIVERSITAS_START,
  SET_DASHBOARD_CHART_UNIVERSITAS_SUCCESS,
  SET_DASHBOARD_CHART_UNIVERSITAS_FAIL,
} from "../../actions/prodi/types";
import { updateObject } from "../../../utils";

const initialState = {
  dataMataKuliah: [],
  dataUniversitas: [],
  loadingChartMataKuliah: false,
  dataChartMataKuliah: {},
  errorChartMataKuliah: false,
  loadingChartUniversitas: false,
  dataChartUniversitas: {},
  errorChartUniversitas: false,
};

// Function for dashboard chart mata kuliah

const dashboardChartMataKuliahStart = (state, action) => {
  return updateObject(state, {
    ...state,
    loadingChartMataKuliah: true,
    errorChartMataKuliah: false,
  });
};

const dashboardChartMataKuliahSuccess = (state, action) => {
  return updateObject(state, {
    dataChartMataKuliah: action.payload,
    loadingChartMataKuliah: false,
    errorChartMataKuliah: false,
  });
};

const dashboardDataMataKuliah = (state, action) => {
  return updateObject(state, {
    dataMataKuliah: action.payload,
  });
};

const dashboardChartMataKuliahFail = (state, action) => {
  return updateObject(state, {
    ...state,
    loadingChartMataKuliah: false,
    errorChartMataKuliah: true,
  });
};

// Function for dashboard chart universitas

const dashboardChartUniversitasStart = (state, action) => {
  return updateObject(state, {
    ...state,
    loadingChartUniversitas: true,
    errorChartUniversitas: false,
  });
};

const dashboardChartUniversitasSuccess = (state, action) => {
  return updateObject(state, {
    dataChartUniversitas: action.payload,
    loadingChartUniversitas: false,
    errorChartUniversitas: false,
  });
};

const dashboardDataUniversitas = (state, action) => {
  return updateObject(state, {
    dataUniversitas: action.payload,
  });
};

const dashboardChartUniversitasFail = (state, action) => {
  return updateObject(state, {
    ...state,
    loadingChartUniversitas: false,
    errorChartUniversitas: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA_MATA_KULIAH:
      return dashboardDataMataKuliah(state, action);
    case SET_DATA_UNIVERSITAS:
      return dashboardDataUniversitas(state, action);
    case SET_DASHBOARD_CHART_MATA_KULIAH_START:
      return dashboardChartMataKuliahStart(state, action);
    case SET_DASHBOARD_CHART_MATA_KULIAH_SUCCESS:
      return dashboardChartMataKuliahSuccess(state, action);
    case SET_DASHBOARD_CHART_MATA_KULIAH_FAIL:
      return dashboardChartMataKuliahFail(state, action);
    case SET_DASHBOARD_CHART_UNIVERSITAS_START:
      return dashboardChartUniversitasStart(state, action);
    case SET_DASHBOARD_CHART_UNIVERSITAS_SUCCESS:
      return dashboardChartUniversitasSuccess(state, action);
    case SET_DASHBOARD_CHART_UNIVERSITAS_FAIL:
      return dashboardChartUniversitasFail(state, action);
    default:
      return state;
  }
};

export default reducer;
