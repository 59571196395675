import {
  SET_BANNER,
  SET_LOADING,
  SET_LOADING_DONE,
  SET_LOADING_UPLOAD,
} from "./actionTypes";
import axios from "../../../api/axios-orders";
import { setAlert } from "../alert";

export const setLoading = () => {
  return {
    type: SET_LOADING,
  };
};

export const setLoadingDone = () => {
  return {
    type: SET_LOADING_DONE,
  };
};

export const setLoadingUpload = () => {
  return {
    type: SET_LOADING_UPLOAD,
  };
};

export const getBanner = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/banner`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({
      type: SET_BANNER,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch(setAlert("Can't get data banner!", "error"));
  }
};

export const createBanner = (data) => async (dispatch) => {
  dispatch(setLoadingUpload());
  try {
    await axios.post("/admin/banner", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    dispatch(setAlert("Banner Berhasil Dibuat", "success"));
    dispatch(getBanner());
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
  }
};

export const deleteBanner = (id) => async (dispatch) => {
  dispatch(setLoadingUpload());
  try {
    const res = await axios.delete(`/admin/banner/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    dispatch(setAlert("Banner Berhasil Di Hapus", "success"));
    dispatch(getBanner());
  } catch (error) {
    dispatch(setAlert("Banner Gagal Di Hapus", "error"));
  }
};
