/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles, Typography, Grid, Container } from "@material-ui/core";

import { ListUniversitas } from "./component";
import clsx from "clsx";
import AOS from "aos";
import "aos/dist/aos.css";

// Global Components
import { ListPerkuliahan, Carousel, ListArtikel } from "../Components";

// Actions
import {
  getShowBanner,
  getShowUniversitas,
  getShowRekomendasi,
  getNewsArtikel,
} from "../../../store/actions/mahasiswa";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
    },
  },
  textContainer: {
    marginTop: theme.spacing(5),
  },
  textTitle: {
    fontWeight: "700",
    lineHeight: "24px",
  },
  textLink: {
    fontWeight: "700",
    lineHeight: "24px",
    color: "#0068d3",
    cursor: "pointer",
  },
  textMargin: {
    marginTop: theme.spacing(5),
  },
  subtitle: {
    fontSize: "13px",
    color: "#a4a4a4",
  },
}));

const MainPage = ({
  showBanner,
  getShowBanner,
  getNewsArtikel,
  showArtikel,
  getShowUniversitas,
  showUniversitas,
  getShowRekomendasi,
  showRekomendasi,
}) => {
  const classes = useStyles();
  const history = useHistory();
  AOS.init({
    // duration: 1000
  });

  const handleLinkPerkuliahan = () => {
    history.push("/perkuliahan");
  };

  const handleLinkArtikel = () => {
    history.push("/artikel");
  };

  useEffect(() => {
    if (showBanner.data.length === 0) {
      getShowBanner();
    }
    getNewsArtikel();
    getShowRekomendasi();
    getShowUniversitas(1, 5);
  }, []);

  return (
    <Container maxWidth="lg" className={classes.root}>
      <div data-aos="fade-up" data-aos-duration="500">
        <Carousel data={showBanner.data} />
      </div>
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        className={classes.textContainer}
      >
        <Typography variant="h4" className={classes.textTitle}>
          Rekomendasi Perkuliahan
        </Typography>
        <Grid container justify="space-between">
          <Typography
            variant="subtitle2"
            className={clsx(classes.textTitle, classes.subtitle)}
          >
            Rekomendasi berdasarkan semester
          </Typography>
          <Typography
            variant="body2"
            className={classes.textLink}
            onClick={handleLinkPerkuliahan}
          >
            Lihat Semua
          </Typography>
        </Grid>
      </div>
      <div data-aos="fade-up" data-aos-duration="1000">
        <ListPerkuliahan list={showRekomendasi} lg={2} md={3} sm={4} xs={6} />
      </div>
      <div
        data-aos="fade-up"
        data-aos-duration="1500"
        className={classes.textMargin}
      >
        <Grid container justify="space-between">
          <Typography variant="h4" className={classes.textTitle}>
            Daftar Universitas
          </Typography>
        </Grid>
      </div>
      <div data-aos="fade-up" data-aos-duration="1500">
        <ListUniversitas list={showUniversitas} />
      </div>
      <div
        className={classes.textMargin}
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <Grid container justify="space-between">
          <Typography variant="h4" className={classes.textTitle}>
            Artikel Terbaru
          </Typography>
          <Typography
            variant="body2"
            className={classes.textLink}
            onClick={handleLinkArtikel}
          >
            Lihat Semua
          </Typography>
        </Grid>
      </div>
      <div data-aos="fade-up" data-aos-duration="2000">
        <ListArtikel data={showArtikel.news} />
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  showBanner: state.showBanner,
  showArtikel: state.showArtikel,
  showUniversitas: state.showUniversitas,
  showRekomendasi: state.showRekomendasi,
});

export default connect(mapStateToProps, {
  getShowBanner,
  getNewsArtikel,
  getShowUniversitas,
  getShowRekomendasi,
})(React.memo(MainPage));
