import React, { useState, useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Hidden,
  Button,
  TextField,
  Paper,
  CircularProgress,
  MenuItem,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { GoogleLogin } from "react-google-login";

import axios from "../../api/axios-orders";
import { authMahasiswaProdi, authGuest } from "../../store/actions";
import BgLogin from "../../assets/img/bg-login.png";
import Logo from "../../assets/img/logo.png";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    width: "100%",
  },
  image: {
    backgroundImage: `url("${BgLogin}")`,
    backgroundSize: "cover",
  },
  logo: {
    width: "30%",
    height: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  center: {
    color: "#FFFFFF",
    fontSize: 50,
    paddingBottom: 15,
    lineHeight: 1,
  },
  paper: {
    margin: theme.spacing(2, 4),
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
  },
  avatar1: {
    textAlign: "center",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(5),
    },
  },
  avatar2: {
    marginTop: theme.spacing(1),
    textAlign: "center",
    fontWeight: "bold",
  },
  avatarParagraph: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    paddingTop: 30,
  },
  textField: {
    borderRadius: "10px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formLogin: {
    height: "100%",
    justifyContent: "center",
  },
  linkForgotPassword: {
    marginTop: 10,
  },
  btnGoogle: {
    margin: "8px auto",
    width: "100%",
  },
  backgroundLogin: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      height: "100%",
      objectFit: "contained",
    },
  },
}));

const validationSchema = yup.object().shape({
  university_id: yup.number().typeError("Universitas asal harus diisi"),
  username: yup.string().required("NIM harus diisi"),
  password: yup.string().required("Password harus diisi"),
});

const Login = ({ authMahasiswaProdi, auth, authGuest }) => {
  const classes = useStyles();
  const history = useHistory();

  const [universitasList, setUniversitasList] = useState([]);

  const { handleSubmit, register, errors, control } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const googleAction = (response) => {
    authGuest(response.accessToken, history);
  };

  const formSubmitHandler = (values) => {
    authMahasiswaProdi(values, history);
  };

  useEffect(() => {
    async function getUniversitas() {
      const response = await axios.get("/university/list", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      setUniversitasList(response.data.data);
    }

    getUniversitas();
  }, []);

  if (localStorage.getItem("access_token")) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Grid className={classes.container} container>
        <Hidden only={["xs", "sm"]}>
          <Grid item md={6} lg={6}>
            <img
              src={BgLogin}
              alt="login-background"
              className={classes.backgroundLogin}
            />
          </Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          component={Paper}
          square
          className={classes.formLogin}
        >
          <div className={classes.paper}>
            <Grid container>
              <Grid item xs={12}>
                <center>
                  <img src={Logo} className={classes.logo} alt="logo-bmb" />
                </center>
              </Grid>
            </Grid>
            <Typography variant="h3" className={classes.avatar1}>
              Silakan Login
            </Typography>
            <Grid container justify="center">
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <GoogleLogin
                  className={classes.btnGoogle}
                  clientId="958216117362-0ec4uel9mtocv369ctu9jci0il1uthsk.apps.googleusercontent.com"
                  onSuccess={googleAction}
                  onFailure={googleAction}
                  cookiePolicy={"single_host_origin"}
                />
              </Grid>
            </Grid>
            <form
              className={classes.form}
              onSubmit={handleSubmit(formSubmitHandler)}
            >
              <Controller
                control={control}
                name="university_id"
                defaultValue=""
                render={({ onChange, ref }) => (
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label="Asal Universitas"
                    inputRef={ref}
                    defaultValue=""
                    onChange={(e) => onChange(e.target.value)}
                    error={!!errors.university_id}
                    helperText={errors.university_id?.message}
                  >
                    {universitasList.map((data) => (
                      <MenuItem key={data.id} value={data.id}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="NIM/NIK"
                name="username"
                autoFocus
                inputRef={register}
                error={!!errors.username}
                helperText={errors.username?.message}
                // className={classes.textField}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type="password"
                autoComplete="current-password"
                inputRef={register}
                error={!!errors.password}
                helperText={errors.password?.message}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color={auth.loading ? "inherit" : "primary"}
                size="large"
                disabled={auth.loading}
                className={classes.submit}
              >
                {!auth.loading ? "LOGIN" : <CircularProgress color="primary" />}
              </Button>
            </form>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

const mapStatetoProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStatetoProps, { authMahasiswaProdi, authGuest })(
  Login
);
