import {
  SHOW_ARTIKEL_START,
  SET_SHOW_ARTIKEL,
  SHOW_ARTIKEL_FAIL,
  SHOW_ONE_ARTIKEL_START,
  SET_ONE_SHOW_ARTIKEL,
  SHOW_ONE_ARTIKEL_FAIL,
  NEWS_ARTIKEL_START,
  SET_NEWS_ARTIKEL,
  NEWS_ARTIKEL_FAIL,
} from "../../actions/mahasiswa/types";
import { updateObject } from "../../../utils";

const initialState = {
  all: {
    data: [],
    loading: false,
    error: false,
    last_id: "",
    hasMore: false,
  },
  one: {
    data: {},
    loading: false,
    error: false,
  },
  news: {
    data: [],
    loading: false,
    error: false,
  },
};

// START
const showArtikelStart = (state, action) => {
  return updateObject(state, {
    all: { ...state.all, loading: true, error: false },
  });
};

const showOneArtikelStart = (state, action) => {
  return updateObject(state, {
    one: { ...state.one, loading: true, error: false },
  });
};

const newsArtikelStart = (state, action) => {
  return updateObject(state, {
    news: { ...state.all, loading: true, error: false },
  });
};

const setShowArtikel = (state, action) => {
  return updateObject(state, {
    all: {
      ...state.all,
      data: state.all.data.concat(action.payload),
      loading: false,
      last_id: action.last_id,
      hasMore: action.hasMore,
    },
  });
};

const setOneShowArtikel = (state, action) => {
  return updateObject(state, {
    one: { ...state.one, data: action.payload, loading: false },
  });
};

const setNewsArtikel = (state, action) => {
  return updateObject(state, {
    news: { ...state.news, data: action.payload, loading: false },
  });
};

const showArtikelFail = (state, action) => {
  return updateObject(state, {
    all: { ...state.all, loading: false, error: true },
  });
};

const showOneArtikelFail = (state, action) => {
  return updateObject(state, {
    one: { ...state.all, loading: false, error: true },
  });
};

const newsArtikelFail = (state, action) => {
  return updateObject(state, {
    news: { ...state.news, loading: false, error: true },
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ARTIKEL_START:
      return showArtikelStart(state, action);
    case SHOW_ONE_ARTIKEL_START:
      return showOneArtikelStart(state, action);
    case NEWS_ARTIKEL_START:
      return newsArtikelStart(state, action);
    case SET_SHOW_ARTIKEL:
      return setShowArtikel(state, action);
    case SET_ONE_SHOW_ARTIKEL:
      return setOneShowArtikel(state, action);
    case SET_NEWS_ARTIKEL:
      return setNewsArtikel(state, action);
    case SHOW_ARTIKEL_FAIL:
      return showArtikelFail(state, action);
    case SHOW_ONE_ARTIKEL_FAIL:
      return showOneArtikelFail(state, action);
    case NEWS_ARTIKEL_FAIL:
      return newsArtikelFail(state, action);
    default:
      return state;
  }
};

export default reducer;
