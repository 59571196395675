/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import Select from "react-select";
import { TableDetailMataKuliah } from "./component";

import { connect } from "react-redux";
import {
  getFilterUniversitas,
  getFilterFakultas,
  getFilterProdi,
  getFilterMataKuliah,
  clearFilterUniversitas,
  clearFilterFakultas,
  clearFilterProdi,
  getDetailMataKuliah,
} from "../../../store/actions";

import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: 5,
    },
  },

  searchContainer: {
    [theme.breakpoints.up("md")]: {
      textAlign: "end",
    },
    [theme.breakpoints.only("sm")]: {
      textAlign: "center",
    },
  },

  searchField: {
    width: 300,
  },

  title: {
    fontWeight: 700,
    marginBottom: theme.spacing(5),
  },

  mb: {
    marginBottom: theme.spacing(5),
  },
}));

const DetailMataKuliah = ({
  getFilterUniversitas,
  getFilterFakultas,
  getFilterProdi,
  getFilterMataKuliah,
  filter,
  clearFilterUniversitas,
  clearFilterFakultas,
  clearFilterProdi,
  getDetailMataKuliah,
  detailMataKuliah,
}) => {
  const classes = useStyles();
  const fakultasRef = useRef();
  const prodiRef = useRef();
  const mataKuliahRef = useRef();
  const [universitas, setUniversitas] = useState("");
  const [fakultas, setFakultas] = useState("");
  const [prodi, setProdi] = useState("");
  const [mataKuliah, setMataKuliah] = useState("");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handlePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    getFilterUniversitas();
  }, [getFilterUniversitas]);

  useEffect(() => {
    getDetailMataKuliah({
      university_code: universitas.code,
      faculty_uuid: fakultas,
      major_uuid: prodi,
      course_name: mataKuliah,
      search: search,
      page: page,
      size: rowsPerPage,
    });
  }, [page, rowsPerPage]);

  useEffect(() => {
    setPage(1);
    getDetailMataKuliah({
      university_code: universitas.code,
      faculty_uuid: fakultas,
      major_uuid: prodi,
      course_name: mataKuliah,
      search: search,
      page: page,
      size: rowsPerPage,
    });
  }, [universitas, fakultas, prodi, mataKuliah, search]);
  return (
    <Container maxWidth="xl" className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        Perguruan Tinggi Penyedia
      </Typography>
      <Grid className={classes.mb} container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Select
                className="basic-single"
                isClearable={true}
                isSearchable={true}
                name="color"
                options={filter.universitas.data}
                onChange={(e) => {
                  if (e === null) {
                    setUniversitas("");
                    setFakultas("");
                    setProdi("");
                    setMataKuliah("");
                    clearFilterUniversitas();
                    fakultasRef.current.select.clearValue();
                    prodiRef.current.select.clearValue();
                    mataKuliahRef.current.select.clearValue();
                  } else {
                    setUniversitas({ id: e.value, code: e.code });
                    getFilterFakultas(e.value);
                    fakultasRef.current.select.clearValue();
                    prodiRef.current.select.clearValue();
                    mataKuliahRef.current.select.clearValue();
                  }
                }}
                placeholder="Universitas.."
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                className="basic-single"
                isClearable={true}
                isSearchable={true}
                ref={fakultasRef}
                name="color"
                options={filter.fakultas.data}
                onChange={(e) => {
                  if (e === null) {
                    setFakultas("");
                    setProdi("");
                    setMataKuliah("");
                    clearFilterFakultas();
                    prodiRef.current.select.clearValue();
                    mataKuliahRef.current.select.clearValue();
                  } else {
                    setFakultas(e.value);
                    getFilterProdi(universitas.id, e.value);
                    prodiRef.current.select.clearValue();
                    mataKuliahRef.current.select.clearValue();
                  }
                }}
                placeholder="Fakultas"
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                className="basic-single"
                isClearable={true}
                isSearchable={true}
                name="color"
                options={filter.prodi.data}
                placeholder="Prodi"
                ref={prodiRef}
                onChange={(e) => {
                  if (e === null) {
                    setProdi("");
                    setMataKuliah("");
                    clearFilterProdi();
                    mataKuliahRef.current.select.clearValue();
                  } else {
                    setProdi(e.value);
                    getFilterMataKuliah(universitas.id, e.value);
                    mataKuliahRef.current.select.clearValue();
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                className="basic-single"
                ref={mataKuliahRef}
                isClearable={true}
                isSearchable={true}
                name="color"
                options={filter.mataKuliah.data}
                placeholder="Nama MK"
                onChange={(e) => {
                  if (e === null) {
                    setMataKuliah("");
                  } else {
                    setMataKuliah(e.label);
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.searchContainer} xs={12} md={6}>
          <TextField
            className={classes.searchField}
            id="outlined-basic"
            label="Pencarian"
            size="medium"
            variant="outlined"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Card>
        <CardContent>
          <TableDetailMataKuliah
            data={detailMataKuliah}
            page={page}
            handlePage={handlePage}
          />
        </CardContent>
      </Card>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    filter: state.filterAdmin,
    detailMataKuliah: state.detailMataKuliah,
  };
};

export default connect(mapStateToProps, {
  getFilterUniversitas,
  getFilterFakultas,
  getFilterProdi,
  getFilterMataKuliah,
  clearFilterUniversitas,
  clearFilterFakultas,
  clearFilterProdi,
  getDetailMataKuliah,
})(DetailMataKuliah);
