/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { TableMataKuliah, DialogPreview } from "./component";

import SearchIcon from "@material-ui/icons/Search";
import { connect } from "react-redux";
import { getProdiMataKuliah, getProfileProdi } from "../../../store/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: 5,
    },
  },

  searchContainer: {
    [theme.breakpoints.up("md")]: {
      textAlign: "end",
    },
    [theme.breakpoints.only("sm")]: {
      textAlign: "center",
    },
  },

  searchField: {
    width: 300,
  },

  title: {
    fontWeight: 700,
    marginBottom: theme.spacing(5),
  },

  mb: {
    marginBottom: theme.spacing(2),
  },

  titleTable: {
    margin: theme.spacing(2, 0, 0, 2),
    fontWeight: "bold",
    fontSize: "15px",
    textAlign: "center",
  },
}));

const MataKuliah = ({
  getProdiMataKuliah,
  prodiMataKuliah,
  profileProdi,
  getProfileProdi,
}) => {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dialogPreview, setDialogPreview] = useState({
    open: false,
    data: null,
  });

  const handlePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    getProdiMataKuliah({
      search: search,
      page: page,
      size: rowsPerPage,
    });
  }, [page, rowsPerPage]);

  useEffect(() => {
    setPage(1);
    getProdiMataKuliah({
      search: search,
      page: page,
      size: rowsPerPage,
    });
  }, [search]);

  useEffect(() => {
    getProfileProdi();
  }, []);

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        {profileProdi.data?.university_name}
      </Typography>
      <Grid className={classes.mb} display="flex" container>
        <Grid item className={classes.searchContainer} xs={12}>
          <TextField
            className={classes.searchField}
            id="outlined-basic"
            label="Pencarian"
            size="medium"
            variant="outlined"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Card>
        <Typography className={classes.titleTable}>
          Mata Kuliah Prodi {profileProdi.data?.major_name}
        </Typography>
        <CardContent>
          <TableMataKuliah
            data={prodiMataKuliah}
            page={page}
            handlePage={handlePage}
            rowsPerPage={rowsPerPage}
            setDialogPreview={setDialogPreview}
          />
        </CardContent>
      </Card>
      <DialogPreview
        dialog={dialogPreview}
        onClose={() => setDialogPreview({ open: false, data: null })}
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    prodiMataKuliah: state.prodiMataKuliah,
    profileProdi: state.profileProdi,
  };
};

export default connect(mapStateToProps, {
  getProdiMataKuliah,
  getProfileProdi,
})(MataKuliah);
