/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography, Grid } from "@material-ui/core";

import {
  getShowRekomendasi,
  getRiwayat,
} from "../../../store/actions/mahasiswa";
import { ListHistory } from "./component";
import { Heading, ListPerkuliahan } from "../Components";

const useStyles = makeStyles((theme) => ({
  itemContainer: {
    marginTop: theme.spacing(5),
  },
  textContainer: {
    marginTop: theme.spacing(10),
  },
  textTitle: {
    fontWeight: "700",
    lineHeight: "24px",
  },
  textSubtitle: {
    fontWeight: "500",
    lineHeight: "24px",
  },
}));

const Riwayat = ({
  showRekomendasi,
  getShowRekomendasi,
  riwayat,
  getRiwayat,
}) => {
  const classes = useStyles();

  useEffect(() => {
    getShowRekomendasi();
    getRiwayat();
  }, []);

  return (
    <div>
      <Heading text="Riwayat" />
      <Container maxWidth="lg">
        <div className={classes.itemContainer}>
          <ListHistory list={riwayat} />
        </div>
        <div className={classes.textContainer}>
          <Typography variant="h4" className={classes.textTitle}>
            Rekomendasi Perkuliahan
          </Typography>
          <Grid container justify="space-between">
            <Typography variant="subtitle2" className={classes.textSubtitle}>
              Rekomendasi berdasarkan semester
            </Typography>
            <Typography variant="body1" className={classes.textTitle}>
              Lihat Semua
            </Typography>
          </Grid>
        </div>
        <div>
          <ListPerkuliahan list={showRekomendasi} lg={2} md={3} sm={4} xs={6} />
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  showRekomendasi: state.showRekomendasi,
  riwayat: state.riwayat,
});

export default connect(mapStateToProps, { getShowRekomendasi, getRiwayat })(
  Riwayat
);
