import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  TextField,
  InputAdornment,
  Box,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

import { connect } from "react-redux";
import {
  getDetailJumlahMahasiswa,
  getDetailJumlahMahasiswaChart,
  printDetailMahasiswa,
} from "../../../store/actions";

import SearchIcon from "@material-ui/icons/Search";
import { Doughnut } from "react-chartjs-2";

// components
import { TableDetailJumlahMahasiswa } from "./component";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: 5,
    },
  },

  searchContainer: {
    justifyContent: "end",
    display: "flex",
  },

  searchField: {
    width: 300,
  },

  title: {
    fontWeight: 700,
    marginBottom: 10,
  },

  mb: {
    marginBottom: theme.spacing(3),
  },

  btnCetak: {
    marginRight: theme.spacing(2),
  },
}));

const dataDoughnut = {
  labels: [
    "Universitas Mercu Buana",
    "Universitas Mercu Buana",
    "Universitas Mercu Buana",
    "Universitas Mercu Buana",
    "Universitas Mercu Buana",
    "Universitas Mercu Buana",
  ],
  datasets: [
    {
      label: "Prodi",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

const DetailJumlahMahasiswa = ({
  getDetailJumlahMahasiswa,
  getDetailJumlahMahasiswaChart,
  detailJumlahMahasiswa,
  printDetailMahasiswa,
  exportDetailMahasiswa,
}) => {
  const classes = useStyles();
  const params = useParams();

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handlePage = (event, newPage) => {
    setPage(newPage);
  };

  const printFile = (type) => {
    printDetailMahasiswa({
      typeFile: type,
      class_uuid: params.uuid,
    });
  };

  useEffect(() => {
    getDetailJumlahMahasiswaChart({ uuid: params.uuid });
  }, [getDetailJumlahMahasiswaChart]);

  useEffect(() => {
    getDetailJumlahMahasiswa({
      search: search,
      page: page,
      size: rowsPerPage,
      uuid: params.uuid,
    });
  }, [page, rowsPerPage]);

  useEffect(() => {
    setPage(1);
    getDetailJumlahMahasiswa({
      search: search,
      page: page,
      size: rowsPerPage,
      uuid: params.uuid,
    });
  }, [search]);
  return (
    <Container maxWidth="xl" className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        Detail Jumlah Mahasiswa
      </Typography>
      <Grid container className={classes.mb}>
        <Grid item xs={12} md={10} lg={6}>
          <Card>
            <CardContent>
              {detailJumlahMahasiswa.loadingChart ? (
                <Skeleton variant="rect" width="100%">
                  <div style={{ paddingTop: "57%" }} />
                </Skeleton>
              ) : (
                <Doughnut data={detailJumlahMahasiswa.dataChart} />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid className={classes.mb} container spacing={2}>
        <Grid item className={classes.searchContainer} xs={12}>
          <Box display="flex" alignItems="center">
            <Button
              variant="contained"
              color="secondary"
              style={{ marginRight: 8 }}
              disabled={exportDetailMahasiswa.loadingPrint ? true : false}
              onClick={() => {
                printFile("pdf");
              }}
              className={classes.btnCetak}
            >
              Cetak PDF
            </Button>
            <Button
              className={classes.btnCetak}
              onClick={() => {
                printFile("excel");
              }}
              disabled={exportDetailMahasiswa.loadingPrint ? true : false}
              variant="outlined"
              color="secondary"
            >
              Cetak Excel
            </Button>
          </Box>
          <TextField
            className={classes.searchField}
            id="outlined-basic"
            label="Pencarian"
            size="medium"
            variant="outlined"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Card>
        <CardContent>
          <TableDetailJumlahMahasiswa
            data={detailJumlahMahasiswa}
            page={page}
            handlePage={handlePage}
          />
        </CardContent>
      </Card>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    detailJumlahMahasiswa: state.detailJumlahMahasiswaAdmin,
    exportDetailMahasiswa: state.exportDetailMahasiswa,
  };
};

export default connect(mapStateToProps, {
  getDetailJumlahMahasiswa,
  getDetailJumlahMahasiswaChart,
  printDetailMahasiswa,
})(DetailJumlahMahasiswa);
