import {
  FILTER_UNIVERSITAS_START,
  FILTER_UNIVERSITAS_SUCCESS,
  FILTER_UNIVERSITAS_FAIL,
  FILTER_FAKULTAS_START,
  FILTER_FAKULTAS_SUCCESS,
  FILTER_FAKULTAS_FAIL,
  FILTER_PRODI_START,
  FILTER_PRODI_SUCCESS,
  FILTER_PRODI_FAIL,
  FILTER_MATA_KULIAH_START,
  FILTER_MATA_KULIAH_SUCCESS,
  FILTER_MATA_KULIAH_FAIL,
  CLEAR_FILTER_UNIVERSITAS,
  CLEAR_FILTER_FAKULTAS,
  CLEAR_FILTER_PRODI,
} from "../../actions/admin/actionTypes";
import { updateObject } from "../../../utils";

const initialState = {
  universitas: {
    data: [],
    loading: false,
    error: false,
  },
  fakultas: {
    data: [],
    loading: false,
    error: false,
  },
  prodi: {
    data: [],
    loading: false,
    error: false,
  },
  mataKuliah: {
    data: [],
    loading: false,
    error: false,
  },
};

// Function For Handle Filter Universitas
const universitasStart = (state, action) => {
  return updateObject(state, {
    universitas: { ...state.universitas, loading: true, error: false },
  });
};

const universitasSuccess = (state, action) => {
  return updateObject(state, {
    universitas: { data: action.payload, loading: false, error: false },
  });
};

const universitasFail = (state, action) => {
  return updateObject(state, {
    universitas: { ...state.universitas, loading: false, error: true },
  });
};

const clearFilterUniversitas = (state, action) => {
  return updateObject(state, {
    fakultas: { data: [], loading: false, error: false },
    prodi: { data: [], loading: false, error: false },
    mataKuliah: { data: [], loading: false, error: false },
  });
};

// Function For Handle Filter Fakultas

const fakultasStart = (state, action) => {
  return updateObject(state, {
    fakultas: { data: [], loading: true, error: false },
  });
};

const fakultasSuccess = (state, action) => {
  return updateObject(state, {
    fakultas: { data: action.payload, loading: false, error: false },
  });
};

const fakultasFail = (state, action) => {
  return updateObject(state, {
    fakultas: { data: [], loading: false, error: true },
  });
};

const clearFilterFakultas = (state, action) => {
  return updateObject(state, {
    ...state,
    prodi: { data: [], loading: false, error: false },
    mataKuliah: { data: [], loading: false, error: false },
  });
};

// Function For Handle Filter Program Studi

const prodiStart = (state, action) => {
  return updateObject(state, {
    prodi: { data: [], loading: true, error: false },
  });
};

const prodiSuccess = (state, action) => {
  return updateObject(state, {
    prodi: { data: action.payload, loading: false, error: false },
  });
};

const prodiFail = (state, action) => {
  return updateObject(state, {
    prodi: { data: [], loading: false, error: true },
  });
};

const clearFilterProdi = (state, action) => {
  return updateObject(state, {
    ...state,
    mataKuliah: { data: [], loading: false, error: false },
  });
};

// Function For Handle Filter Mata Kuliah

const mataKuliahStart = (state, action) => {
  return updateObject(state, {
    mataKuliah: { data: [], loading: true, error: false },
  });
};

const mataKuliahSuccess = (state, action) => {
  return updateObject(state, {
    mataKuliah: { data: action.payload, loading: false, error: false },
  });
};

const mataKuliahFail = (state, action) => {
  return updateObject(state, {
    mataKuliah: { data: [], loading: false, error: true },
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FILTER_UNIVERSITAS_START:
      return universitasStart(state, action);
    case FILTER_UNIVERSITAS_SUCCESS:
      return universitasSuccess(state, action);
    case FILTER_UNIVERSITAS_FAIL:
      return universitasFail(state, action);
    case FILTER_FAKULTAS_START:
      return fakultasStart(state, action);
    case FILTER_FAKULTAS_SUCCESS:
      return fakultasSuccess(state, action);
    case FILTER_FAKULTAS_FAIL:
      return fakultasFail(state, action);
    case FILTER_PRODI_START:
      return prodiStart(state, action);
    case FILTER_PRODI_SUCCESS:
      return prodiSuccess(state, action);
    case FILTER_PRODI_FAIL:
      return prodiFail(state, action);
    case FILTER_MATA_KULIAH_START:
      return mataKuliahStart(state, action);
    case FILTER_MATA_KULIAH_SUCCESS:
      return mataKuliahSuccess(state, action);
    case FILTER_MATA_KULIAH_FAIL:
      return mataKuliahFail(state, action);
    case CLEAR_FILTER_UNIVERSITAS:
      return clearFilterUniversitas(state, action);
    case CLEAR_FILTER_FAKULTAS:
      return clearFilterFakultas(state, action);
    case CLEAR_FILTER_PRODI:
      return clearFilterProdi(state, action);
    default:
      return state;
  }
};

export default reducer;
