import React from "react";
import { Grid } from "@material-ui/core";
import { LoadText } from "../../../../../components/Skeleton";

import TextContent from "./TextContent";

const DataProfile = ({ data }) => {
  return (
    <Grid container>
      <Grid item xs={5}>
        <TextContent>Username</TextContent>
        <TextContent>Nama Lengkap</TextContent>
        <TextContent>Perguruan Tinggi</TextContent>
        <TextContent>Fakultas</TextContent>
        <TextContent>Program Studi</TextContent>
      </Grid>
      <Grid item xs={7}>
        {data.loading ? (
          Array(9)
            .fill(1)
            .map((value, index) => (
              <TextContent key={index}>
                <LoadText />
              </TextContent>
            ))
        ) : (
          <>
            <TextContent>{data.data?.username}</TextContent>
            <TextContent>{data.data?.name || "-"}</TextContent>
            <TextContent>{data.data?.university_name}</TextContent>
            <TextContent>{data.data?.faculty_name}</TextContent>
            <TextContent>{data.data?.major_name}</TextContent>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default DataProfile;
