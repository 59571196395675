import React, { useState, useEffect, forwardRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  TextField,
  CardActions,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";

import { Link as RouterLink } from "react-router-dom";

import Autocomplete from "@material-ui/lab/Autocomplete";
import CKEditor from "ckeditor4-react";

import ImageUploader from "react-images-upload";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { connect } from "react-redux";
import {
  createArticle,
  setAlert,
  getArticleCategories,
} from "../../../store/actions";
import axios from "../../../api/axios-orders";

// components
import Breadcrumb from "../../../components/UI/Breadcrumb/Breadcrumb";

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref}>
    <RouterLink {...props} />
  </div>
));

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  btnSave: {
    width: 250,
  },
  title: {
    fontWeight: 700,
  },

  errorMessageThumbnail: {
    color: "red",
    fontSize: 12,
    marginTop: 5,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const validationSchema = yup.object().shape({
  title: yup.string().required("Wajib diisi"),
  category: yup.string().required("Wajib diisi"),
});

const TambahArtikel = ({
  setAlert,
  getArticleCategories,
  article,
  createArticle,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [image, setImage] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [dataArticle, setDataArticle] = useState("");

  const onDrop = (picture) => {
    setImage(picture);
  };

  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
  });

  const onChangeContent = (e) => {
    setDataArticle(e.editor.getData());
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    setIsSubmit(true);
    if (image.length === 0) {
      setIsSubmit(false);
      return setAlert("Thumbnail Belum Di Upload", "error");
    }
    if (dataArticle === "") {
      setIsSubmit(false);
      return setAlert("Content Berita Tidak boleh Kosong", "error");
    }

    const dataImage = new FormData();
    dataImage.append("file", image[0]);
    dataImage.append("dir", "article");

    try {
      const res = await axios.post(`/admin/file/upload `, dataImage, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      const formData = {
        ...data,
        body: dataArticle,
        thumbnail: res.data.data,
      };
      setIsSubmit(false);
      createArticle(formData, history);
    } catch (error) {
      setIsSubmit(false);
      setAlert("Thumbnail Gagal Di Upload", "error");
    }
  };

  const scrumbs = [
    {
      name: "Manage Artikel",
      link: `/admin/manage-artikel`,
    },
    { name: "Tambah Artikel", link: "" },
  ];

  useEffect(() => {
    getArticleCategories();
  }, [getArticleCategories]);

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        Tambah Artikel
      </Typography>

      <Breadcrumb crumb={scrumbs} />
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="JUDUL"
                  placeholder="Masukkan judul artikel"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type="text"
                  name="title"
                  inputRef={register}
                  error={!!errors.title}
                  helperText={errors.title?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  freeSolo
                  id="free-solo-2-demo"
                  disableClearable
                  options={article.categories.map((option) => option.category)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="KATEGORI"
                      name="category"
                      inputRef={register}
                      error={!!errors.category}
                      helperText={errors.category?.message}
                      placeholder="Masukkan Kategori"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="PENULIS"
                  placeholder="Masukkan nama penulis"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type="text"
                  inputRef={register}
                  helperText={`Opsional`}
                  name="creator"
                />
              </Grid>
              <Grid style={{ marginBottom: 15 }} item xs={12}>
                <ImageUploader
                  withIcon={true}
                  buttonText="Upload Thumbnail"
                  onChange={onDrop}
                  imgExtension={[".jpg", ".jpeg", ".png"]}
                  maxFileSize={2242880}
                  withPreview={true}
                  label="Max file size : 2 mb, Format : jpeg, png, jpg"
                  accept="image/jpg,image/jpeg,image/png"
                  singleImage={true}
                  name="thumbnail"
                />
              </Grid>
              <Grid item xs={12}>
                <CKEditor
                  config={{
                    extraPlugins: "justify,font,colorbutton",

                    toolbarGroups: [
                      {
                        name: "document",
                        groups: ["mode", "document", "doctools"],
                      },
                      { name: "clipboard", groups: ["clipboard", "undo"] },
                      {
                        name: "editing",
                        groups: [
                          "find",
                          "selection",
                          "spellchecker",
                          "editing",
                        ],
                      },
                      { name: "forms", groups: ["forms"] },
                      "/",
                      {
                        name: "basicstyles",
                        groups: ["basicstyles", "cleanup"],
                      },
                      {
                        name: "paragraph",
                        groups: [
                          "list",
                          "indent",
                          "blocks",
                          "align",
                          "bidi",
                          "paragraph",
                        ],
                      },
                      { name: "links", groups: ["links"] },
                      { name: "insert", groups: ["insert"] },
                      "/",
                      { name: "styles", groups: ["styles"] },
                      { name: "colors", groups: ["colors"] },
                      { name: "tools", groups: ["tools"] },
                      { name: "others", groups: ["others"] },
                      { name: "about", groups: ["about"] },
                    ],
                  }}
                  data={dataArticle}
                  onChange={onChangeContent}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions style={{ display: "flex", justifyContent: "right" }}>
            <Button
              component={CustomRouterLink}
              to="/admin/manage-artikel"
              variant="outlined"
              color="secondary"
              style={{ flexGrow: "none" }}
            >
              Cancel
            </Button>
            {!isSubmit ? (
              <Button
                className={classes.btnSave}
                type="submit"
                variant="contained"
                color="secondary"
                fullWidth
              >
                Save
              </Button>
            ) : (
              <Button
                className={classes.btnSave}
                variant="contained"
                color="secondary"
                disabled
                fullWidth
              >
                Save
              </Button>
            )}
          </CardActions>
        </form>
      </Card>
      <Backdrop className={classes.backdrop} open={article.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    article: state.article,
  };
};

export default connect(mapStateToProps, {
  setAlert,
  getArticleCategories,
  createArticle,
})(TambahArtikel);
