/* eslint-disable array-callback-return */
import { NOTIFIKASI_START, NOTIFIKASI_SUCCESS, NOTIFIKASI_FAIL } from './types';
import axios from '../../../api/axios-orders';
import { setAlert } from '../alert';

export const getNotifikasi = () => async (dispatch) => {
  dispatch({
    type: NOTIFIKASI_START,
  });
  try {
    const response = await axios.get(`/student/notification`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
    if (response.data.data === null || response.data.data.length === 0) {
      dispatch({
        type: NOTIFIKASI_SUCCESS,
        payload: [],
        total: 0,
      });
    } else {
      const notif = response.data.data.map((value) => {
        if (value.read_at === '') {
          return 1;
        }
        return 0;
      });
      const totalNotif = notif.reduce((a, b) => a + b);
      dispatch({
        type: NOTIFIKASI_SUCCESS,
        payload: response.data.data,
        total: totalNotif,
      });
    }
  } catch (error) {
    dispatch({
      type: NOTIFIKASI_FAIL,
    });
    dispatch(setAlert("Can't get data notifikasi", 'error'));
  }
};

export const readNotifikasi = (data) => async (dispatch) => {
  try {
    await axios.patch(`/student/notification/read`, data, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
    dispatch(getNotifikasi());
  } catch (error) {
    console.log(error.response);
  }
};
