import {
  SET_DETAIL_JUMLAH_MAHASISWA_START,
  SET_DETAIL_JUMLAH_MAHASISWA_SUCCESS,
  SET_DETAIL_JUMLAH_MAHASISWA_FAIL,
  SET_DETAIL_JUMLAH_MAHASISWA_CHART_START,
  SET_DETAIL_JUMLAH_MAHASISWA_CHART_SUCCESS,
  SET_DETAIL_JUMLAH_MAHASISWA_CHART_FAIL,
} from "../../actions/prodi/types";
import { updateObject } from "../../../utils";

const initialState = {
  loading: false,
  loadingChart: false,
  data: [],
  dataChart: {},
  error: false,
  errorChart: false,
};

const detailJumlahMahasiswaStart = (state, action) => {
  return updateObject(state, {
    ...state,
    loading: true,
    error: false,
  });
};

const detailJumlahMahasiswaSuccess = (state, action) => {
  return updateObject(state, {
    data: action.payload,
    current_page: action.current_page,
    has_previous_page: action.has_previous_page,
    has_next_page: action.has_next_page,
    previous_page: action.previous_page,
    next_page: action.next_page,
    last_page: action.last_page,
    total_item: action.total_item,
    loading: false,
    error: false,
  });
};

const detailJumlahMahasiswaFail = (state, action) => {
  return updateObject(state, {
    ...state,
    loading: false,
    error: true,
  });
};

// Function for chart
const detailJumlahMahasiswaChartStart = (state, action) => {
  return updateObject(state, {
    ...state,
    loadingChart: true,
    errorChart: false,
  });
};

const detailJumlahMahasiswaChartSuccess = (state, action) => {
  return updateObject(state, {
    dataChart: action.payload,
    loadingChart: false,
    errorChart: false,
  });
};

const detailJumlahMahasiswaChartFail = (state, action) => {
  return updateObject(state, {
    ...state,
    loadingChart: false,
    errorChart: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DETAIL_JUMLAH_MAHASISWA_START:
      return detailJumlahMahasiswaStart(state, action);
    case SET_DETAIL_JUMLAH_MAHASISWA_SUCCESS:
      return detailJumlahMahasiswaSuccess(state, action);
    case SET_DETAIL_JUMLAH_MAHASISWA_FAIL:
      return detailJumlahMahasiswaFail(state, action);
    case SET_DETAIL_JUMLAH_MAHASISWA_CHART_START:
      return detailJumlahMahasiswaChartStart(state, action);
    case SET_DETAIL_JUMLAH_MAHASISWA_CHART_SUCCESS:
      return detailJumlahMahasiswaChartSuccess(state, action);
    case SET_DETAIL_JUMLAH_MAHASISWA_CHART_FAIL:
      return detailJumlahMahasiswaChartFail(state, action);
    default:
      return state;
  }
};

export default reducer;
