import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  List,
  ListSubheader,
  ListItemText,
  ListItem,
  Box,
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { LoadText } from "../../../../../components/Skeleton";

import StyledMenuKategori from "../StyledMenuKategori";

const useStyles = makeStyles((theme) => ({
  rootMenuKategori: {
    padding: theme.spacing(2, 0),
  },
  loadingText: {
    width: "100%",
  },
}));

const MenuKategori = ({
  menuPosition,
  setMenuPosition,
  setStateKategori,
  stateKategori,
  getKategoriFakultas,
  getKategoriProdi,
  data,
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <StyledMenuKategori
      open={!!menuPosition}
      onClose={() => {
        setMenuPosition(null);
      }}
      MenuListProps={{
        onMouseLeave: () => {
          setMenuPosition(null);
          setStateKategori({
            fakultas: {
              open: false,
              university_id: null,
            },
            prodi: {
              open: false,
              faculty_id: null,
            },
            semester: {
              open: false,
              major_id: null,
            },
          });
        },
      }}
      anchorReference="anchorPosition"
      anchorPosition={menuPosition}
    >
      <Grid container className={classes.rootMenuKategori}>
        <Grid item xs={3}>
          <List
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader
                component="div"
                id="nested-list-subheader"
                style={{ color: "#66C7F2", fontWeight: "bold" }}
              >
                Universitas
              </ListSubheader>
            }
          >
            {data.universitas.data.map((value) => (
              <ListItem
                button
                key={value.university_id}
                onMouseEnter={() => {
                  getKategoriFakultas(value.university_code);
                  setStateKategori((prev) => ({
                    ...prev,
                    fakultas: {
                      open: true,
                      university_id: value.university_code,
                    },
                    prodi: {
                      open: false,
                      faculty_id: null,
                    },
                    semester: {
                      open: false,
                      major_id: null,
                    },
                  }));
                }}
                onClick={() => {
                  setMenuPosition(null);
                  history.push(
                    `/perkuliahan?universitas=${value.university_code}`
                  );
                }}
              >
                <ListItemText primary={value.name} />
                {stateKategori.fakultas.open &&
                stateKategori.fakultas.university_id ===
                  value.university_cooperation_code ? (
                  <ArrowForwardIosIcon />
                ) : null}
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={3}>
          <Box visibility={stateKategori.fakultas.open ? "visible" : "hidden"}>
            <List
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader
                  component="div"
                  id="nested-list-subheader"
                  style={{ color: "#F0C349", fontWeight: "bold" }}
                >
                  Fakultas
                </ListSubheader>
              }
            >
              {data.fakultas.loading
                ? Array(5)
                    .fill("a")
                    .map((value, index) => (
                      <ListItem key={index}>
                        <LoadText className={classes.loadingText} />
                      </ListItem>
                    ))
                : data.fakultas.data.map((value, index) => (
                    <ListItem
                      button
                      key={index}
                      onMouseEnter={() => {
                        getKategoriProdi(
                          stateKategori.fakultas.university_id,
                          value.faculty_uuid
                        );
                        setStateKategori((prev) => ({
                          ...prev,
                          prodi: {
                            open: true,
                            faculty_id: value.faculty_uuid,
                          },
                          semester: {
                            open: false,
                            major_id: null,
                          },
                        }));
                      }}
                      onClick={() => {
                        setMenuPosition(null);
                        history.push(
                          `/perkuliahan?universitas=${stateKategori.fakultas.university_id}&fakultas=${value.faculty_uuid}`
                        );
                      }}
                    >
                      <ListItemText primary={value.name} />
                      {stateKategori.prodi.open &&
                      stateKategori.prodi.faculty_id === value.faculty_uuid ? (
                        <ArrowForwardIosIcon />
                      ) : null}
                    </ListItem>
                  ))}
              {data.fakultas.data.length === 0 && !data.fakultas.loading && (
                <ListItem>
                  <ListItemText>Fakultas tidak tersedia</ListItemText>
                </ListItem>
              )}
            </List>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box visibility={stateKategori.prodi.open ? "visible" : "hidden"}>
            <List
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader
                  component="div"
                  id="nested-list-subheader"
                  style={{ color: "#4FD989", fontWeight: "bold" }}
                >
                  Program Studi
                </ListSubheader>
              }
            >
              {data.prodi.loading
                ? Array(5)
                    .fill("a")
                    .map((value, index) => (
                      <ListItem key={index}>
                        <LoadText className={classes.loadingText} />
                      </ListItem>
                    ))
                : data.prodi.data.map((value, index) => (
                    <ListItem
                      button
                      key={index}
                      onMouseEnter={() =>
                        setStateKategori((prev) => ({
                          ...prev,
                          semester: {
                            open: true,
                            major_id: value.major_uuid,
                          },
                        }))
                      }
                      onClick={() => {
                        setMenuPosition(null);
                        history.push(
                          `/perkuliahan?universitas=${stateKategori.fakultas.university_id}&fakultas=${stateKategori.prodi.faculty_id}&prodi=${value.major_uuid}`
                        );
                      }}
                    >
                      <ListItemText primary={value.major_name} />
                      {stateKategori.semester.open &&
                      stateKategori.semester.major_id === value.major_uuid ? (
                        <ArrowForwardIosIcon />
                      ) : null}
                    </ListItem>
                  ))}
              {data.prodi.data.length === 0 && !data.prodi.loading && (
                <ListItem>
                  <ListItemText>Program Studi tidak tersedia</ListItemText>
                </ListItem>
              )}
            </List>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box visibility={stateKategori.semester.open ? "visible" : "hidden"}>
            <List
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader
                  component="div"
                  id="nested-list-subheader"
                  style={{ color: "#F9A255", fontWeight: "bold" }}
                >
                  Semester
                </ListSubheader>
              }
            >
              {data.semester.data.map((value) => (
                <ListItem
                  button
                  key={value.id}
                  onClick={() => {
                    setMenuPosition(null);
                    history.push(
                      `/perkuliahan?universitas=${stateKategori.fakultas.university_id}&fakultas=${stateKategori.prodi.faculty_id}&prodi=${stateKategori.semester.major_id}&semester=${value.id}`
                    );
                  }}
                >
                  <ListItemText primary={value.name} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
      </Grid>
    </StyledMenuKategori>
  );
};

export default React.memo(MenuKategori);
