import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
  Box,
  CircularProgress,
} from "@material-ui/core";

import { TableHead } from "./component";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  btnDelete: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.error.main,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

const TableManageUser = ({ setDialogUpdate, setDialogDelete, data }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableHead />
            <TableBody>
              {data.loading ? (
                <TableRow>
                  <TableCell align="center" colSpan={8}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : data.data.length > 0 ? (
                data.data.map((row, index) => {
                  return (
                    <TableRow tabIndex={-1} key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.aptisi_code}</TableCell>
                      <TableCell>{row.university_name}</TableCell>
                      <TableCell>{row.faculty_name}</TableCell>
                      <TableCell>{row.major_name}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.username}</TableCell>
                      <TableCell>
                        <Box display="flex">
                          <Button
                            size="small"
                            color="secondary"
                            variant="contained"
                            onClick={() =>
                              setDialogUpdate({ open: true, data: row })
                            }
                          >
                            Edit
                          </Button>
                          <Button
                            size="small"
                            variant="contained"
                            className={classes.btnDelete}
                            onClick={() =>
                              setDialogDelete({ open: true, id: row.user_id })
                            }
                          >
                            Hapus
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={8}>
                    Data Kosong
                  </TableCell>
                </TableRow>
              )}
              {}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default TableManageUser;
