export const SET_PROFILE_PRODI = "SET_PROFILE_PRODI";
export const PROFILE_PRODI_START = "PROFILE_PRODI_START";
export const PROFILE_PRODI_FAIL = "PROFILE_PRODI_FAIL";

// PRODI PENGAMBIL
export const SET_PRODI_PENGAMBIL_START = "SET_PRODI_PENGAMBIL_START";
export const SET_PRODI_PENGAMBIL_SUCCESS = "SET_PRODI_PENGAMBIL_SUCCESS";
export const SET_PRODI_PENGAMBIL_FAIL = "SET_PRODI_PENGAMBIL_FAIL";

// PRODI PENYEDIA
export const SET_PRODI_PENYEDIA_START = "SET_PRODI_PENYEDIA_START";
export const SET_PRODI_PENYEDIA_SUCCESS = "SET_PRODI_PENYEDIA_SUCCESS";
export const SET_PRODI_PENYEDIA_FAIL = "SET_PRODI_PENYEDIA_FAIL";

// PRODI MATA KULIAH
export const SET_PRODI_MATA_KULIAH_START = "SET_PRODI_MATA_KULIAH_START";
export const SET_PRODI_MATA_KULIAH_SUCCESS = "SET_PRODI_MATA_KULIAH_SUCCESS";
export const SET_PRODI_MATA_KULIAH_FAIL = "SET_PRODI_MATA_KULIAH_FAIL";

// DETAIL JUMLAH MAHASISWA
export const SET_DETAIL_JUMLAH_MAHASISWA_START =
  "SET_DETAIL_JUMLAH_MAHASISWA_START";
export const SET_DETAIL_JUMLAH_MAHASISWA_SUCCESS =
  "SET_DETAIL_JUMLAH_MAHASISWA_SUCCESS";
export const SET_DETAIL_JUMLAH_MAHASISWA_FAIL =
  "SET_DETAIL_JUMLAH_MAHASISWA_FAIL";
export const SET_DETAIL_JUMLAH_MAHASISWA_CHART_START =
  "SET_DETAIL_JUMLAH_MAHASISWA_CHART_START";
export const SET_DETAIL_JUMLAH_MAHASISWA_CHART_SUCCESS =
  "SET_DETAIL_JUMLAH_MAHASISWA_CHART_SUCCESS";
export const SET_DETAIL_JUMLAH_MAHASISWA_CHART_FAIL =
  "SET_DETAIL_JUMLAH_MAHASISWA_CHART_FAIL";

// DASHBOARD
export const SET_DASHBOARD_CHART_MATA_KULIAH_START =
  "SET_DASHBOARD_CHART_MATA_KULIAH_START";
export const SET_DASHBOARD_CHART_MATA_KULIAH_SUCCESS =
  "SET_DASHBOARD_CHART_MATA_KULIAH_SUCCESS";
export const SET_DASHBOARD_CHART_MATA_KULIAH_FAIL =
  "SET_DASHBOARD_CHART_MATA_KULIAH_FAIL";
export const SET_DASHBOARD_CHART_UNIVERSITAS_FAIL =
  "SET_DASHBOARD_CHART_UNIVERSITAS_FAIL";
export const SET_DASHBOARD_CHART_UNIVERSITAS_SUCCESS =
  "SET_DASHBOARD_CHART_UNIVERSITAS_SUCCESS";
export const SET_DASHBOARD_CHART_UNIVERSITAS_START =
  "SET_DASHBOARD_CHART_UNIVERSITAS_START";
export const SET_DATA_MATA_KULIAH = "SET_DATA_MATA_KULIAH";
export const SET_DATA_UNIVERSITAS = "SET_DATA_UNIVERSITAS";
