import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

const headCells = [
  {
    id: "no",
    numeric: false,
    disablePadding: false,
    label: "NO",
  },
  {
    id: "kode-aptisi",
    numeric: false,
    disablePadding: false,
    label: "Kode Aptisi",
  },
  {
    id: "universitas",
    numeric: false,
    disablePadding: false,
    label: "Universitas",
  },
  {
    id: "fakultas",
    numeric: false,
    disablePadding: false,
    label: "Fakultas",
  },
  {
    id: "program-studi",
    numeric: false,
    disablePadding: false,
    label: "Program Studi",
  },
  {
    id: "nama-lengkap",
    numeric: false,
    disablePadding: false,
    label: "Nama Lengkap (Beserta Gelar)",
  },
  {
    id: "username",
    numeric: false,
    disablePadding: false,
    label: "Username",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];

const EnhancedTableHead = (props) => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
