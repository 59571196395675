import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const headCells = [
  {
    id: "no",
    numeric: false,
    disablePadding: false,
    label: "No",
  },
  {
    id: "perguruan-tinggi",
    numeric: false,
    disablePadding: false,
    label: "Perguruan Tinggi",
  },
  {
    id: "total",
    numeric: false,
    disablePadding: false,
    label: "Total",
  },
];

const EnhancedTableHead = (props) => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
