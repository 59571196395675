import React, { useState, useEffect, forwardRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams, Link as RouterLink } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  TextField,
  CardActions,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";

import CKEditor from "ckeditor4-react";

import ImageUploader from "react-images-upload";

import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { connect } from "react-redux";
import {
  getArticleOne,
  setAlert,
  getArticleCategories,
  editArticle,
} from "../../../store/actions";
import axios from "../../../api/axios-orders";

// components
import Breadcrumb from "../../../components/UI/Breadcrumb/Breadcrumb";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  btnSave: {
    width: 250,
  },
  title: {
    fontWeight: 700,
  },

  errorMessageThumbnail: {
    color: "red",
    fontSize: 12,
    marginTop: 5,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref}>
    <RouterLink {...props} />
  </div>
));

const validationSchema = yup.object().shape({
  title: yup.string().required("Wajib diisi"),
  category: yup.string().required("Wajib diisi"),
});

const EditArtikel = ({
  setAlert,
  getArticleCategories,
  article,
  getArticleOne,
  editArticle,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const [image, setImage] = useState([]);
  const [defaultThumbnail, setDefaultThumbnail] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [dataArticle, setDataArticle] = useState("");

  const onDrop = (picture) => {
    setImage(picture);
    setDefaultThumbnail(false);
  };

  const onChangeContent = (e) => {
    setDataArticle(e.editor.getData());
  };

  const { register, handleSubmit, errors, setValue, control } = useForm({
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
  });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    setIsSubmit(true);
    if (dataArticle === "") {
      setIsSubmit(false);
      return setAlert("Content Berita Tidak boleh Kosong", "error");
    }
    if (image.length === 0) {
      const formData = {
        ...data,
        body: dataArticle,
        thumbnail: article.dataEdit.thumbnail,
        change_thumbnail: false,
      };
      setIsSubmit(false);
      editArticle(formData, article.dataEdit.id, history);
    } else {
      const dataImage = new FormData();
      dataImage.append("file", image[0]);
      dataImage.append("dir", "article");
      try {
        const res = await axios.post(`/admin/file/upload `, dataImage, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        const formData = {
          ...data,
          body: dataArticle,
          thumbnail: res.data.data,
          change_thumbnail: true,
        };
        editArticle(formData, article.dataEdit.id, history);
        setIsSubmit(false);
      } catch (error) {
        setAlert("Thumbnail Gagal Di Upload", "error");
        setIsSubmit(false);
      }
    }
  };

  const scrumbs = [
    {
      name: "Manage Artikel",
      link: `/admin/manage-artikel`,
    },
    { name: "Edit Artikel", link: "" },
  ];

  useEffect(() => {
    getArticleOne(params.slug, history);
    getArticleCategories();
  }, [getArticleOne]);

  useEffect(() => {
    if (!article.loadingEdit) {
      setValue("title", article.dataEdit.title);
      setValue("category", article.dataEdit.category);
      setValue("creator", article.dataEdit.creator);

      // Set Value To Editor
      setDataArticle(article.dataEdit.body);
    }
  }, [article.dataEdit]);

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        Edit Artikel
      </Typography>

      <Breadcrumb crumb={scrumbs} />
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="JUDUL"
                  placeholder="Masukkan judul artikel"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type="text"
                  name="title"
                  inputRef={register}
                  error={!!errors.title}
                  helperText={errors.title?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="category"
                  render={({ onChange, ref, value }) => (
                    <Autocomplete
                      freeSolo
                      id="free-solo-2-demo"
                      disableClearable
                      options={
                        !article.loading
                          ? article.categories.map((option) => option.category)
                          : []
                      }
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      value={value}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          label="KATEGORI"
                          inputRef={ref}
                          error={!!errors.category}
                          helperText={errors.category?.message}
                          placeholder="Masukkan Kategori"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="PENULIS"
                  placeholder="Masukkan nama penulis"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type="text"
                  inputRef={register}
                  helperText={`Opsional`}
                  name="creator"
                />
              </Grid>
              <Grid align="center" style={{ marginBottom: 15 }} item xs={12}>
                <ImageUploader
                  withIcon={true}
                  buttonText="Upload Thumbnail"
                  onChange={onDrop}
                  imgExtension={[".jpg", ".jpeg", ".png"]}
                  maxFileSize={2242880}
                  withPreview={true}
                  label="Max file size : 2 mb, Format : jpeg, png, jpg"
                  accept="image/jpg,image/jpeg,image/png"
                  singleImage={true}
                  name="thumbnail"
                />
                {defaultThumbnail && !article.loadingEdit && (
                  <img
                    src={article.dataEdit.thumbnail}
                    width="auto"
                    height="150"
                    alt=""
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <CKEditor
                  config={{
                    extraPlugins: "justify,font,colorbutton",

                    toolbarGroups: [
                      {
                        name: "document",
                        groups: ["mode", "document", "doctools"],
                      },
                      { name: "clipboard", groups: ["clipboard", "undo"] },
                      {
                        name: "editing",
                        groups: [
                          "find",
                          "selection",
                          "spellchecker",
                          "editing",
                        ],
                      },
                      { name: "forms", groups: ["forms"] },
                      "/",
                      {
                        name: "basicstyles",
                        groups: ["basicstyles", "cleanup"],
                      },
                      {
                        name: "paragraph",
                        groups: [
                          "list",
                          "indent",
                          "blocks",
                          "align",
                          "bidi",
                          "paragraph",
                        ],
                      },
                      { name: "links", groups: ["links"] },
                      { name: "insert", groups: ["insert"] },
                      "/",
                      { name: "styles", groups: ["styles"] },
                      { name: "colors", groups: ["colors"] },
                      { name: "tools", groups: ["tools"] },
                      { name: "others", groups: ["others"] },
                      { name: "about", groups: ["about"] },
                    ],
                  }}
                  data={dataArticle}
                  onChange={onChangeContent}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions style={{ display: "flex", justifyContent: "right" }}>
            <Button
              component={CustomRouterLink}
              to="/admin/manage-artikel"
              variant="outlined"
              color="secondary"
              style={{ flexGrow: "none" }}
            >
              Cancel
            </Button>
            {!isSubmit ? (
              <Button
                className={classes.btnSave}
                type="submit"
                variant="contained"
                color="secondary"
                fullWidth
              >
                Save
              </Button>
            ) : (
              <Button
                className={classes.btnSave}
                variant="contained"
                color="secondary"
                disabled
                fullWidth
              >
                Save
              </Button>
            )}
          </CardActions>
        </form>
      </Card>
      <Backdrop className={classes.backdrop} open={article.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    article: state.article,
  };
};

export default connect(mapStateToProps, {
  setAlert,
  getArticleCategories,
  getArticleOne,
  editArticle,
})(EditArtikel);
