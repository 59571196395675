/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
  Slider,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Cropper from "react-easy-crop";
import axios from "../../../../../api/axios-orders";
import getCroppedImg from "./cropImage";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    padding: 16,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },
  sliderContainer: {
    display: "flex",
    flex: "1",
    alignItems: "center",
  },
  sliderLabel: {
    [theme.breakpoints.down("xs")]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: "22px 0px",
    marginLeft: 32,
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "center",
      margin: "0 16px",
    },
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles((theme) => ({
  cropContainer: {
    position: "relative",
    width: "100%",
    height: 200,
    background: "#333",
    [theme.breakpoints.up("sm")]: {
      height: 400,
    },
  },
}));

const DialogUploadBanner = ({
  dialog,
  onClose,
  action,
  setAlert,
  setLoadingUpload,
  setLoadingDone,
}) => {
  const classes = useStyles();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      setLoadingUpload();
      onClose();
      const data = await getCroppedImg(dialog.src, croppedAreaPixels, rotation);

      const dataUploadBanner = {
        dir: "banner",
        data: data,
      };

      const res = await axios.post(
        `/admin/file/upload/base64`,
        dataUploadBanner,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      const formData = {
        path: res.data.data,
      };

      action(formData);
    } catch (e) {
      onClose();
      setLoadingDone();
      setAlert("Banner Gagal Di Upload", "error");
    }
  }, [croppedAreaPixels, rotation]);

  return (
    <Dialog open={dialog.open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle onClose={onClose}>UploadBanner</DialogTitle>
      <DialogContent dividers>
        {/* <Grid container>
          <Grid item xs={12}> */}
        <div className={classes.cropContainer}>
          <Cropper
            image={dialog.src}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={26 / 14}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        <div className={classes.controls}>
          <div className={classes.sliderContainer}>
            <Typography
              variant="overline"
              classes={{ root: classes.sliderLabel }}
            >
              Zoom
            </Typography>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              classes={{ root: classes.slider }}
              onChange={(e, zoom) => setZoom(zoom)}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={showCroppedImage}>
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogUploadBanner;
