import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import { deleteBanner } from "../../../../store/actions";

const DialogAlert = ({ dialog, onClose, deleteBanner }) => {
  const onDelete = () => {
    deleteBanner(dialog.id);
    onClose();
  };
  return (
    <div>
      <Dialog
        open={dialog.open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Apakah anda yakin ingin menghapus banner ini?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Banner akan terhapus dan tidak akan bisa dikembalikan lagi...
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined" color="secondary">
            Batal
          </Button>
          <Button onClick={onDelete} variant="contained" color="primary">
            Hapus
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default connect(null, { deleteBanner })(DialogAlert);
