import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Card, CardContent } from "@material-ui/core";

import { LoadText, LoadAvatar } from "../../../../../components/Skeleton";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 1, 0),
  },
  card: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  media: {
    width: "100%",
    paddingTop: "100%",
    borderRadius: "10px",
  },
  textTitle: {
    marginTop: theme.spacing(1),
  },
  titleContainer: {
    paddingLeft: theme.spacing(2),
  },
  textTime: {
    marginTop: theme.spacing(0.5),
  },
  icon: {
    fontSize: theme.spacing(1.5),
  },
  textMargin: {
    marginTop: theme.spacing(1),
  },
  cardContent: {
    marginTop: theme.spacing(2),
    height: "120px",
    padding: 0,
    color: "#343434",
  },
  copyLink: {
    cursor: "pointer",
  },
}));

const ListArtikel = ({ array }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Grid container>
        <Grid item xs={6} sm={5} className={classes.imageContainer}>
          <LoadAvatar className={classes.media} />
        </Grid>
        <Grid item xs={6} sm={7} className={classes.titleContainer}>
          <LoadText />
          <LoadText className={classes.textTitle} />
          <LoadText className={classes.textTime} />
          <LoadText />
        </Grid>
      </Grid>
      <CardContent className={classes.cardContent}>
        {Array(5)
          .fill("a")
          .map((_, index) => (
            <LoadText key={index} />
          ))}
      </CardContent>
    </Card>
  );
};

export default React.memo(ListArtikel);
