import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from "@material-ui/core";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { CopyToClipboard } from "react-copy-to-clipboard";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";

import { setAlert } from "../../../../store/actions";
import { LoadArtikel } from "../Skeleton";
import CustomRouterLink from "../../../../components/CustomRouterLink";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 1, 0),
  },
  card: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  media: {
    width: "100%",
    paddingTop: "100%",
    borderRadius: "10px",
  },
  textTitle: {
    fontWeight: 700,
    marginTop: theme.spacing(1),
    fontSize: "14px",
    lineHeight: "17px",
    cursor: "pointer",
  },
  titleContainer: {
    paddingLeft: theme.spacing(2),
  },
  textTime: {
    fontSize: "12px",
    fontWeight: 500,
    marginTop: theme.spacing(0.5),
  },
  textWriter: {
    fontSize: "14px",
    fontWeight: 500,
  },
  icon: {
    fontSize: theme.spacing(1.5),
  },
  textMargin: {
    marginTop: theme.spacing(1),
  },
  cardContent: {
    marginTop: theme.spacing(2),
    height: "120px",
    padding: 0,
    color: "#343434",
  },
  copyLink: {
    cursor: "pointer",
  },
}));

const ListArtikel = ({ data, setAlert }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid container justify="center">
      {data.data.map((value) => (
        <Grid
          item
          lg={4}
          md={4}
          sm={6}
          xs={12}
          key={value.slug}
          className={classes.container}
        >
          <Card className={classes.card}>
            <Grid container>
              <Grid item xs={6} sm={5} className={classes.imageContainer}>
                <CardMedia
                  className={classes.media}
                  title="Image Perkuliahan"
                  image={value.thumbnail}
                />
              </Grid>
              <Grid item xs={6} sm={7} className={classes.titleContainer}>
                <CopyToClipboard
                  text={`https://localhost:3000/${value.slug}`}
                  onCopy={() => setAlert("Copied to clipboard", "success")}
                >
                  <Typography
                    variant="body2"
                    align="right"
                    className={classes.copyLink}
                  >
                    Copy Link <FileCopyOutlinedIcon className={classes.icon} />
                  </Typography>
                </CopyToClipboard>
                <Typography
                  variant="h4"
                  className={classes.textTitle}
                  onClick={() => history.push(`/artikel/${value.slug}`)}
                >
                  {value.title}
                </Typography>
                <Typography variant="h4" className={classes.textTime}>
                  {value.created_at}
                </Typography>
                <Typography variant="h4" className={classes.textWriter}>
                  {value.creator}
                </Typography>
              </Grid>
            </Grid>
            <CardActionArea
              component={CustomRouterLink}
              to={`/artikel/${value.slug}`}
            >
              <CardContent className={classes.cardContent}>
                <HTMLEllipsis
                  unsafeHTML={value.body}
                  maxLine="7"
                  ellipsis="..."
                  basedOn="letters"
                />
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
      {data.loading &&
        Array(3)
          .fill("a")
          .map((_, index) => (
            <Grid
              item
              lg={4}
              md={4}
              sm={6}
              xs={12}
              key={index}
              className={classes.container}
            >
              <LoadArtikel array={1} />
            </Grid>
          ))}
    </Grid>
  );
};

export default connect(null, { setAlert })(React.memo(ListArtikel));
