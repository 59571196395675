export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// Auth
export const AUTH_START = "AUTH_START";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SET_LOADING_PASSWORD = "SET_LOADING_PASSWORD";
export const SET_LOADING_PASSWORD_DONE = "SET_LOADING_PASSWORD_DONE";

// Auth mahasiswa
export const AUTH_MAHASISWA_PRODI_SUCCESS = "AUTH_MAHASISWA_PRODI_SUCCESS";

// Auth Admin Aptisi
export const AUTH_ADMIN_APTISI_SUCCESS = "AUTH_ADMIN_APTISI_SUCCESS";

// Auth Guest
export const AUTH_GUEST_SUCCESS = "AUTH_GUEST_SUCCESS";

// Profile
export const SET_PROFILE = "SET_PROFILE";

// Print
export const SET_PRINT_START = "SET_PRINT_START";
export const SET_PRINT_FAIL = "SET_PRINT_FAIL";
export const SET_PRINT_SUCCESS = "SET_PRINT_SUCCESS";
