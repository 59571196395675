/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "none",
  },
  image: {
    width: "260px",
    [theme.breakpoints.only("xs")]: {
      margin: "0 auto",
    },
  },
  media: {
    width: "100%",
    borderRadius: "10px",
  },
  textConteiner: {
    width: "calc(100% - 300px)",
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.only("xs")]: {
      paddingLeft: "0",
      marginTop: theme.spacing(2),
      width: "100%",
    },
  },
  textTitle: {
    fontSize: "28px",
    lineHeight: "37px",
    fontWeight: "700",
  },
  textTime: {
    fontSize: "20px",
    lineHeight: "33px",
    fontWeight: "400",
    marginTop: theme.spacing(1),
    [theme.breakpoints.only("xs")]: {
      marginTop: 0,
    },
  },
  textWriter: {
    fontSize: "18px",
    fontWeight: "500",
    marginTop: theme.spacing(1),
    [theme.breakpoints.only("xs")]: {
      marginTop: 0,
    },
  },
}));

const HeaderArtikel = ({ data }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container>
          <div className={classes.image}>
            <img className={classes.media} src={data.data.thumbnail} />
          </div>
          <div className={classes.textConteiner}>
            <Typography varinat="h5" className={classes.textTitle}>
              {data.data.title}
            </Typography>
            <Typography varinat="h5" className={classes.textTime}>
              Senin, 21 Februari 2021
            </Typography>
            <Typography variant="body1" className={classes.textWriter}>
              {data.data.creator}
            </Typography>
          </div>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default HeaderArtikel;
