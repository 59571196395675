import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  heading: {
    height: "70px",
    backgroundColor: "#0263C4",
    color: theme.palette.white,
    display: "flex",
    alignItems: "center",
  },
  textHead: {
    fontWeight: "bold",
    color: theme.palette.white,
  },
}));

const Heading = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.heading}>
      <Container maxWidth="lg">
        <Typography variant="h4" className={classes.textHead}>
          {text}
        </Typography>
      </Container>
    </div>
  );
};

export default React.memo(Heading);
