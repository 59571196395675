import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import Image404 from "../../assets/img/404.svg";

import { CustomRouterLink } from "../../components";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },

  img: {},
}));

const Error404 = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img
        className={classes.img}
        src={Image404}
        height="400"
        width="400"
        alt="404-image"
      />
      <Typography variant="h1" style={{ marginBottom: 30 }} paragraph>
        404 NOT FOUND
      </Typography>
      <Button
        component={CustomRouterLink}
        to="/"
        variant="contained"
        color="primary"
      >
        Kembali Ke Halaman Utama
      </Button>
    </div>
  );
};

export default Error404;
