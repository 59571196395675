import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

const headCells = [
  {
    id: "no",
    numeric: false,
    disablePadding: false,
    label: "NO",
  },
  {
    id: "nim",
    numeric: false,
    disablePadding: false,
    label: "NIM",
  },
  {
    id: "nama",
    numeric: false,
    disablePadding: false,
    label: "Nama",
  },
  {
    id: "semester",
    numeric: false,
    disablePadding: false,
    label: "Semester",
  },
  {
    id: "sks-mahasiswa",
    numeric: false,
    disablePadding: false,
    label: "SKS Mahasiswa",
  },
  {
    id: "kode-mk",
    numeric: false,
    disablePadding: false,
    label: "Kode Mata Kuliah",
  },
  {
    id: "nama-mk",
    numeric: false,
    disablePadding: false,
    label: "Nama Mata Kuliah",
  },
  {
    id: "sks-mk",
    numeric: false,
    disablePadding: false,
    label: "SKS Mata Kuliah",
  },
  {
    id: "univ-penyedia",
    numeric: false,
    disablePadding: false,
    label: "Perguruan Tinggi Penyedia",
  },
  {
    id: "fakultas-penyedia",
    numeric: false,
    disablePadding: false,
    label: "Fakultas Penyedia",
  },

  {
    id: "prodi-penyedia",
    numeric: false,
    disablePadding: false,
    label: "Prodi Penyedia",
  },

  {
    id: "nama-dosen",
    numeric: false,
    disablePadding: false,
    label: "Nama Dosen",
  },
];

const EnhancedTableHead = (props) => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
