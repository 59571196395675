import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: theme.spacing(1),
  },
  loadContent: {
    height: "80px",
  },
}));

const LoadUniversitas = () => {
  const classes = useStyles();

  return (
    <Card>
      <Skeleton variant="rect" width="100%" height="180px" />
      <CardContent className={classes.loadContent}>
        <Skeleton variant="text" className={classes.marginTop} />
      </CardContent>
    </Card>
  );
};

export default React.memo(LoadUniversitas);
