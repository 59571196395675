import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Typography,
  Checkbox,
  Divider,
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  title: {
    fontWeight: "bold",
    color: "#66C7F2"
  },
  check: {
    width: "5px",
    height: "5px",
  },
  listFilter: {
    marginTop: theme.spacing(3),
  },
  textFilter: {
    marginTop: theme.spacing(1.1),
  },
  checkContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const FilterUniversitas = ({
  title,
  listFilter,
  setSelected,
  selected,
  actionFakultas,
  actionProdi,
}) => {
  const classes = useStyles();

  const handleSetState = (event, index) => {
    if (event.target.checked) {
      actionFakultas(listFilter.data[index].university_code);
      actionProdi(listFilter.data[index].university_code);
      return setSelected({
        universitas: listFilter.data[index].university_code,
        fakultas: "",
        prodi: "",
        semester: "",
      });
    }
    return setSelected({
      universitas: "",
      fakultas: "",
      prodi: "",
      semester: "",
    });
  };

  const isSelected = (id) => {
    if (selected.universitas !== "") {
      return selected.universitas === id;
    }
    return false;
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} variant="h5">
          {title}
        </Typography>
        <Divider />
        <div className={classes.listFilter}>
          {listFilter.data.map((data, index) => {
            const isItemSelected = isSelected(data.university_code);

            return (
              <Grid
                className={classes.textFilter}
                container
                justify="space-between"
                key={index}
              >
                <Grid item xs={10}>
                  <Typography noWrap>{data.name}</Typography>
                </Grid>
                <Grid item xs={2} className={classes.checkContainer}>
                  <Checkbox
                    className={classes.check}
                    color="primary"
                    checked={isItemSelected}
                    onChange={(e) => handleSetState(e, index)}
                  />
                </Grid>
              </Grid>
            );
          })}
        </div>
      </CardContent>
    </Card>
  );
};

export default FilterUniversitas;
