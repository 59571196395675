import {
  PROFILE_PRODI_START,
  PROFILE_PRODI_FAIL,
  SET_PROFILE_PRODI,
} from "../../actions/prodi/types";
import { updateObject } from "../../../utils";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const profileProdiStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const setProfileProdi = (state, action) => {
  return updateObject(state, { data: action.payload, loading: false });
};

const profileProdiFail = (state, action) => {
  return updateObject(state, { error: action.payload, loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_PRODI_START:
      return profileProdiStart(state, action);
    case SET_PROFILE_PRODI:
      return setProfileProdi(state, action);
    case PROFILE_PRODI_FAIL:
      return profileProdiFail(state, action);
    default:
      return state;
  }
};

export default reducer;
