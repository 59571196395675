import {
  FILTER_UNIVERSITAS_START,
  FILTER_UNIVERSITAS_SUCCESS,
  FILTER_UNIVERSITAS_FAIL,
  FILTER_FAKULTAS_START,
  FILTER_FAKULTAS_SUCCESS,
  FILTER_FAKULTAS_FAIL,
  FILTER_PRODI_START,
  FILTER_PRODI_SUCCESS,
  FILTER_PRODI_FAIL,
} from "./types";
import axios from "../../../api/axios-orders";
import { setAlert } from "../alert";

// DUMMY DATA
const dummyUniversitas = [
  {
    university_id: 652,
    university_code: "11111",
    name: "Universitas Esa Unggul",
  },
  {
    university_id: 762,
    university_code: "11112",
    name: "Universitas Budi Luhur",
  },
  {
    university_id: 261,
    university_code: "11113",
    name: "Universitas Pancasila",
  },
  {
    university_id: 261,
    university_code: "11114",
    name: "Universitas Pembangunan Panca Budi",
  },
  {
    university_id: 182,
    university_code: "11115",
    name: "Universitas Janabadra",
  },
  {
    university_id: 182,
    university_code: "11116",
    name: "Sekolah Tinggi Manajemen Handayani",
  },
  {
    university_id: 182,
    university_code: "11117",
    name: "Sekolah Tinggi Agama Islam Ambon",
  },
  {
    university_id: 182,
    university_code: "11118",
    name: "Universitas Pasundan",
  },
  {
    university_id: 182,
    university_code: "11119",
    name: "STMIK Asia Malang",
  },
  {
    university_id: 182,
    university_code: "11120",
    name: "Universitas Darma Agung",
  },
  {
    university_id: 182,
    university_code: "11121",
    name: "Universitas Ichsan Gorontalo",
  },
  {
    university_id: 182,
    university_code: "11122",
    name: "IKIP Negeri Gorontalo",
  },
  {
    university_id: 182,
    university_code: "11123",
    name: "Universitas Yarsi",
  },
  {
    university_id: 182,
    university_code: "11124",
    name: "Universitas Muhammadiyah Sumatra Utara",
  },
];

const dummyFakultas = [
  {
    faculty_uuid: "f4412052-24b5-49da-9419-8d7sa87das",
    name: "Teknik",
    cooperation_university_id: "39f2fa64-c7a6-41d4-ba79-d9s898sd9s",
  },
];

const dummyProdi = [
  {
    major_uuid: "644fcc2b-0cce-4d1a-8248-98d98sjh",
    major_name: "Teknik Informatika",
    cooperation_university_id: "39f2fa64-c7a6-41d4-ba79-sa98sa8a9",
  },
  {
    major_uuid: "644fcc2b-0cce-4d1a-d9sa898dsa",
    major_name: "Teknik Industri",
    cooperation_university_id: "39f2fa64-c7a6-41d4-ba79-sa98sa8a9",
  },
  {
    major_uuid: "644fcc2b-0cce-4d1a-das878dsa7",
    major_name: "Teknik Elektro",
    cooperation_university_id: "39f2fa64-c7a6-41d4-ba79-sa98sa8a9",
  },
];

export const getFilterUniversitas = () => async (dispatch) => {
  dispatch({
    type: FILTER_UNIVERSITAS_START,
  });
  try {
    const response = await axios.get("/api/navbar/university", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    // dispatch({
    //   type: FILTER_UNIVERSITAS_SUCCESS,
    //   payload: response.data.data,
    // });

    // ======
    dispatch({
      type: FILTER_UNIVERSITAS_SUCCESS,
      payload: [...response.data.data, ...dummyUniversitas],
    });
  } catch (error) {
    dispatch({
      type: FILTER_UNIVERSITAS_FAIL,
      payload: error.response.message,
    });
    dispatch(setAlert("Can't get data university", "error"));
  }
};

export const getFilterFakultas = (university_code) => async (dispatch) => {
  dispatch({
    type: FILTER_FAKULTAS_START,
  });
  try {
    const response = await axios.get(
      `/api/navbar/university/${university_code}/faculty`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    // dispatch({
    //   type: FILTER_FAKULTAS_SUCCESS,
    //   payload: response.data.data,
    // });
    // YANG ELSE YANG BENAR
    if (
      university_code === "11111" ||
      university_code === "11112" ||
      university_code === "11113" ||
      university_code === "11114" ||
      university_code === "11115" ||
      university_code === "11116" ||
      university_code === "11117" ||
      university_code === "11118" ||
      university_code === "11119" ||
      university_code === "11120" ||
      university_code === "11121" ||
      university_code === "11122" ||
      university_code === "11123" ||
      university_code === "11124"
    ) {
      dispatch({
        type: FILTER_FAKULTAS_SUCCESS,
        payload: dummyFakultas,
      });
    } else {
      dispatch({
        type: FILTER_FAKULTAS_SUCCESS,
        payload: response.data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: FILTER_FAKULTAS_FAIL,
      payload: error.response.message,
    });
    dispatch(setAlert("Can't get data faculty", "error"));
  }
};

export const getFilterProdi = (code_university) => async (dispatch) => {
  dispatch({
    type: FILTER_PRODI_START,
  });
  try {
    const response = await axios.get(
      `/api/navbar/university/${code_university}/major`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    // dispatch({
    //   type: FILTER_PRODI_SUCCESS,
    //   payload: response.data.data,
    // });

    if (
      code_university === "11111" ||
      code_university === "11112" ||
      code_university === "11113" ||
      code_university === "11114" ||
      code_university === "11115" ||
      code_university === "11116" ||
      code_university === "11117" ||
      code_university === "11118" ||
      code_university === "11119" ||
      code_university === "11120" ||
      code_university === "11121" ||
      code_university === "11122" ||
      code_university === "11123" ||
      code_university === "11124"
    ) {
      dispatch({
        type: FILTER_PRODI_SUCCESS,
        payload: dummyProdi,
      });
    } else {
      dispatch({
        type: FILTER_PRODI_SUCCESS,
        payload: response.data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: FILTER_PRODI_FAIL,
      payload: error.response.message,
    });
    dispatch(setAlert("Can't get data major", "error"));
  }
};
