import {
  FILTER_UNIVERSITAS_START,
  FILTER_UNIVERSITAS_SUCCESS,
  FILTER_UNIVERSITAS_FAIL,
  FILTER_FAKULTAS_START,
  FILTER_FAKULTAS_SUCCESS,
  FILTER_FAKULTAS_FAIL,
  FILTER_PRODI_START,
  FILTER_PRODI_SUCCESS,
  FILTER_PRODI_FAIL,
  FILTER_MATA_KULIAH_START,
  FILTER_MATA_KULIAH_SUCCESS,
  FILTER_MATA_KULIAH_FAIL,
  CLEAR_FILTER_UNIVERSITAS,
  CLEAR_FILTER_FAKULTAS,
  CLEAR_FILTER_PRODI,
} from "./actionTypes";
import axios from "../../../api/axios-orders";
import { setAlert } from "../alert";

export const getFilterUniversitas = () => async (dispatch) => {
  dispatch({
    type: FILTER_UNIVERSITAS_START,
  });
  try {
    const response = await axios.get("/aptisi/dropdown/university", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    const data = response.data.data.map((value) => ({
      value: value.id,
      label: value.name,
      code: value.code,
    }));
    dispatch({
      type: FILTER_UNIVERSITAS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FILTER_UNIVERSITAS_FAIL,
      payload: error.response.data.message,
    });
    dispatch(setAlert("Can't get data university", "error"));
  }
};

export const getFilterFakultas = (university_id) => async (dispatch) => {
  dispatch({
    type: FILTER_FAKULTAS_START,
  });
  try {
    const response = await axios.get(
      `/aptisi/dropdown/university/${university_id}/faculty `,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );

    const data = response.data.data.map((value) => ({
      value: value.uuid,
      label: value.name,
    }));
    dispatch({
      type: FILTER_FAKULTAS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FILTER_FAKULTAS_FAIL,
      payload: error.response.data.message,
    });
    dispatch(setAlert("Can't get data faculty", "error"));
  }
};

export const getFilterProdi = (university_id, faculty_uuid) => async (
  dispatch
) => {
  dispatch({
    type: FILTER_PRODI_START,
  });
  try {
    const response = await axios.get(
      `/aptisi/dropdown/university/${university_id}/faculty/${faculty_uuid}/major`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    const data = response.data.data.map((value) => ({
      value: value.uuid,
      label: value.name,
    }));
    dispatch({
      type: FILTER_PRODI_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FILTER_PRODI_FAIL,
      payload: error.response.data.message,
    });
    dispatch(setAlert("Can't get data major", "error"));
  }
};

export const getFilterMataKuliah = (university_id, prodi_uuid) => async (
  dispatch
) => {
  dispatch({
    type: FILTER_MATA_KULIAH_START,
  });
  try {
    const response = await axios.get(
      `/aptisi/dropdown/university/${university_id}/major/${prodi_uuid}/course`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    const data = response.data.data.map((value) => ({
      value: value.uuid,
      label: value.name,
    }));
    dispatch({
      type: FILTER_MATA_KULIAH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FILTER_MATA_KULIAH_FAIL,
      payload: error.response.data.message,
    });
    dispatch(setAlert("Can't get data course", "error"));
  }
};

export const clearFilterUniversitas = () => {
  return {
    type: CLEAR_FILTER_UNIVERSITAS,
  };
};

export const clearFilterFakultas = () => {
  return {
    type: CLEAR_FILTER_FAKULTAS,
  };
};

export const clearFilterProdi = () => {
  return {
    type: CLEAR_FILTER_PRODI,
  };
};
