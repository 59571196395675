import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  colors,
  Tooltip,
  Collapse,
  List,
  ListItem,
  SwipeableDrawer,
  Divider,
  ListItemText,
  Hidden,
  IconButton,
} from "@material-ui/core";
import { Link as RouterLink, useLocation, useHistory } from "react-router-dom";

import StorageIcon from "@material-ui/icons/Storage";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import BallotIcon from "@material-ui/icons/Ballot";
import AssessmentIcon from "@material-ui/icons/Assessment";
import GroupIcon from "@material-ui/icons/Group";
import SyncIcon from "@material-ui/icons/Sync";

import { Footer, AppBar, AccountName, MobileDrawer } from "./components";

const drawerWidth = 240;
const drawerColorBlue = "#fff";

const textMenuBlack = "#AEAEA";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    display: "flex",
  },
  button: {
    color: colors.blueGrey[800],
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
  },
  content: {
    position: "relative",
    flexGrow: 1,
    marginTop: 56,
    marginLeft: 0,
    width: "calc(100% - 240px)",
    paddingTop: 40,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(2),
    },
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: drawerColorBlue,
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
    background: drawerColorBlue,
    paddingTop: theme.spacing(1.2),
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: drawerColorBlue,
  },
  menus: {
    paddingTop: theme.spacing(3),
  },
  active: {
    backgroundColor: "rgba(2, 99, 196, 0.2)",
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: theme.palette.primary.main,
    },
  },
  activeNested: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: theme.palette.primary.main,
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  bottomPush: {
    position: "fixed",
    bottom: 0,
    textAlign: "center",
    paddingBottom: 10,
    width: "auto",
  },
  textMenu: {
    color: textMenuBlack,
    paddingLeft: theme.spacing(2),
  },
  textMenuNested: {
    color: textMenuBlack,
    fontSize: "14px",
    paddingLeft: theme.spacing(2),
  },
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },

  icon: {
    color: textMenuBlack,
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },

  iconActive: {
    color: theme.palette.primary.main,
  },

  backdrop: {
    position: "absolute",
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  nested: {
    paddingLeft: theme.spacing(4),
    marginBottom: "-20px",
  },

  nested2: {
    paddingLeft: theme.spacing(7),
    marginBottom: "-20px",
  },

  imgDrawerOpen: {
    width: "auto",
    height: "100px",
  },

  imgDrawerClose: {
    width: "auto",
    height: "50px",
  },

  classRootDrawerOpen: {
    marginBottom: theme.spacing(9),
  },
  classRootDrawerClose: {
    marginBottom: theme.spacing(5),
  },
  btnLogout: {
    margin: theme.spacing(3, 3, 3, 5),
  },

  btnLogoutIcon: {
    margin: theme.spacing(0, 4, 0, 3),
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const MainAdmin = (props) => {
  const { children } = props;
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();

  const [open, setOpen] = useState(true);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [openManage, setOpenManage] = useState(false);

  const handleClickManage = () => {
    setOpenManage(!openManage);
  };

  const drawerHandler = () => {
    setOpen((prev) => !prev);
    if (openManage) {
      setOpenManage(!openManage);
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerMobileOpen = () => {
    setMobileMenu(true);
  };

  const handleDrawerMobileClose = () => {
    setMobileMenu(false);
  };

  const onLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("data");
    localStorage.removeItem("role");
    localStorage.clear();
    history.push("/login-admin");
  };

  return (
    <div className={classes.root}>
      <Hidden mdUp>
        <AppBar handleDrawerMobileOpen={handleDrawerMobileOpen} />
        <MobileDrawer onClose={handleDrawerMobileClose} open={mobileMenu} />
      </Hidden>
      <Hidden smDown>
        <AppBar
          handleDrawerOpen={drawerHandler}
          open={open}
          setOpen={setOpen}
        />
        <SwipeableDrawer
          anchor="left"
          variant="permanent"
          open={open}
          onClose={handleDrawerClose}
          onOpen={handleDrawerOpen}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <AccountName
            className={open ? classes.imgDrawerOpen : classes.imgDrawerClose}
            classRoot={
              open ? classes.classRootDrawerOpen : classes.classRootDrawerClose
            }
          />

          {/* LIST MENU ADMIN */}
          <List className={classes.menus}>
            {/* Menu Dashboard */}
            <ListItem key="dashboard" button className={classes.item}>
              <Button
                className={clsx(classes.button, {
                  [classes.active]: location.pathname === "/admin" && open,
                  [classes.iconActive]: location.pathname === "/admin" && !open,
                })}
                component={CustomRouterLink}
                to="/admin"
              >
                {open ? (
                  <>
                    <div className={classes.icon}>
                      <DashboardIcon style={{ color: textMenuBlack }} />
                    </div>
                    <ListItemText
                      className={classes.textMenu}
                      primary="Dashboard"
                      disableTypography={true}
                    />
                  </>
                ) : (
                  <Tooltip title="Dashboard" placement="right" arrow>
                    <div className={classes.icon}>
                      <DashboardIcon style={{ color: textMenuBlack }} />
                    </div>
                  </Tooltip>
                )}
              </Button>
            </ListItem>

            {/* Menu Manage */}
            {open && (
              <>
                <ListItem key="manage" button className={classes.item}>
                  <Button
                    className={clsx(classes.button, {
                      [classes.active]:
                        (location.pathname === "/admin/manage-banner" ||
                          location.pathname === "/admin/manage-artikel" ||
                          location.pathname === "/admin/manage-user" ||
                          location.pathname === "/admin/tambah-artikel" ||
                          location.pathname === "/admin/edit-artikel/*") &&
                        open,
                    })}
                    component={`div`}
                    onClick={handleClickManage}
                  >
                    <div className={classes.icon}>
                      <StorageIcon style={{ color: textMenuBlack }} />
                    </div>
                    <ListItemText
                      className={classes.textMenu}
                      primary="Manage"
                      disableTypography={true}
                    />
                    {openManage ? (
                      <ExpandLess style={{ color: textMenuBlack }} />
                    ) : (
                      <ExpandMore style={{ color: textMenuBlack }} />
                    )}
                  </Button>
                </ListItem>
                <Collapse in={openManage} timeout="auto" unmountOnExit>
                  <List style={{ marginTop: "-10px" }} disablePadding>
                    <ListItem key="banner" button className={classes.nested}>
                      <Button
                        className={clsx(classes.button, {
                          [classes.activeNested]:
                            location.pathname === "/admin/manage-banner" &&
                            open,
                        })}
                        component={CustomRouterLink}
                        to="/admin/manage-banner"
                      >
                        <div className={classes.icon}>
                          <ViewCarouselIcon style={{ color: textMenuBlack }} />
                        </div>
                        <ListItemText
                          className={classes.textMenu}
                          primary="Manage Banner"
                          disableTypography={true}
                        />
                      </Button>
                    </ListItem>
                    <ListItem key="artikel" button className={classes.nested}>
                      <Button
                        className={clsx(classes.button, {
                          [classes.activeNested]:
                            location.pathname === "/admin/manage-artikel" ||
                            location.pathname === "/admin/tambah-artikel" ||
                            (location.pathname === "/admin/edit-artikel" &&
                              open),
                        })}
                        component={CustomRouterLink}
                        to="/admin/manage-artikel"
                      >
                        <div className={classes.icon}>
                          <SpeakerNotesIcon style={{ color: textMenuBlack }} />
                        </div>
                        <ListItemText
                          className={classes.textMenu}
                          primary="Manage Artikel"
                          disableTypography={true}
                        />
                      </Button>
                    </ListItem>

                    <ListItem key="user" button className={classes.nested}>
                      <Button
                        className={clsx(classes.button, {
                          [classes.activeNested]:
                            location.pathname === "/admin/manage-user",
                        })}
                        component={CustomRouterLink}
                        to="/admin/manage-user"
                      >
                        <div className={classes.icon}>
                          <GroupIcon style={{ color: textMenuBlack }} />
                        </div>
                        <ListItemText
                          className={classes.textMenu}
                          primary="Manage User"
                          disableTypography={true}
                        />
                      </Button>
                    </ListItem>
                  </List>
                </Collapse>
              </>
            )}
            {!open && (
              <>
                <ListItem key="manageBanner" button className={classes.item}>
                  <Button
                    className={clsx(classes.button, {
                      [classes.iconActive]:
                        location.pathname === "/admin/manage-banner" && !open,
                    })}
                    component={CustomRouterLink}
                    to="/admin/manage-banner"
                  >
                    <Tooltip title="Manage Banner" placement="right" arrow>
                      <div className={classes.icon}>
                        <ViewCarouselIcon style={{ color: textMenuBlack }} />
                      </div>
                    </Tooltip>
                  </Button>
                </ListItem>
                <ListItem key="manageArtikel" button className={classes.item}>
                  <Button
                    className={clsx(classes.button, {
                      [classes.iconActive]:
                        (location.pathname === "/admin/manage-artikel" ||
                          location.pathname === "/admin/tambah-artikel" ||
                          location.pathname === "/admin/edit-artikel") &&
                        !open,
                    })}
                    component={CustomRouterLink}
                    to="/admin/manage-artikel"
                  >
                    <Tooltip title="Manage Artikel" placement="right" arrow>
                      <div className={classes.icon}>
                        <SpeakerNotesIcon style={{ color: textMenuBlack }} />
                      </div>
                    </Tooltip>
                  </Button>
                </ListItem>

                <ListItem key="manageUser" button className={classes.item}>
                  <Button
                    className={clsx(classes.button, {
                      [classes.iconActive]:
                        location.pathname === "/admin/manage-user",
                    })}
                    component={CustomRouterLink}
                    to="/admin/manage-user"
                  >
                    <Tooltip title="Manage User" placement="right" arrow>
                      <div className={classes.icon}>
                        <GroupIcon style={{ color: textMenuBlack }} />
                      </div>
                    </Tooltip>
                  </Button>
                </ListItem>
              </>
            )}

            {/* Menu Detail Mata Kuliah */}
            <ListItem key="detail-mata-kuliah" button className={classes.item}>
              <Button
                className={clsx(classes.button, {
                  [classes.active]:
                    location.pathname === "/admin/detail-mata-kuliah" && open,
                  [classes.iconActive]:
                    location.pathname === "/admin/detail-mata-kuliah" && !open,
                })}
                component={CustomRouterLink}
                to="/admin/detail-mata-kuliah"
              >
                {open ? (
                  <>
                    <div className={classes.icon}>
                      <BallotIcon style={{ color: textMenuBlack }} />
                    </div>
                    <ListItemText
                      className={classes.textMenu}
                      primary="Detail Mata Kuliah"
                      disableTypography={true}
                    />
                  </>
                ) : (
                  <Tooltip title="Detail Mata Kuliah" placement="right" arrow>
                    <div className={classes.icon}>
                      <BallotIcon style={{ color: textMenuBlack }} />
                    </div>
                  </Tooltip>
                )}
              </Button>
            </ListItem>

            {/* Menu Laporan Data Transaksi */}
            <ListItem
              key="laporan-data-transaksi"
              button
              className={classes.item}
            >
              <Button
                className={clsx(classes.button, {
                  [classes.active]:
                    location.pathname === "/admin/laporan-data-transaksi" &&
                    open,
                  [classes.iconActive]:
                    location.pathname === "/admin/laporan-data-transaksi" &&
                    !open,
                })}
                component={CustomRouterLink}
                to="/admin/laporan-data-transaksi"
              >
                {open ? (
                  <>
                    <div className={classes.icon}>
                      <AssessmentIcon style={{ color: textMenuBlack }} />
                    </div>
                    <ListItemText
                      className={classes.textMenu}
                      primary="Laporan Data Transaksi"
                      disableTypography={true}
                    />
                  </>
                ) : (
                  <Tooltip
                    title="Laporan Data Transaksi"
                    placement="right"
                    arrow
                  >
                    <div className={classes.icon}>
                      <AssessmentIcon style={{ color: textMenuBlack }} />
                    </div>
                  </Tooltip>
                )}
              </Button>
            </ListItem>

            {/* Menu Sinkronisasi */}
            <ListItem key="sync" button className={classes.item}>
              <Button
                className={clsx(classes.button, {
                  [classes.active]: location.pathname === "/admin/sync" && open,
                  [classes.iconActive]:
                    location.pathname === "/admin/sync" && !open,
                })}
                component={CustomRouterLink}
                to="/admin/sync"
              >
                {open ? (
                  <>
                    <div className={classes.icon}>
                      <SyncIcon style={{ color: textMenuBlack }} />
                    </div>
                    <ListItemText
                      className={classes.textMenu}
                      primary="Sinkronisasi"
                      disableTypography={true}
                    />
                  </>
                ) : (
                  <Tooltip title="Sinkronisasi" placement="right" arrow>
                    <div className={classes.icon}>
                      <SyncIcon style={{ color: textMenuBlack }} />
                    </div>
                  </Tooltip>
                )}
              </Button>
            </ListItem>
          </List>

          <Divider />
          {open ? (
            <Button
              onClick={onLogout}
              className={classes.btnLogout}
              color="primary"
              variant="contained"
            >
              Logout
            </Button>
          ) : (
            <Tooltip title="Logout" placement="right" arrow>
              <IconButton
                onClick={onLogout}
                className={classes.btnLogoutIcon}
                aria-label="logout"
                color="primary"
              >
                <ExitToAppIcon />
              </IconButton>
            </Tooltip>
          )}
        </SwipeableDrawer>
      </Hidden>
      <main className={classes.content}>
        {children}
        <Footer />
      </main>
    </div>
  );
};

MainAdmin.propTypes = {
  children: PropTypes.node,
};

export default connect(null)(MainAdmin);
