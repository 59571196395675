import {
  SET_PRODI_PENYEDIA_START,
  SET_PRODI_PENYEDIA_SUCCESS,
  SET_PRODI_PENYEDIA_FAIL,
} from "./types";
import axios from "../../../api/axios-orders";
import { setAlert } from "../alert";

export const setProdiPenyediaStart = () => {
  return {
    type: SET_PRODI_PENYEDIA_START,
  };
};

export const getProdiPenyedia = ({
  page = 1,
  size = 10,
  search = "",
}) => async (dispatch) => {
  dispatch(setProdiPenyediaStart());
  try {
    const res = await axios.get(
      `/major/class?search=${search}&page=${page}&page_size=${size}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );

    dispatch({
      type: SET_PRODI_PENYEDIA_SUCCESS,
      payload: res.data.data || [] ,
      current_page: page,
      has_previous_page: res.data.has_previous_page,
      has_next_page: res.data.has_next_page,
      previous_page: res.data.previous_page,
      next_page: res.data.next_page,
      last_page: res.data.last_page,
      total_item: res.data.total_item,
    });
  } catch (error) {
    dispatch({
      type: SET_PRODI_PENYEDIA_FAIL,
      payload: error.response.data.message,
    });
    dispatch(setAlert("Can't get data class!", "error"));
  }
};
