import { SET_UNIVERSITAS, SET_LOADING, SET_LOADING_DONE } from "./actionTypes";
import axios from "../../../api/axios-orders";
import { setAlert } from "../alert";

export const setLoading = () => {
  return {
    type: SET_LOADING,
  };
};

export const setLoadingDone = () => {
  return {
    type: SET_LOADING_DONE,
  };
};

export const getUniversitas = (page, page_size) => async (dispatch) => {
  dispatch(setLoading);
  try {
    const res = await axios.get(
      `/admin/university?page=${page}&page_size=${page_size}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    dispatch({
      type: SET_UNIVERSITAS,
      payload: res.data.data,
      current_page: page,
      has_previous_page: res.data.has_previous_page,
      has_next_page: res.data.has_next_page,
      previous_page: res.data.previous_page,
      next_page: res.data.next_page,
      last_page: res.data.last_page,
      total_item: res.data.total_item,
    });
  } catch (error) {
    dispatch(setAlert("Can't get data universitas", "error"));
  }
};

export const createUniversitas = (data) => async (dispatch) => {
  dispatch(setLoading());
  try {
    await axios.post(`/admin/university`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch(setAlert("Universitas berhasil dibuat", "success"));
    dispatch(getUniversitas(1, 10));
  } catch (error) {
    dispatch(setAlert("Data Gagal Di Buat", "error"));
  }
};

export const updateUniversitas = (data, id) => async (dispatch) => {
  dispatch(setLoading());
  try {
    await axios.put(`/admin/university/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch(setAlert("Data Berhasil Di Update", "success"));
    dispatch(getUniversitas(1, 10));
  } catch (error) {
    dispatch(setAlert("Data Gagal Di Update", "error"));
  }
};

export const deleteUniversitas = (id) => async (dispatch) => {
  dispatch(setLoading());
  try {
    await axios.delete(`/admin/university/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch(setAlert("Universitas Berhasil Di Hapus", "success"));
    dispatch(getUniversitas(1, 10));
  } catch (error) {
    dispatch(setAlert("Data Gagal Di Hapus", "error"));
  }
};

export const syncUniversitas = (id) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const res = await axios.get(`/admin/university/${id}/sync `, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch(setAlert("Berhasil Sinkronisasi"));
    dispatch(getUniversitas(1, 10));
  } catch (error) {
    dispatch(setAlert("Gagal Melakukan Sinkronisasi", "error"));
  }
};
