import { ASSESSMENT_START, ASSESSMENT_FAIL, ASSESSMENT_SUCCESS } from "./types";
import axios from "../../../api/axios-orders";
import { setAlert } from "../alert";

export const getAssessment = (class_id) => async (dispatch) => {
  dispatch({ type: ASSESSMENT_START });
  try {
    const response = await axios.get(`/student/class/${class_id}/assessment`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({ type: ASSESSMENT_SUCCESS, payload: response.data.data });
  } catch (error) {
    dispatch({ type: ASSESSMENT_FAIL });
    dispatch(setAlert("Something wen't wrong", "error"));
  }
};
