/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";

import {
  Container,
  Typography,
  Card,
  CardContent,
  Button,
  CardActions,
  Box,
} from "@material-ui/core";

import { connect } from "react-redux";
import {
  getProfileProdi,
  setAlert,
  uploadLogoMataKuliah,
} from "../../../store/actions";

import axios from "../../../api/axios-orders";

import ImageUploader from "react-images-upload";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: 5,
    },
  },
  title: {
    fontWeight: 700,
    marginBottom: theme.spacing(5),
  },

  mb: {
    marginBottom: theme.spacing(2),
  },
}));

const MataKuliahUpload = ({
  profileProdi,
  getProfileProdi,
  setAlert,
  uploadLogoMataKuliah,
}) => {
  const classes = useStyles();
  const [image, setImage] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);

  const params = useParams();
  const history = useHistory();

  const onDrop = (picture) => {
    setImage(picture);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    if (image.length === 0) {
      setIsSubmit(false);
      return setAlert("Logo Belum Di Upload", "error");
    }

    const dataImage = new FormData();
    dataImage.append("file", image[0]);
    dataImage.append("dir", "logo");

    try {
      const res = await axios.post(`/major/file/upload `, dataImage, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      const formData = {
        image: res.data.data,
      };
      setIsSubmit(false);
      uploadLogoMataKuliah(params.uuid, formData, history);
    } catch (error) {
      setIsSubmit(false);
      setAlert("Logo Gagal Di Upload", "error");
    }
  };

  useEffect(() => {
    getProfileProdi();
  }, []);

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        {profileProdi.data?.university_name}
      </Typography>

      <Card>
        <form onSubmit={onSubmit}>
          <CardContent>
            <ImageUploader
              withIcon={true}
              buttonText="Upload Logo"
              onChange={onDrop}
              imgExtension={[".jpg", ".jpeg", ".png"]}
              maxFileSize={2242880}
              withPreview={true}
              label="Max file size : 2 mb, Format : jpeg, png, jpg"
              accept="image/jpg,image/jpeg,image/png"
              singleImage={true}
              name="logo"
            />
          </CardContent>
          <CardActions>
            <Box display="flex" justifyContent="center" width="100%">
              {!isSubmit ? (
                <Button
                  className={classes.btnSave}
                  type="submit"
                  variant="contained"
                  color="secondary"
                >
                  Save
                </Button>
              ) : (
                <Button
                  className={classes.btnSave}
                  variant="contained"
                  color="secondary"
                  disabled
                >
                  Save
                </Button>
              )}
            </Box>
          </CardActions>
        </form>
      </Card>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    profileProdi: state.profileProdi,
  };
};

export default connect(mapStateToProps, {
  getProfileProdi,
  setAlert,
  uploadLogoMataKuliah,
})(MataKuliahUpload);
