/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Card, Divider, Grid, Typography } from "@material-ui/core";
import moment from "moment";

import { LoadKeranjang } from "../../../Components/Skeleton";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "none",
    marginTop: theme.spacing(2),
  },
  image: {
    width: "100%",
  },
  media: {
    width: "100%",
    borderRadius: "10xp",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: theme.spacing(2),
  },
  textPerkuliahan: {
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "29px",
  },
  textUniversitas: {
    fontSize: "18px",
    lineHeight: "27px",
    fontWeight: 400,
  },
  divider: {
    marginTop: theme.spacing(1),
    backgroundColor: "#263238",
    [theme.breakpoints.only("xs")]: {
      marginBottom: theme.spacing(4),
    },
  },
  btnContainer: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    [theme.breakpoints.only("xs")]: {
      justifyContent: "center",
      alignItems: "center",
    },
  },
  btnCheckout: {
    width: "150px",
    color: "#fff",
    backgroundColor: "#EE6A0D",
    "&:hover": {
      backgroundColor: "#D63A00",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  btnHapus: {
    marginTop: theme.spacing(1),
    width: "150px",
    color: "#EE6A0D",
    border: "1px solid #EE6A0D",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  btnHome: {
    marginTop: theme.spacing(2),
  },
}));

const ListItem = ({ list, setDialogDelete, setDialogCheckout }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleCheckout = (id) => {
    const data = {
      token_sia: localStorage.getItem("token_sia"),
      checkout: [
        {
          class_uuid: id,
        },
      ],
    };
    setDialogCheckout({ open: true, data: data });
  };

  return (
    <>
      {list.data.map((data) => (
        <div key={data.cart_id}>
          <Card className={classes.card}>
            <Grid container>
              <Grid item xs={5} sm={2} className={classes.image}>
                <img
                  className={classes.media}
                  src={data.image !== "" ? data.image : data.university_logo}
                />
              </Grid>
              <Grid item xs={7} sm={7} className={classes.textContainer}>
                <Typography varinat="h5" className={classes.textPerkuliahan}>
                  {data.course_name}
                </Typography>
                <Typography varinat="h5" className={classes.textUniversitas}>
                  {data.university_name}
                </Typography>
                <Typography variant="body1" className={classes.textTime}>
                  {`${data.day}, ${moment(
                    data.first_class_date,
                    "dddd, DD MMM YYYY"
                  ).format("DD MMM YYYY")}`}
                </Typography>
                <Typography variant="body1" className={classes.textTime}>
                  {`${data.period || "-"} WIB`}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} className={classes.btnContainer}>
                <Button
                  variant="contained"
                  className={classes.btnCheckout}
                  onClick={() => handleCheckout(data.class_uuid)}
                >
                  Checkout
                </Button>
                <Button
                  variant="contained"
                  className={classes.btnHapus}
                  onClick={() =>
                    setDialogDelete({ open: true, id: data.cart_id })
                  }
                >
                  Hapus
                </Button>
              </Grid>
            </Grid>
          </Card>
          <Divider className={classes.divider} />
        </div>
      ))}

      {/* Handling Loading Data */}
      {list.loading &&
        Array(3)
          .fill("a")
          .map((_, index) => <LoadKeranjang key={index} />)}

      {/* Handle Empty data */}
      {list.data.length === 0 && !list.loading && (
        <>
          <Typography variant="h1">Oopps.... keranjang anda kosong</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push("/")}
            className={classes.btnHome}
          >
            Kembali ke Home
          </Button>
        </>
      )}
    </>
  );
};

export default ListItem;
