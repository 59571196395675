import {
  SET_TRANSAKSI_START,
  SET_TRANSAKSI_SUCCESS,
  SET_TRANSAKSI_FAIL,
  SET_PRINT_START,
  SET_PRINT_SUCCESS,
  SET_PRINT_FAIL,
} from "./actionTypes";
import axios from "../../../api/axios-orders";
import { setAlert } from "../alert";
import fileDownload from "js-file-download";

export const setTransaksiStart = () => {
  return {
    type: SET_TRANSAKSI_START,
  };
};

export const getTransaksi = ({
  page = 1,
  size = 10,
  search = "",
  university_code = "",
  faculty_uuid = "",
  major_uuid = "",
  course_name = "",
}) => async (dispatch) => {
  dispatch(setTransaksiStart());
  let url;
  if (search !== "") {
    url = `/admin/transaction?search=${search}&page=${page}&page_size=${size}`;
  } else {
    url = `/admin/transaction?university_code=${university_code}&faculty_uuid=${faculty_uuid}&major_uuid=${major_uuid}&course_name=${course_name}&page=${page}&page_size=${size}`;
  }
  try {
    const res = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    dispatch({
      type: SET_TRANSAKSI_SUCCESS,
      payload: res.data.data,
      current_page: page,
      has_previous_page: res.data.has_previous_page,
      has_next_page: res.data.has_next_page,
      previous_page: res.data.previous_page,
      next_page: res.data.next_page,
      last_page: res.data.last_page,
      total_item: res.data.total_item,
    });
  } catch (error) {
    dispatch({
      type: SET_TRANSAKSI_FAIL,
      payload: error.response.data.message,
    });
    dispatch(setAlert("Can't get data transaction!", "error"));
  }
};

export const printTransaksi = ({
  typeFile = "",
  university_code = "",
  faculty_uuid = "",
  major_uuid = "",
  course_name = "",
  search = "",
}) => async (dispatch) => {
  dispatch({
    type: SET_PRINT_START,
  });
  let contentType = "";
  if (typeFile === "excel") {
    contentType = "application/vnd.ms-excel";
  } else {
    contentType = "application/pdf";
  }
  try {
    const res = await axios.get(
      `admin/export/${typeFile}/transaction?university_code=${university_code}&faculty_uuid=${faculty_uuid}&major_uuid=${major_uuid}&course_name=${course_name}&search=${search}`,
      {
        headers: {
          "Content-Type": contentType,
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        responseType: "blob",
      }
    );
    if (typeFile === "excel") {
      fileDownload(res.data, "transaksi.xlsx");
    } else {
      fileDownload(res.data, "transaksi.pdf");
    }

    dispatch({
      type: SET_PRINT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: SET_PRINT_FAIL,
    });
    dispatch(setAlert("Export Failed", "error"));
  }
};
