import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Paper,
  Grid,
  TextField,
  Typography,
  Divider,
  Button,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { connect } from "react-redux";
import { changePassword } from "../../../store/actions";

// React Hook Form
import { useForm } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  paper: {
    padding: theme.spacing(1, 3, 4),
  },
  textTitle: {
    padding: theme.spacing(2, 0),
  },
  boxfield: {
    width: "96%",
    margin: theme.spacing(4, 0),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: theme.spacing(0, 0, 3, 0),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const validationSchema = yup.object().shape({
  old_password: yup
    .string()
    .required("Wajib diisi")
    .min(8, "Minimal 8 Karakter"),
  new_password: yup
    .string()
    .required("Wajib diisi")
    .min(8, "Minimal 8 Karakter"),
  confirm_password: yup
    .string()
    .required("Wajib diisi")
    .oneOf([yup.ref("new_password"), null], "Konfirmasi Password Berbeda")
    .min(8, "Minimal 8 Karakter"),
});

const GantiPassword = ({ auth, changePassword }) => {
  const classes = useStyles();
  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const crumbs = [
    { name: "Dashboard", link: "/" },
    { name: "Ganti-password", link: "" },
  ];

  const onSubmit = (data, e) => {
    e.target.reset();
    changePassword(data);
  };

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container direction="column">
          <Grid item xs={12} className={classes.textTitle}>
            <Typography variant="h4">Ganti Password</Typography>
            <Divider />
          </Grid>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container item xs={12}>
              <Grid item sm={4} xs={12}>
                <TextField
                  className={classes.boxfield}
                  type="password"
                  label="Password Lama"
                  variant="filled"
                  name="old_password"
                  inputRef={register}
                  error={!!errors.old_password}
                  helperText={errors.old_password?.message}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <TextField
                  className={classes.boxfield}
                  type="password"
                  label="Password Baru"
                  variant="filled"
                  name="new_password"
                  inputRef={register}
                  error={!!errors.new_password}
                  helperText={errors.new_password?.message}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <TextField
                  className={classes.boxfield}
                  type="password"
                  label="Konfirmasi Password"
                  variant="filled"
                  name="confirm_password"
                  inputRef={register}
                  error={!!errors.confirm_password}
                  helperText={errors.confirm_password?.message}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                size="large"
                color="primary"
                type="submit"
              >
                Simpan
              </Button>
            </Grid>
          </form>
        </Grid>
      </Paper>
      <Backdrop className={classes.backdrop} open={auth.loadingPassword}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { changePassword })(GantiPassword);
