import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";

const DialogCheckoutAll = ({ dialog, onClose, action, openDialogFinish }) => {
  const handleAction = () => {
    action(dialog.data, openDialogFinish);
    onClose();
  };

  return (
    <Dialog
      open={dialog.open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Checkout Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Apakah anda yakin melakukan checkout semua mata kuliah ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          cancel
        </Button>
        <Button
          onClick={handleAction}
          color="primary"
          autoFocus
          variant="contained"
        >
          checkout all
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogCheckoutAll;
