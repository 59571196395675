import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";
import FiberNewIcon from "@material-ui/icons/FiberNew";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "fixed",
    zIndex: theme.zIndex.drawer + 10,
    bottom: 0,
    "& .MuiBottomNavigationAction-root": {
      maxWidth: "100%",
    },
    "& .Mui-selected": {
      color: theme.palette.lightBlue.color900,
      backgroundColor: theme.palette.blue.color200,
    },
  },
}));

const BottomNavigationMobile = ({ auth }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [value, setValue] = useState(0);

  useEffect(() => {
    let path = location.pathname.split("/");
    if (path[1] === "") {
      setValue(null);
    } else if (path[1] === "perkuliahan") {
      setValue(1);
    } else if (path[1] === "artikel") {
      setValue(0);
    } else if (path[1] === "profile") {
      setValue(2);
    } else {
      setValue(null);
    }
  }, [location]);

  return (
    <BottomNavigation value={value} showLabels className={classes.root}>
      <BottomNavigationAction
        label="Artikel"
        icon={<FiberNewIcon />}
        onClick={() => history.push("/artikel")}
      />
      <BottomNavigationAction
        label="Perkuliahan"
        icon={<CollectionsBookmarkIcon />}
        onClick={() => history.push("/perkuliahan")}
      />
      {auth.role !== "guest" && (
        <BottomNavigationAction
          label="Profile"
          icon={<AccountCircleIcon />}
          onClick={() => history.push("/profile")}
        />
      )}
    </BottomNavigation>
  );
};

export default React.memo(BottomNavigationMobile);
