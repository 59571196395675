/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography, Grid, Button } from "@material-ui/core";

import {
  ListItem,
  DialogCheckoutFinish,
  DialogCheckout,
  DialogCheckoutAll,
  DialogDelete,
} from "./component";
import { Heading, ListPerkuliahan } from "../Components";

import {
  deleteKeranjang,
  getShowRekomendasi,
  checkoutKeranjang,
} from "../../../store/actions/mahasiswa";

const useStyles = makeStyles((theme) => ({
  itemContainer: {
    marginTop: theme.spacing(5),
  },
  textContainer: {
    marginTop: theme.spacing(10),
  },
  textTitle: {
    fontWeight: "700",
    lineHeight: "24px",
  },
  textLink: {
    fontWeight: "700",
    lineHeight: "24px",
    color: "#0068d3",
    cursor: "pointer",
  },
  textSubtitle: {
    fontWeight: "500",
    lineHeight: "24px",
  },
  checkoutAll: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: theme.spacing(3),
  },
}));

const Keranjang = ({
  keranjang,
  showRekomendasi,
  deleteKeranjang,
  getShowRekomendasi,
  checkoutKeranjang,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [dialogFinish, setDialogFinish] = useState(false);
  const [dialogCheckout, setDialogCheckout] = useState({
    open: false,
    data: null,
  });
  const [dialogCheckoutAll, setDialogCheckoutAll] = useState({
    open: false,
    data: null,
  });
  const [dialogDelete, setDialogDelete] = useState({ open: false, id: null });

  const openDialogFinish = () => {
    setDialogFinish(true);
  };

  const handleCheckoutAll = () => {
    const data = {
      token_sia: localStorage.getItem("token_sia"),
      checkout: keranjang.data.map((value) => ({
        class_uuid: value.class_uuid,
      })),
    };
    setDialogCheckoutAll({ open: true, data: data });
  };

  useEffect(() => {
    getShowRekomendasi();
  }, []);

  return (
    <div>
      <Heading text="Keranjang" />
      <Container maxWidth="lg">
        <div className={classes.itemContainer}>
          <ListItem
            list={keranjang}
            setDialogCheckout={setDialogCheckout}
            setDialogDelete={setDialogDelete}
          />
        </div>
        {keranjang.data.length > 0 && (
          <div className={classes.checkoutAll}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCheckoutAll}
            >
              Checkout All
            </Button>
          </div>
        )}
        <div className={classes.textContainer}>
          <Typography variant="h4" className={classes.textTitle}>
            Rekomendasi Perkuliahan
          </Typography>
          <Grid container justify="space-between">
            <Typography variant="subtitle2" className={classes.textSubtitle}>
              Rekomendasi berdasarkan semester
            </Typography>
            <Typography
              variant="body1"
              className={classes.textLink}
              onClick={() => history.push("/perkuliahan")}
            >
              Lihat Semua
            </Typography>
          </Grid>
        </div>
        <div>
          <ListPerkuliahan list={showRekomendasi} lg={2} md={3} sm={4} xs={6} />
        </div>
      </Container>
      <DialogCheckoutFinish
        open={dialogFinish}
        onClose={() => setDialogFinish(false)}
      />
      <DialogCheckout
        dialog={dialogCheckout}
        onClose={() => setDialogCheckout({ open: false, data: null })}
        action={checkoutKeranjang}
        openDialogFinish={openDialogFinish}
      />
      <DialogCheckoutAll
        dialog={dialogCheckoutAll}
        onClose={() => setDialogCheckoutAll({ open: false, data: null })}
        action={checkoutKeranjang}
        openDialogFinish={openDialogFinish}
      />
      <DialogDelete
        dialog={dialogDelete}
        onClose={() => setDialogDelete({ open: false, id: null })}
        action={deleteKeranjang}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  keranjang: state.keranjang,
  showRekomendasi: state.showRekomendasi,
});

export default connect(mapStateToProps, {
  deleteKeranjang,
  getShowRekomendasi,
  checkoutKeranjang,
})(Keranjang);
