/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  Button,
  Typography,
  IconButton,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(1),
  },
  actions: {
    justifyContent: "end",
  },
}));

const validationSchema = yup.object().shape({
  aptisi_code: yup.string().required("Code Aptisi harus diisi"),
  university_name: yup.string().required("Universitas harus diisi"),
  faculty_name: yup.string().required("Fakultas harus diisi"),
  major_name: yup.string().required("Program Studi harus diisi"),
  name: yup.string().required("Nama Lengkap harus diisi"),
  username: yup.string().required("NIM harus diisi"),
  password: yup.string().required("Password harus diisi"),
});

const DialogCreateUser = ({ dialog, onClose, action }) => {
  const classes = useStyles();
  const [isSubmit, setIsSubmit] = useState(false);

  const { register, errors, handleSubmit, setValue } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    setIsSubmit(true);
    const formData = {
      name: data.name,
      username: data.username,
      password: data.password,
      role: "aptisi",
      photo: "",
      profile: {
        aptisi_code: data.aptisi_code,
        university_name: data.university_name,
        faculty_name: data.faculty_name,
        major_name: data.major_name,
      },
    };
    action(formData);
    setIsSubmit(false);
    onClose();
  };

  useEffect(() => {
    setValue("role", "aptisi");
  }, []);

  return (
    <Dialog open={dialog.open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle onClose={onClose}>Form Tambah User Aptisi</DialogTitle>
        <DialogContent dividers>
          <TextField
            fullWidth
            type="number"
            name="aptisi_code"
            label="Code Aptisi"
            inputRef={register}
            error={!!errors.aptisi_code}
            helperText={errors.aptisi_code?.message}
          />
          <TextField
            fullWidth
            className={classes.field}
            name="university_name"
            label="Universitas"
            inputRef={register}
            error={!!errors.university_name}
            helperText={errors.university_name?.message}
          />
          <TextField
            fullWidth
            className={classes.field}
            name="faculty_name"
            label="Fakultas"
            inputRef={register}
            error={!!errors.faculty_name}
            helperText={errors.faculty_name?.message}
          />
          <TextField
            fullWidth
            className={classes.field}
            name="major_name"
            label="Program Studi"
            inputRef={register}
            error={!!errors.major_name}
            helperText={errors.major_name?.message}
          />
          <TextField
            fullWidth
            className={classes.field}
            name="name"
            label="Nama Lengkap"
            inputRef={register}
            error={!!errors.name}
            helperText={errors.name?.message}
          />
          <TextField
            fullWidth
            className={classes.field}
            name="username"
            label="Username"
            inputRef={register}
            error={!!errors.username}
            helperText={errors.username?.message}
          />
          <TextField
            fullWidth
            className={classes.field}
            type="password"
            name="password"
            label="Password"
            inputRef={register}
            error={!!errors.password}
            helperText={errors.password?.message}
          />
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button variant="contained" color="inherit" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={isSubmit}
            variant="contained"
            color="primary"
            type="submit"
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogCreateUser;
