/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  Button,
  Typography,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DialogDeleteUniversitas = ({ dialog, onClose, action }) => {
  const handleAction = () => {
    action(dialog.id);
    onClose();
  };

  return (
    <Dialog open={dialog.open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle onClose={onClose}>Confirm Delete User</DialogTitle>
      <DialogContent dividers>
        <Typography valiant="body1" align="center">
          Apakah anda yakin ingin menghapus Universitas ini ?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleAction}>
          delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDeleteUniversitas;
