import {
  SET_PRODI_PENYEDIA_START,
  SET_PRODI_PENYEDIA_SUCCESS,
  SET_PRODI_PENYEDIA_FAIL,
} from "../../actions/prodi/types";
import { updateObject } from "../../../utils";

const initialState = {
  loading: false,
  data: [],
  error: false,
};

const prodiPenyediaStart = (state, action) => {
  return updateObject(state, {
    ...state,
    loading: true,
    error: false,
  });
};

const prodiPenyediaSuccess = (state, action) => {
  return updateObject(state, {
    data: action.payload,
    current_page: action.current_page,
    has_previous_page: action.has_previous_page,
    has_next_page: action.has_next_page,
    previous_page: action.previous_page,
    next_page: action.next_page,
    last_page: action.last_page,
    total_item: action.total_item,
    loading: false,
    error: false,
  });
};

const prodiPenyediaFail = (state, action) => {
  return updateObject(state, {
    ...state,
    loading: false,
    error: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODI_PENYEDIA_START:
      return prodiPenyediaStart(state, action);
    case SET_PRODI_PENYEDIA_SUCCESS:
      return prodiPenyediaSuccess(state, action);
    case SET_PRODI_PENYEDIA_FAIL:
      return prodiPenyediaFail(state, action);
    default:
      return state;
  }
};

export default reducer;
