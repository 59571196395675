import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "ID",
  },
  {
    id: "perguruan-tinggi",
    numeric: false,
    disablePadding: false,
    label: "Perguruan Tinggi",
  },
  {
    id: "fakultas",
    numeric: false,
    disablePadding: false,
    label: "Fakultas",
  },
  {
    id: "prodi",
    numeric: false,
    disablePadding: false,
    label: "Prodi",
  },
  {
    id: "nim",
    numeric: false,
    disablePadding: false,
    label: "NIM",
  },
  {
    id: "nama-mahasiswa",
    numeric: false,
    disablePadding: false,
    label: "Nama Mahasiswa",
  },
  {
    id: "semester",
    numeric: false,
    disablePadding: false,
    label: "Semester",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
];

const EnhancedTableHead = (props) => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
