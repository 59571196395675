import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Card, CardContent, Container } from "@material-ui/core";
import parse from "html-react-parser";
import AOS from "aos";
import "aos/dist/aos.css";

// Component
import { HeaderArtikel } from "./component";
import { ListArtikel } from "../Components";
import { isEmpty } from "../../../utils";

// Actions
import {
  getOneShowArtikel,
  getShowArtikel,
} from "../../../store/actions/mahasiswa";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
    },
  },
  containerTextDesc: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
    },
  },
  containerNewArtikel: {
    marginTop: theme.spacing(5)
  },
  textDesc: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
}));

const ArtikelDetail = ({ showArtikel, getOneShowArtikel, getShowArtikel }) => {
  const classes = useStyles();
  const params = useParams();
  AOS.init({
    // duration: 1000
  });

  useEffect(() => {
    getShowArtikel("", 3);
  }, [getShowArtikel]);

  useEffect(() => {
    getOneShowArtikel(params.slug);
  }, [getOneShowArtikel, params.slug]);

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <div data-aos="fade-up" data-aos-duration="800">
          <HeaderArtikel data={showArtikel.one} />
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="1500"
          className={classes.containerTextDesc}
        >
          <Typography variant="h4" className={classes.textDesc}>
            Deskripsi
          </Typography>
        </div>
        <Card data-aos="fade-up" data-aos-duration="1500">
          <CardContent>
            {!isEmpty(showArtikel.one.data.body) &&
              parse(showArtikel.one.data.body)}
          </CardContent>
        </Card>
        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          className={classes.containerNewArtikel}
        >
          <Typography variant="h4" className={classes.textDesc}>
            Artikel Terbaru
          </Typography>
          <ListArtikel data={showArtikel.all} />
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  showArtikel: state.showArtikel,
});

export default connect(mapStateToProps, { getOneShowArtikel, getShowArtikel })(
  ArtikelDetail
);
