import React, { forwardRef } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Drawer,
  Button,
  List,
  ListItem,
  colors,
  ListItemText,
} from "@material-ui/core";
import { connect } from "react-redux";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import BallotIcon from "@material-ui/icons/Ballot";
import AssessmentIcon from "@material-ui/icons/Assessment";
import GroupIcon from "@material-ui/icons/Group";
import SyncIcon from "@material-ui/icons/Sync";

import clsx from "clsx";

const textMenuBlack = "#000";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  icon: {
    color: "#000",
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  activeIcon: {
    color: theme.palette.primary.main,
  },
  button: {
    color: colors.blueGrey[800],
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
  },
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  menus: {
    paddingTop: theme.spacing(3),
  },
  textMenu: {
    color: textMenuBlack,
    paddingLeft: theme.spacing(2),
  },
  activeTextMenu: {
    color: theme.palette.primary.main,
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const MobileDrawer = (props) => {
  const { open, onClose, userData } = props;

  const classes = useStyles();
  const location = useLocation();

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <div className={classes.fullList}>
        <List className={classes.menus}>
          <ListItem key="dashboard" button className={classes.item}>
            <Button
              className={classes.button}
              component={CustomRouterLink}
              to="/admin"
            >
              <div
                className={clsx(classes.icon, {
                  [classes.activeIcon]: location.pathname === "/admin",
                })}
              >
                <DashboardIcon />
              </div>
              <ListItemText
                className={clsx(classes.textMenu, {
                  [classes.activeTextMenu]: location.pathname === "/admin",
                })}
                primary="Dashboard"
                disableTypography={true}
              />
            </Button>
          </ListItem>

          <ListItem key="manage-banner" button className={classes.item}>
            <Button
              className={classes.button}
              component={CustomRouterLink}
              to="/admin/manage-banner"
            >
              <div
                className={clsx(classes.icon, {
                  [classes.activeIcon]:
                    location.pathname === "/admin/manage-banner",
                })}
              >
                <ViewCarouselIcon />
              </div>
              <ListItemText
                className={clsx(classes.textMenu, {
                  [classes.activeTextMenu]:
                    location.pathname === "/admin/manage-banner",
                })}
                primary="Manage Banner"
                disableTypography={true}
              />
            </Button>
          </ListItem>
          <ListItem key="manage-artikel" button className={classes.item}>
            <Button
              className={classes.button}
              component={CustomRouterLink}
              to="/admin/manage-artikel"
            >
              <div
                className={clsx(classes.icon, {
                  [classes.activeIcon]:
                    location.pathname === "/admin/manage-artikel",
                })}
              >
                <SpeakerNotesIcon />
              </div>
              <ListItemText
                className={clsx(classes.textMenu, {
                  [classes.activeTextMenu]:
                    location.pathname === "/admin/manage-artikel",
                })}
                primary="Manage Artikel"
                disableTypography={true}
              />
            </Button>
          </ListItem>
          <ListItem key="manage-user" button className={classes.item}>
            <Button
              className={classes.button}
              component={CustomRouterLink}
              to="/admin/manage-user"
            >
              <div
                className={clsx(classes.icon, {
                  [classes.activeIcon]:
                    location.pathname === "/admin/manage-user",
                })}
              >
                <GroupIcon />
              </div>
              <ListItemText
                className={clsx(classes.textMenu, {
                  [classes.activeTextMenu]:
                    location.pathname === "/admin/manage-user",
                })}
                primary="Manage User"
                disableTypography={true}
              />
            </Button>
          </ListItem>
          <ListItem key="detail-mata-kuliah" button className={classes.item}>
            <Button
              className={classes.button}
              component={CustomRouterLink}
              to="/admin/detail-mata-kuliah"
            >
              <div
                className={clsx(classes.icon, {
                  [classes.activeIcon]:
                    location.pathname === "/admin/detail-mata-kuliah",
                })}
              >
                <BallotIcon />
              </div>
              <ListItemText
                className={clsx(classes.textMenu, {
                  [classes.activeTextMenu]:
                    location.pathname === "/admin/detail-mata-kuliah",
                })}
                primary="Detail Mata Kuliah"
                disableTypography={true}
              />
            </Button>
          </ListItem>
          <ListItem key="laporanDataTransaksi" button className={classes.item}>
            <Button
              className={classes.button}
              component={CustomRouterLink}
              to="/admin/laporan-data-transaksi"
            >
              <div
                className={clsx(classes.icon, {
                  [classes.activeIcon]:
                    location.pathname === "/admin/laporan-data-transaksi",
                })}
              >
                <AssessmentIcon />
              </div>
              <ListItemText
                className={clsx(classes.textMenu, {
                  [classes.activeTextMenu]:
                    location.pathname === "/admin/laporan-data-transaksi",
                })}
                primary="Laporan Data Transaksi"
                disableTypography={true}
              />
            </Button>
          </ListItem>
          <ListItem key="sync" button className={classes.item}>
            <Button
              className={classes.button}
              component={CustomRouterLink}
              to="/admin/sync"
            >
              <div
                className={clsx(classes.icon, {
                  [classes.activeIcon]: location.pathname === "/admin/sync",
                })}
              >
                <SyncIcon />
              </div>
              <ListItemText
                className={clsx(classes.textMenu, {
                  [classes.activeTextMenu]: location.pathname === "/admin/sync",
                })}
                primary="Sinkronisasi"
                disableTypography={true}
              />
            </Button>
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.auth,
  };
};

export default connect(mapStateToProps)(MobileDrawer);
