import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Divider, Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "none",
    marginTop: theme.spacing(2),
  },
  containerText: {
    paddingLeft: theme.spacing(2),
  },
  loadText: {
    marginTop: theme.spacing(0.5),
  },
  divider: {
    marginTop: theme.spacing(0.5),
  },
}));

const LoadKeranjang = () => {
  const classes = useStyles();

  return (
    <div>
      <Card className={classes.card}>
        <Grid container>
          <Grid item xs={5} sm={2}>
            <Skeleton variant="rect" height={100} />
          </Grid>
          <Grid item xs={7} sm={10} className={classes.containerText}>
            <Skeleton variant="text" className={classes.loadText} />
            <Skeleton variant="text" className={classes.loadText} />
            <Skeleton variant="text" className={classes.loadText} />
            <Skeleton variant="text" className={classes.loadText} />
          </Grid>
        </Grid>
      </Card>
      <Divider className={classes.divider} />
    </div>
  );
};

export default React.memo(LoadKeranjang);
