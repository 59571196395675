import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 150,
    backgroundColor: "#888888",
  },
  textColor: {
    color: theme.palette.white,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.white,
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.root}
      justify="center"
      alignItems="center"
    >
      <Grid
        item
        xs={12}
        // onMouseEnter={() => console.log("true")}
        // onMouseLeave={() => console.log("false")}
      >
        <Typography variant="h5" align="center" className={classes.textColor}>
          &copy;{" "}
          <Link component="a" className={classes.link}>
            Berkah Mandiri Belajar
          </Link>
          . {new Date().getFullYear()}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
