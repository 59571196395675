import {
  SET_UNIVERSITAS,
  SET_LOADING_DONE,
  SET_LOADING,
} from "../../actions/admin/actionTypes";
import { updateObject } from "../../../utils";

const initialState = {
  data: [],
  loading: true,
};

const setLoading = (state, action) => {
  return updateObject(state, { loading: true });
};

const setLoadingDone = (state, action) => {
  return updateObject(state, { loading: false });
};

const setUniversitas = (state, action) => {
  return updateObject(state, {
    data: action.payload,
    loading: false,
    current_page: action.current_page,
    has_previous_page: action.has_previous_page,
    has_next_page: action.has_next_page,
    previous_page: action.previous_page,
    next_page: action.next_page,
    last_page: action.last_page,
    total_item: action.total_item,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_UNIVERSITAS:
      return setUniversitas(state, action);
    case SET_LOADING:
      return setLoading(state, action);
    case SET_LOADING_DONE:
      return setLoadingDone(state, action);
    default:
      return state;
  }
};

export default reducer;
