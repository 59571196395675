/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography, Grid } from "@material-ui/core";

import { ListItem } from "./component";
import { Heading, ListPerkuliahan } from "../Components";

import {
  getNotifikasi,
  getShowRekomendasi,
} from "../../../store/actions/mahasiswa";

const useStyles = makeStyles((theme) => ({
  itemContainer: {
    marginTop: theme.spacing(5),
  },
  textContainer: {
    marginTop: theme.spacing(10),
  },
  textTitle: {
    fontWeight: "700",
    lineHeight: "24px",
  },
  textSubtitle: {
    fontWeight: "500",
    lineHeight: "24px",
  },
  checkoutAll: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: theme.spacing(3),
  },
}));

const Notifikasi = ({
  showRekomendasi,
  getShowRekomendasi,
  getNotifikasi,
  notifikasi,
}) => {
  const classes = useStyles();

  useEffect(() => {
    getShowRekomendasi();
    getNotifikasi();
  }, []);

  return (
    <div>
      <Heading text="Notifikasi" />
      <Container maxWidth="lg">
        <div className={classes.itemContainer}>
          <ListItem list={notifikasi} />
        </div>
        <div className={classes.textContainer}>
          <Typography variant="h4" className={classes.textTitle}>
            Rekomendasi Perkuliahan
          </Typography>
          <Grid container justify="space-between">
            <Typography variant="subtitle2" className={classes.textSubtitle}>
              Rekomendasi berdasarkan semester
            </Typography>
            <Typography variant="body1" className={classes.textTitle}>
              Lihat Semua
            </Typography>
          </Grid>
        </div>
        <div>
          <ListPerkuliahan list={showRekomendasi} lg={2} md={3} sm={4} xs={6} />
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  notifikasi: state.notifikasi,
  showRekomendasi: state.showRekomendasi,
});

export default connect(mapStateToProps, {
  getNotifikasi,
  getShowRekomendasi,
})(Notifikasi);
