import * as actionTypes from "../../actions/admin/actionTypes";
import { updateObject } from "../../../utils";

const initialState = {
  loading: true,
  loadingEdit: true,
  data: [],
  dataEdit: null,
  categories: [],
};

const setLoading = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const setLoadingDone = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

const setArticle = (state, action) => {
  return updateObject(state, {
    loading: false,
    data: action.payload,
    current_page: action.current_page,
    has_previous_page: action.has_previous_page,
    has_next_page: action.has_next_page,
    previous_page: action.previous_page,
    next_page: action.next_page,
    last_page: action.last_page,
    total_item: action.total_item,
  });
};

const setArticleOne = (state, action) => {
  return updateObject(state, {
    loadingEdit: false,
    dataEdit: action.payload,
  });
};

const setArticleCategories = (state, action) => {
  return updateObject(state, {
    loading: false,
    categories: action.payload,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LOADING:
      return setLoading(state, action);
    case actionTypes.SET_LOADING_DONE:
      return setLoadingDone(state, action);
    case actionTypes.SET_ARTICLE:
      return setArticle(state, action);
    case actionTypes.SET_ARTICLE_ONE:
      return setArticleOne(state, action);
    case actionTypes.SET_ARTICLE_CATEGORIES:
      return setArticleCategories(state, action);
    default:
      return state;
  }
};

export default reducer;
