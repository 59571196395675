/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Container } from "@material-ui/core";
import AOS from "aos";
import "aos/dist/aos.css";
import InfiniteScroll from "react-infinite-scroll-component";

import { Carousel, ListArtikel } from "../Components";

// Action
import {
  getShowArtikel,
  getShowBanner,
} from "../../../store/actions/mahasiswa";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
    },
  },
  textContainer: {
    marginTop: theme.spacing(5),
  },
  textTitle: {
    fontWeight: "700",
    lineHeight: "24px",
  },
  textMargin: {
    marginTop: theme.spacing(5),
  },
}));

const Artikel = ({
  getShowArtikel,
  showArtikel,
  showBanner,
  getShowBanner,
}) => {
  const limit_artikel = 3;
  const classes = useStyles();
  AOS.init({
    // duration: 1000
  });

  const getMoreData = () => {
    getShowArtikel(showArtikel.all.last_id, limit_artikel);
  };

  useEffect(() => {
    if (showArtikel.all.data.length === 0) {
      getShowArtikel("", limit_artikel);
    }
    if (showBanner.data.length === 0) {
      getShowBanner();
    }
  }, []);

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <div data-aos="fade-up" data-aos-duration="500">
          <Carousel data={showBanner.data} />
        </div>
        <div
          className={classes.textContainer}
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <Typography variant="h4" className={classes.textTitle}>
            Artikel Terbaru
          </Typography>
        </div>
        <InfiniteScroll
          style={{ overflow: "none" }}
          dataLength={showArtikel.all.data.length}
          hasMore={showArtikel.all.hasMore}
          next={getMoreData}
        >
          <ListArtikel data={showArtikel.all} />
        </InfiniteScroll>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  showArtikel: state.showArtikel,
  showBanner: state.showBanner,
});

export default connect(mapStateToProps, { getShowArtikel, getShowBanner })(
  React.memo(Artikel)
);
