/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  Button,
  Typography,
  IconButton,
  Box,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { connect } from "react-redux";
import axios from "../../../../../api/axios-orders";

import ImageUploader from "react-images-upload";
import { setAlert } from "../../../../../store/actions";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(1),
  },
  actions: {
    justifyContent: "end",
  },
}));

const validationSchema = yup.object().shape({
  name: yup.string().required("Wajib Diisi"),
  code: yup.string().required("Wajib Diisi"),
  login: yup.string().required("Wajib Diisi"),
  profile_student: yup.string().required("Wajib Diisi"),
  profile_major: yup.string().required("Wajib Diisi"),
  auth_global: yup.string().required("Wajib Diisi"),
  faculty: yup.string().required("Wajib Diisi"),
  major: yup.string().required("Wajib Diisi"),
  class: yup.string().required("Wajib Diisi"),
  course: yup.string().required("Wajib Diisi"),
  arrangement_schedules: yup.string().required("Wajib Diisi"),
  cooperation_university: yup.string().required("Wajib Diisi"),
  cooperation_major: yup.string().required("Wajib Diisi"),
  client_id: yup.string().required("Wajib Diisi"),
  client_secret: yup.string().required("Wajib Diisi"),
  class_approved_from: yup.string().required("Wajib Diisi"),
  class_approved_to: yup.string().required("Wajib Diisi"),
  university_sks_limit: yup.string().required("Wajib Diisi"),
  university_profile: yup.string().required("Wajib Diisi"),
});

const DialogUpdateUniversitas = ({ dialog, onClose, action, setAlert }) => {
  const classes = useStyles();

  const [image, setImage] = useState([]);
  const [defaultLogo, setDefaultLogo] = useState(true);
  const [logo, setLogo] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);

  const onDrop = (picture) => {
    setImage(picture);
    setDefaultLogo(false);
  };

  const { register, errors, handleSubmit, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
  });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    setIsSubmit(true);
    if (image.length === 0) {
      const formData = {
        ...data,
        logo: dialog.data.logo,
        change_thumbnail: false,
      };
      action(formData, dialog.data.id);
      setDefaultLogo(true);
      setIsSubmit(false);
      onClose();
    } else {
      const dataImage = new FormData();
      dataImage.append("file", image[0]);
      dataImage.append("dir", "logo");
      try {
        const res = await axios.post(`/admin/file/upload `, dataImage, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        const formData = {
          ...data,
          logo: res.data.data,
          change_thumbnail: true,
        };
        action(formData, dialog.data.id);
        setDefaultLogo(true);
        setIsSubmit(false);
        onClose();
      } catch (error) {
        setAlert("Logo Gagal Di Upload", "error");
      }
    }
  };

  useEffect(() => {
    if (dialog.data !== null) {
      setValue("code", dialog.data.code);
      setValue("name", dialog.data.name);
      setValue("login", dialog.data.login);
      setValue("profile_student", dialog.data.profile_student);
      setValue("profile_major", dialog.data.profile_major);
      setValue("auth_global", dialog.data.auth_global);
      setValue("faculty", dialog.data.faculty);
      setValue("major", dialog.data.major);
      setValue("class", dialog.data.class);
      setValue("course", dialog.data.course);
      setValue("arrangement_schedules", dialog.data.arrangement_schedules);
      setValue("university_sks_limit", dialog.data.university_sks_limit);
      setValue("university_profile", dialog.data.university_profile);
      setValue("cooperation_university", dialog.data.cooperation_university);
      setValue("cooperation_major", dialog.data.cooperation_major);
      setValue("client_id", dialog.data.client_id);
      setValue("client_secret", dialog.data.client_secret);
      setValue("class_approved_from", dialog.data.class_approved_from);
      setValue("class_approved_to", dialog.data.class_approved_to);
      setLogo(dialog.data.logo);
    }
  }, [dialog.data]);

  return (
    <Dialog open={dialog.open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle onClose={onClose}>Form Update User Aptisi</DialogTitle>
        <DialogContent dividers>
          <ImageUploader
            withIcon={true}
            buttonText="Upload Logo"
            onChange={onDrop}
            imgExtension={[".jpg", ".jpeg", ".png"]}
            maxFileSize={2242880}
            withPreview={true}
            label="Max file size : 2 mb, Format : jpeg, png, jpg"
            accept="image/jpg,image/jpeg,image/png"
            singleImage={true}
            name="logo"
          />
          {defaultLogo && (
            <Box display="flex" justifyContent="center">
              <img src={logo} width="auto" height="150" alt="" />
            </Box>
          )}
          <TextField
            fullWidth
            className={classes.field}
            name="name"
            label="Nama Universitas"
            inputRef={register}
            error={!!errors.name}
            helperText={errors.name?.message}
          />

          <TextField
            fullWidth
            className={classes.field}
            name="code"
            label="Kode Universitas"
            inputRef={register}
            error={!!errors.code}
            helperText={errors.code?.message}
          />

          <TextField
            fullWidth
            className={classes.field}
            name="login"
            label="Login Universitas"
            inputRef={register}
            error={!!errors.login}
            helperText={errors.login?.message}
          />
          <TextField
            fullWidth
            className={classes.field}
            name="profile_student"
            label="Profile Student"
            inputRef={register}
            error={!!errors.profile_student}
            helperText={errors.profile_student?.message}
          />
          <TextField
            fullWidth
            className={classes.field}
            name="profile_major"
            label="Profile Major"
            inputRef={register}
            error={!!errors.profile_major}
            helperText={errors.profile_major?.message}
          />
          <TextField
            fullWidth
            className={classes.field}
            name="auth_global"
            label="Auth Global"
            inputRef={register}
            error={!!errors.auth_global}
            helperText={errors.auth_global?.message}
          />
          <TextField
            fullWidth
            className={classes.field}
            name="faculty"
            label="Fakultas"
            inputRef={register}
            error={!!errors.faculty}
            helperText={errors.faculty?.message}
          />
          <TextField
            fullWidth
            className={classes.field}
            name="major"
            label="Program Studi / Major"
            inputRef={register}
            error={!!errors.major}
            helperText={errors.major?.message}
          />
          <TextField
            fullWidth
            className={classes.field}
            name="class"
            label="Class"
            inputRef={register}
            error={!!errors.class}
            helperText={errors.class?.message}
          />
          <TextField
            fullWidth
            className={classes.field}
            name="course"
            label="Course"
            inputRef={register}
            error={!!errors.course}
            helperText={errors.course?.message}
          />
          <TextField
            fullWidth
            className={classes.field}
            name="arrangement_schedules"
            label="Arrangement Schedules"
            inputRef={register}
            error={!!errors.arrangement_schedules}
            helperText={errors.arrangement_schedules?.message}
          />
          <TextField
            fullWidth
            className={classes.field}
            name="university_sks_limit"
            label="University SKS Limit"
            inputRef={register}
            error={!!errors.university_sks_limit}
            helperText={errors.university_sks_limit?.message}
          />
          <TextField
            fullWidth
            className={classes.field}
            name="university_profile"
            label="University Profile"
            inputRef={register}
            error={!!errors.university_profile}
            helperText={errors.university_profile?.message}
          />
          <TextField
            fullWidth
            className={classes.field}
            name="cooperation_university"
            label="Cooperation University"
            inputRef={register}
            error={!!errors.cooperation_university}
            helperText={errors.cooperation_university?.message}
          />
          <TextField
            fullWidth
            className={classes.field}
            name="cooperation_major"
            label="Cooperation Major"
            inputRef={register}
            error={!!errors.cooperation_major}
            helperText={errors.cooperation_major?.message}
          />
          <TextField
            fullWidth
            className={classes.field}
            name="client_id"
            label="Client ID"
            inputRef={register}
            error={!!errors.client_id}
            helperText={errors.client_id?.message}
          />
          <TextField
            fullWidth
            className={classes.field}
            name="client_secret"
            label="Client Secret"
            inputRef={register}
            error={!!errors.client_secret}
            helperText={errors.client_secret?.message}
          />
          <TextField
            fullWidth
            className={classes.field}
            name="class_approved_from"
            label="Class Approved From"
            inputRef={register}
            error={!!errors.class_approved_from}
            helperText={errors.class_approved_from?.message}
          />
          <TextField
            fullWidth
            className={classes.field}
            name="class_approved_to"
            label="Class Approved To"
            inputRef={register}
            error={!!errors.class_approved_to}
            helperText={errors.class_approved_to?.message}
          />
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button variant="contained" color="inherit" onClick={onClose}>
            Cancel
          </Button>
          {!isSubmit ? (
            <Button variant="contained" color="primary" type="submit">
              Update
            </Button>
          ) : (
            <Button variant="contained" color="primary" disabled>
              Update
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default connect(null, { setAlert })(DialogUpdateUniversitas);
