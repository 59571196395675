import {
  SET_PROFILE_PRODI,
  PROFILE_PRODI_START,
  PROFILE_PRODI_FAIL,
} from "./types";
import axios from "../../../api/axios-orders";
import { setAlert } from "../alert";

export const getProfileProdi = () => async (dispatch) => {
  dispatch({
    type: PROFILE_PRODI_START,
  });
  try {
    const response = await axios.get("/major/profile/major", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({
      type: SET_PROFILE_PRODI,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({
      type: PROFILE_PRODI_FAIL,
      payload: error.response.data.message,
    });
    dispatch(setAlert("Can't get data profile", "error"));
  }
};
