import {
  PROFILE_START,
  PROFILE_FAIL,
  SET_PROFILE,
} from "../../actions/mahasiswa/types";
import { updateObject } from "../../../utils";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const profileStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const setProfile = (state, action) => {
  return updateObject(state, { data: action.payload, loading: false });
};

const profileFail = (state, action) => {
  return updateObject(state, { error: action.payload, loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_START:
      return profileStart(state, action);
    case SET_PROFILE:
      return setProfile(state, action);
    case PROFILE_FAIL:
      return profileFail(state, action);
    default:
      return state;
  }
};

export default reducer;
