import {
  PERKULIAHAN_START,
  PERKULIAHAN_SUCCESS,
  PERKULIAHAN_FAIL,
  SET_SEARCH_NAME_PERKULIAHAN,
} from "../../actions/mahasiswa/types";
import { updateObject } from "../../../utils";

const initialState = {
  data: [],
  loading: false,
  error: null,
  search: "",
  last_id: "",
  hasMore: false,
};

const perkuliahanStart = (state, action) => {
  return updateObject(state, { loading: true, data: [] });
};

const perkuliahanSuccess = (state, action) => {
  return updateObject(state, { data: action.payload, loading: false });
};

const perkuliahanFail = (state, action) => {
  return updateObject(state, {
    error: action.payload,
    loading: false,
    data: [],
  });
};

const setSearch = (state, action) => {
  return updateObject(state, { search: action.payload });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PERKULIAHAN_START:
      return perkuliahanStart(state, action);
    case PERKULIAHAN_SUCCESS:
      return perkuliahanSuccess(state, action);
    case PERKULIAHAN_FAIL:
      return perkuliahanFail(state, action);
    case SET_SEARCH_NAME_PERKULIAHAN:
      return setSearch(state, action);
    default:
      return state;
  }
};

export default reducer;
