import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Typography,
  Checkbox,
  Divider,
  Grid,
} from "@material-ui/core";

import { LoadText } from "../../../../../components/Skeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  title: {
    fontWeight: "bold",
    color: "#4FD989"
  },
  check: {
    width: "5px",
    height: "5px",
  },
  listFilter: {
    marginTop: theme.spacing(3),
  },
  textFilter: {
    marginTop: theme.spacing(1.1),
  },
  checkContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  load: {
    marginTop: theme.spacing(1.5),
  },
}));

const FilterProdi = ({ title, listFilter, setSelected, selected }) => {
  const classes = useStyles();

  const handleSetState = (event, index) => {
    if (event.target.checked) {
      return setSelected((prev) => ({
        ...prev,
        prodi: listFilter.data[index].major_uuid,
      }));
    }
    return setSelected((prev) => ({ ...prev, prodi: "" }));
  };

  const isSelected = (id) => {
    if (selected.prodi !== "") {
      return selected.prodi === id;
    }
    return false;
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} variant="h5">
          {title}
        </Typography>
        <Divider />
        <div className={classes.listFilter}>
          {listFilter.data.map((data, index) => {
            const isItemSelected = isSelected(data.major_uuid);

            return (
              <Grid
                className={classes.textFilter}
                container
                justify="space-between"
                key={index}
              >
                <Grid item xs={10}>
                  <Typography noWrap>{data.major_name}</Typography>
                </Grid>
                <Grid item xs={2} className={classes.checkContainer}>
                  <Checkbox
                    className={classes.check}
                    color="primary"
                    checked={isItemSelected}
                    onChange={(e) => handleSetState(e, index)}
                  />
                </Grid>
              </Grid>
            );
          })}
          {listFilter.loading &&
            Array(3)
              .fill("a")
              .map((_, index) => (
                <LoadText key={index} className={classes.load} />
              ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default FilterProdi;
