/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const useStyles = makeStyles((theme) => ({
  image: {
    height: "350px",
    width: "100%",
    objectFit: "cover",
    backgroundColor: "#fff",
  },
}));

const CarouselImage = ({ data }) => {
  const classes = useStyles();

  return (
    <Carousel showThumbs={false} infiniteLoop={true}>
      {data.map((value) => (
        <div key={value.id}>
          <img className={classes.image} src={value.path} />
        </div>
      ))}
    </Carousel>
  );
};

export default React.memo(CarouselImage);
