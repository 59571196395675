import {
  SET_PRINT_START,
  SET_PRINT_SUCCESS,
  SET_PRINT_FAIL,
} from "./actionTypes";
import axios from "../../api/axios-orders";
import { setAlert } from "./alert";
import fileDownload from "js-file-download";

export const printDetailMahasiswa = ({
  typeFile = "",
  class_uuid = "",
}) => async (dispatch) => {
  dispatch({
    type: SET_PRINT_START,
  });
  let contentType = "";
  if (typeFile === "excel") {
    contentType = "application/vnd.ms-excel";
  } else {
    contentType = "application/pdf";
  }
  try {
    const res = await axios.get(
      `major/export/${typeFile}/class/${class_uuid}/detail`,
      {
        headers: {
          "Content-Type": contentType,
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        responseType: "blob",
      }
    );
    if (typeFile === "excel") {
      fileDownload(res.data, "detail.xlsx");
    } else {
      fileDownload(res.data, "detail.pdf");
    }

    dispatch({
      type: SET_PRINT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: SET_PRINT_FAIL,
    });
    dispatch(setAlert("Export Failed", "error"));
  }
};
