/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Avatar } from "@material-ui/core";
import defaultAvatar from "../../../../../assets/img/default-avatar.png";

import { LoadText, LoadAvatar } from "../../../../../components/Skeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  imageContainer: {
    width: "80%",
  },
  image: {
    width: "200px",
    height: "200px",
    margin: "0 auto",
  },
  bio: {
    marginTop: theme.spacing(2),
    backgroundColor: "rgba(2, 99, 196, 0.5)",
    borderRadius: "10px",
    padding: theme.spacing(1, 2),
  },
  marginTop: {
    marginTop: theme.spacing(0.5),
  },
}));

const ImageProfile = ({ data }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        {data.loading ? (
          <LoadAvatar className={classes.image} />
        ) : (
          <Avatar
            className={classes.image}
            src={data?.photo === "" ? defaultAvatar : data.photo}
            alt="Photo Profile"
          />
        )}
      </div>
      <Grid container className={classes.bio}>
        <Grid item xs={6}>
          <Typography variant="body1">NIK</Typography>
          <Typography variant="body1" className={classes.marginTop}>
            Nama Lengkap
          </Typography>
          <Typography variant="body1" className={classes.marginTop}>
            Jenis Kelamin
          </Typography>
          <Typography variant="body1" className={classes.marginTop}>
            Tempat Lahir
          </Typography>
          <Typography variant="body1" className={classes.marginTop}>
            Tanggal Lahir
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {data.loading ? (
            Array(5)
              .fill("")
              .map((value, index) => (
                <div className={classes.marginTop} key={index}>
                  <LoadText />
                </div>
              ))
          ) : (
            <>
              <Typography variant="body1">
                {data.data?.username === "" ? "-" : data.data.username}
              </Typography>
              <Typography variant="body1" className={classes.marginTop}>
                {data.data?.name === "" ? "-" : data.data.name}
              </Typography>
              <Typography variant="body1" className={classes.marginTop}>
                {data.data?.gender === "" ? "-" : data.data.gender}
              </Typography>
              <Typography variant="body1" className={classes.marginTop}>
                {data.data?.born_at === ""
                  ? "-"
                  : data.data.born_at}
              </Typography>
              <Typography variant="body1" className={classes.marginTop}>
                {data.data?.born_on === ""
                  ? "-"
                  : data.data.born_on}
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ImageProfile;
