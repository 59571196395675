/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { fade, makeStyles } from "@material-ui/core/styles";
import {
  Slide,
  CssBaseline,
  AppBar,
  Toolbar,
  useScrollTrigger,
  InputBase,
  IconButton,
  Badge,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import HistoryIcon from "@material-ui/icons/History";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import {
  getKeranjang,
  getNotifikasi,
} from "../../../../../store/actions/mahasiswa";

const useStyles = makeStyles((theme) => ({
  navbarMobile: {
    backgroundColor: theme.palette.white,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.12)",
  },
  search: {
    borderRadius: theme.shape.borderRadius,
    border: "1px solid #efefef",
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  searchIcon: {
    padding: theme.spacing(0.3, 1, 0),
  },
  inputRoot: {
    color: theme.palette.black,
  },
  inputRootField: {
    color: theme.palette.black,
    width: "100%",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  grow: {
    flexGrow: 1,
  },
}));

const HiddenOnScroll = ({ children, window }) => {
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

const AppBarMobile = ({
  authLogout,
  notifikasi,
  keranjang,
  getKeranjang,
  getNotifikasi,
  setSearch,
  auth,
  ...rest
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [value, setValue] = useState("");

  const handleLogout = () => {
    authLogout();
    localStorage.removeItem("access_token");
    localStorage.removeItem("role");
    localStorage.removeItem("data");
    localStorage.clear();
    history.push("/login");
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      setSearch(value);
      history.push("/perkuliahan");
    }
  };

  useEffect(() => {
    if (value === "") {
      setSearch(value);
    }
  }, [value, setSearch]);

  useEffect(() => {
    if (auth.role !== "guest") {
      getKeranjang();
      getNotifikasi();
    }
  }, []);

  return (
    <>
      <CssBaseline />
      <HiddenOnScroll {...rest}>
        <AppBar className={classes.navbarMobile}>
          <Toolbar>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon className={classes.inputRoot} />
              </div>
              <InputBase
                placeholder="Pencarian...."
                classes={{
                  root: classes.inputRootField,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                onKeyDown={handleEnter}
              />
            </div>
            <div className={classes.grow} />
            <IconButton
              aria-label="show 4 new mails"
              onClick={() => history.push("/riwayat")}
            >
              <Badge color="secondary">
                <HistoryIcon />
              </Badge>
            </IconButton>
            <IconButton
              aria-label="show 4 new mails"
              onClick={() => history.push("/keranjang")}
            >
              <Badge badgeContent={keranjang.total} color="secondary">
                <ShoppingCartIcon />
              </Badge>
            </IconButton>
            <IconButton
              aria-label="show 17 new notifications"
              onClick={() => history.push("/notifikasi")}
            >
              <Badge badgeContent={notifikasi.total} color="secondary">
                <NotificationsActiveIcon />
              </Badge>
            </IconButton>
            <IconButton aria-label="logout" onClick={handleLogout}>
              <ExitToAppIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </HiddenOnScroll>
    </>
  );
};

const mapStateToProps = (state) => ({
  notifikasi: state.notifikasi,
  keranjang: state.keranjang,
  auth: state.auth,
});

export default connect(mapStateToProps, { getKeranjang, getNotifikasi })(
  React.memo(AppBarMobile)
);
