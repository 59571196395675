import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  TextField,
  InputAdornment,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

import { connect } from "react-redux";
import { getArticle } from "../../../store/actions";

import SearchIcon from "@material-ui/icons/Search";

// Utils
import { capitalize } from "../../../utils";

// Components
import { CustomRouterLink } from "../../../components";
import { DialogAlert } from "./component";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  loading: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },

  title: {
    fontWeight: 700,
    marginBottom: theme.spacing(5),
  },

  topContainer: {
    marginBottom: theme.spacing(10),
  },

  searchContainer: {
    [theme.breakpoints.up("sm")]: {
      textAlign: "end",
    },
  },

  imgCard: {
    height: "96px",
    width: "100%",
    borderRadius: 8,
    boxShadow: "0px 3px 7px rgba(0,0,0,0.5)",
    [theme.breakpoints.only("xs")]: {
      height: 130,
    },
  },

  img: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  judul: {
    fontWeight: "600",
  },

  btnDelete: {
    marginTop: theme.spacing(1),
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
  },

  cardList: {
    marginBottom: theme.spacing(2),
  },

  pagination: {
    padding: theme.spacing(0.5),
    "& .MuiPagination-ul": {
      justifyContent: "center",
    },
  },
}));

const ManageArtikel = ({ article, getArticle }) => {
  const classes = useStyles();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [openDialogAlert, setOpenDialogAlert] = useState({
    open: false,
    id: null,
  });

  const handlePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    getArticle(page, 10, search);
  }, [page, getArticle]);

  useEffect(() => {
    setPage(1);
    getArticle(page, 10, search);
  }, [search]);

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        Manage Artikel
      </Typography>
      <Grid className={classes.topContainer} container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Button
            component={CustomRouterLink}
            to="/admin/tambah-artikel"
            variant="contained"
            color="primary"
          >
            Buat Artikel Baru
          </Button>
        </Grid>
        <Grid className={classes.searchContainer} item xs={12} sm={6}>
          <TextField
            id="outlined-basic"
            label="Pencarian"
            size="small"
            variant="outlined"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      {article.loading ? (
        <Card className="loading" align="center">
          <CardContent>
            <CircularProgress />
          </CardContent>
        </Card>
      ) : article.data.length > 0 ? (
        <>
          {article.data.map((item, index) => (
            <Card className={classes.cardList} key={index}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={2} md={2} lg={2}>
                    <Box className={classes.imgCard}>
                      <img
                        className={classes.img}
                        src={item.thumbnail}
                        alt={item.slug}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={10} md={8} lg={9}>
                    <Typography variant="subtitle1" className={classes.judul}>
                      {capitalize(item.title)}
                    </Typography>
                    <Typography variant="subtitle2">
                      {capitalize(item.category)}
                    </Typography>
                    <Typography variant="caption">
                      {capitalize(item.creator)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={2} lg={1}>
                    <Button
                      component={CustomRouterLink}
                      to={`/admin/edit-artikel/${item.slug}`}
                      variant="contained"
                      fullWidth
                      color="secondary"
                    >
                      Edit
                    </Button>
                    <Button
                      className={classes.btnDelete}
                      variant="contained"
                      fullWidth
                      color="inherit"
                      onClick={() =>
                        setOpenDialogAlert({
                          open: true,
                          id: item.id,
                        })
                      }
                    >
                      Hapus
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))}
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.pagination}
          >
            <Grid item xs={12}>
              <Pagination
                count={article.last_page}
                page={page}
                onChange={handlePage}
                color="primary"
              />
            </Grid>
          </Grid>
          <DialogAlert
            dialog={openDialogAlert}
            onClose={() => {
              setOpenDialogAlert({ open: false, id: null });
            }}
          />
        </>
      ) : (
        <Card align="center">
          <CardContent>Artikel Kosong</CardContent>
        </Card>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    article: state.article,
  };
};

export default connect(mapStateToProps, { getArticle })(ManageArtikel);
