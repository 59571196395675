/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: 'none',
  },
  cardContent: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  image: {
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
    },
    position: 'relative',
  },
  media: {
    width: '100%',
    borderRadius: '10px',
  },
  btnKeranjang: {
    padding: theme.spacing(1, 3),
    width: '300px',
    fontWeight: 'bold',
    textTransform: 'none',
    color: '#fff',
    backgroundColor: '#EE6A0D',
    '&:hover': {
      backgroundColor: '#D63A00',
    },
    marginTop: 'auto',
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
  btnKeranjangDisabled: {
    padding: theme.spacing(1, 3),
    fontWeight: 'bold',
    width: '300px',
    textTransform: 'none',
    color: '#a6a6a6',
    backgroundColor: '#e0e0e0',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
    marginTop: 'auto',
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(2),
    },
    cursor: 'default',
  },
  textConteiner: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0',
      marginTop: theme.spacing(2),
    },
  },
  textPerkuliahan: {
    fontWeight: 700,
    fontSize: '26px',
    lineHeight: '37px',
  },
  textUniversitas: {
    fontSize: '24px',
    lineHeight: '35px',
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  textFakultas: {
    fontSize: '18px',
    lineHeight: '30px',
    fontWeight: 500,
  },
  textProdi: {
    fontSize: '16px',
    lineHeight: '25px',
    marginBottom: theme.spacing(1),
    fontWeight: 400,
  },
  textTime: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 400,
  },
  statusbg: {
    width: '70px',
    padding: theme.spacing(0.5),
    borderRadius: '0 3px 3px 0',
    position: 'absolute',
    left: '0px',
    bottom: '0px',
  },
  open: {
    backgroundColor: '#2cb978',
    color: '#fff',
  },
  close: {
    backgroundColor: '#c03546',
    color: '#fff',
  },
  soon: {
    backgroundColor: '#7d7d7d',
    color: '#fff',
  },
  textStatus: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '14px',
  },
}));

const timeClass = (period) => {
  return `${period.slice(0, 5)}-${period.slice(9, 14)}`;
};

const Header = ({ user, data }) => {
  const classes = useStyles();

  let logo = data.data?.logo;

  if (data.data?.course_image) {
    if (data.data.course_image !== '') {
      logo = data.data.course_image;
    }
  }

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Grid container>
          <div className={classes.image}>
            <img className={classes.media} src={logo} />
          </div>
          <div className={classes.textConteiner}>
            <Typography varinat="h5" className={classes.textPerkuliahan}>
              {data.data?.course_name}
            </Typography>
            <Typography varinat="h5" className={classes.textUniversitas}>
              {data.data?.university_name}
            </Typography>
            <Typography varinat="h5" className={classes.textFakultas}>
              {data.data?.faculty_name}
            </Typography>
            <Typography varinat="h5" className={classes.textProdi}>
              {data.data?.major_name}
            </Typography>
            <Typography varinat="h5" className={classes.textTime}>
              {data.data?.lecturer}
            </Typography>
            <Typography variant="body1" className={classes.textTime}>
              {data.data?.day} ,{' '}
              {data.data.period && timeClass(data.data.period)}
            </Typography>
          </div>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Header;
