import {
  ASSESSMENT_FAIL,
  ASSESSMENT_START,
  ASSESSMENT_SUCCESS,
} from "../../actions/mahasiswa/types";
import { updateObject } from "../../../utils";

const initialState = {
  data: null,
  loading: null,
};

const assessmentStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const assessmentSuccess = (state, action) => {
  return updateObject(state, { loading: false, data: action.payload });
};

const assessmentFail = (state, action) => {
  return updateObject(state, { loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ASSESSMENT_START:
      return assessmentStart(state, action);
    case ASSESSMENT_SUCCESS:
      return assessmentSuccess(state, action);
    case ASSESSMENT_FAIL:
      return assessmentFail(state, action);
    default:
      return state;
  }
};

export default reducer;
