/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Divider, Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "none",
    marginTop: theme.spacing(2),
  },
  image: {
    width: "150px",
  },
  media: {
    width: "100%",
    borderRadius: "10xp",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: theme.spacing(2),
  },
  textPerkuliahan: {
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "29px",
    cursor: "pointer",
  },
  textUniversitas: {
    fontSize: "18px",
    lineHeight: "27px",
    fontWeight: 400,
  },
  divider: {
    marginTop: theme.spacing(1),
    backgroundColor: "#263238",
    [theme.breakpoints.only("xs")]: {
      marginBottom: theme.spacing(4),
    },
  },
  statusContainer: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "300px",
    [theme.breakpoints.only("xs")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      justifyContent: "center",
      alignItems: "center",
    },
  },
  textStatus: {
    color: "#F8992E",
    fontWeight: "bold",
    textTransform: "capitalize",
    textAlign: "center",
  },
  textNote: {
    fontSize: "14px",
  },
}));

const timeClass = (period) => {
  return `${period.slice(0, 5)}-${period.slice(9, 14)}`;
};

const message = (status) => {
  if (status === "waiting") {
    return "Menunggu persetujuan Program Studi asal dan Program Studi tujuan";
  }
  if (status === "approved") {
    return "Selamat Program Studi pilihan anda telah disetujui oleh kedua kampus";
  }
  if (status === "rejected") {
    return "Maaf Program Studi pilihan anda tidak mendapatkan persetujuan";
  }
  if (status === "expired") {
    return "Maaf Program studi pilihan anda sudah kadaluarsa, silahkan pilih kelas kembali";
  }
  if (status === "finished") {
    return "Mata kuliah anda sudah selesai, silakan berikan rating dan komentar";
  }
};

const ListHistory = ({ list }) => {
  const classes = useStyles();
  const history = useHistory();

  const redirectToDetail = (uuid) => {
    history.push(`/riwayat/${uuid}`);
  };

  return (
    <>
      {list.data.map((data) => (
        <div key={data.id}>
          <Card className={classes.card}>
            <Grid container>
              <Grid item xs={5} sm={1} className={classes.image}>
                <img className={classes.media} src={data.university_logo} />
              </Grid>
              <Grid item xs={7} sm={8} className={classes.textContainer}>
                <Typography
                  varinat="h5"
                  className={classes.textPerkuliahan}
                  onClick={() => redirectToDetail(data.uuid)}
                >
                  {data.course_name}
                </Typography>
                <Typography varinat="h5" className={classes.textUniversitas}>
                  {data.university_name}
                </Typography>
                <Typography variant="body1" className={classes.textTime}>
                  {data.first_class_date}
                </Typography>
                <Typography variant="body1" className={classes.textTime}>
                  {timeClass(data.period)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} className={classes.statusContainer}>
                <Typography variant="h4" className={classes.textStatus}>
                  {data.status}
                </Typography>
                <Typography variant="body2" className={classes.textNote}>
                  {message(data.status)}
                </Typography>
              </Grid>
            </Grid>
          </Card>
          <Divider className={classes.divider} />
        </div>
      ))}
    </>
  );
};

export default ListHistory;
