import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
} from "@material-ui/core";

import TableUniversitas from "./component/TableUniversitas/TableUniversitas.jsx";
import TableMataKuliah from "./component/TableMataKuliah/TableMataKuliah.jsx";

import { connect } from "react-redux";
import {
  getDashboardChartMataKuliah,
  getDashboardChartUniversitas,
  printMataKuliah,
  printUniversitas,
  getProfileProdi,
} from "../../../store/actions/prodi";

import { Bar, Doughnut } from "react-chartjs-2";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },

  title: {
    fontWeight: 700,
    marginBottom: theme.spacing(5),
  },

  cardTitle: {
    fontWeight: 700,
    fontSize: 20,
  },

  cardContent: {
    fontWeight: 400,
    fontSize: 16,
  },

  cardActions: {
    display: "flex",
    justifyContent: "right",
  },
}));

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const Dashboard = ({
  getDashboardChartMataKuliah,
  dashboard,
  getDashboardChartUniversitas,
  printMataKuliah,
  printUniversitas,
  getProfileProdi,
  profileProdi,
}) => {
  const classes = useStyles();

  useEffect(() => {
    getDashboardChartMataKuliah();
    getDashboardChartUniversitas();
    getProfileProdi();
  }, [
    getDashboardChartMataKuliah,
    getDashboardChartUniversitas,
    getProfileProdi,
  ]);

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        {profileProdi.data?.university_name}
      </Typography>

      {/* Mata kuliah */}
      <Grid container spacing={2} style={{ marginBottom: 45 }}>
        <Grid item xs={12} lg={12}>
          <Card>
            <CardContent>
              <Bar data={dashboard.dataChartMataKuliah} options={options} />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} lg={12}>
          <Card>
            <CardHeader
              title={
                <Typography className={classes.cardTitle}>
                  Penjelasan Mata Kuliah
                </Typography>
              }
            ></CardHeader>
            <CardContent>
              <TableMataKuliah data={dashboard.dataMataKuliah} />
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button
                onClick={() => {
                  printMataKuliah();
                }}
                size="small"
                variant="contained"
                color="secondary"
              >
                Cetak
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>

      {/* Prodi */}
      <Grid container spacing={2} style={{ marginBottom: 45 }}>
        <Grid item xs={12} lg={12}>
          <Card>
            <CardHeader
              className={classes.cardTitle}
              title={
                <Typography className={classes.cardTitle}>
                  Perguruan tinggi paling popular / paling banyak di ambil
                  mahasiswa
                </Typography>
              }
            />

            <CardContent>
              <Doughnut data={dashboard.dataChartUniversitas} />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} lg={12}>
          <Card>
            <CardHeader
              title={
                <Typography className={classes.cardTitle}>
                  Penjelasan Perguruan Tinggi
                </Typography>
              }
            ></CardHeader>
            <CardContent>
              <TableUniversitas data={dashboard.dataUniversitas} />
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button
                onClick={() => {
                  printUniversitas();
                }}
                size="small"
                variant="contained"
                color="secondary"
              >
                Cetak
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>

      {/* Traffic */}
      {/* <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <Card>
            <CardContent>
              <Line data={dataLine} />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader
              title={
                <Typography className={classes.cardTitle}>
                  Penjelasan Traffic
                </Typography>
              }
            ></CardHeader>
            <CardContent>
              <Typography className={classes.cardContent}>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Dolorum, ipsam cupiditate doloribus vel deleniti officiis
                molestiae ea alias necessitatibus nisi. Amet iure id
                perspiciatis corporis blanditiis expedita exercitationem soluta
                minus.
              </Typography>
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button size="small" variant="contained" color="secondary">
                Cetak
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid> */}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    dashboard: state.dashboardProdi,
    profileProdi: state.profileProdi,
  };
};

export default connect(mapStateToProps, {
  getDashboardChartMataKuliah,
  getDashboardChartUniversitas,
  printMataKuliah,
  printUniversitas,
  getProfileProdi,
})(Dashboard);
