import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth";
import exportDetailMahasiswa from "./exportDetailMahasiswa";

import {
  article,
  banner,
  managementUser,
  universitas,
  filterAdmin,
  transaksi,
  detailMataKuliah,
  detailJumlahMahasiswaAdmin,
  dashboardAdmin,
} from "./admin";

// Mahasiswa
import {
  showBanner,
  profile,
  showArtikel,
  kategori,
  showUniversitas,
  showRekomendasi,
  perkuliahan,
  detailPerkuliahan,
  keranjang,
  filter,
  notifikasi,
  riwayat,
  assessment,
} from "./mahasiswa";

// Prodi
import {
  profileProdi,
  prodiPengambil,
  prodiPenyedia,
  detailJumlahMahasiswaProdi,
  dashboardProdi,
  prodiMataKuliah,
} from "./prodi";

export default combineReducers({
  alert,
  auth,
  article,
  banner,
  managementUser,
  showBanner,
  profile,
  universitas,
  showArtikel,
  profileProdi,
  kategori,
  showUniversitas,
  showRekomendasi,
  perkuliahan,
  detailPerkuliahan,
  keranjang,
  filter,
  notifikasi,
  riwayat,
  filterAdmin,
  transaksi,
  detailMataKuliah,
  detailJumlahMahasiswaAdmin,
  dashboardAdmin,
  prodiPengambil,
  prodiPenyedia,
  detailJumlahMahasiswaProdi,
  dashboardProdi,
  exportDetailMahasiswa,
  assessment,
  prodiMataKuliah,
});
