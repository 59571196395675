import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Slide,
  CardActions,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

// Component
import {
  FilterFakultas,
  FilterSemester,
  FilterProdi,
  FilterUniversitas,
} from "../index";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "#fff",
    fontWeight: "bold",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogFilter = ({
  open,
  handleClose,
  filter,
  setSelected,
  selected,
  getFilterFakultas,
  getFilterProdi,
}) => {
  const classes = useStyles();

  const handleFilter = () => {
    handleClose();
  };

  return (
    <Dialog
      fullScreen
      open={open}
      scroll="body"
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Filter Perkuliahan
          </Typography>
          <Button autoFocus color="inherit" onClick={handleClose}>
            Save
          </Button>
        </Toolbar>
      </AppBar>
      <Card>
        <CardContent>
          <FilterUniversitas
            title="Filter Berdasarkan Universitas"
            listFilter={filter.universitas}
            setSelected={setSelected}
            selected={selected}
            actionFakultas={getFilterFakultas}
            actionProdi={getFilterProdi}
          />
          {selected.universitas !== "" && (
            <FilterFakultas
              title="Filter Berdasarkan Fakultas"
              listFilter={filter.fakultas}
              setSelected={setSelected}
              selected={selected}
            />
          )}
          {selected.universitas !== "" && (
            <FilterProdi
              title="Filter Berdasarkan Program Studi"
              listFilter={filter.prodi}
              setSelected={setSelected}
              selected={selected}
            />
          )}
          {selected.universitas !== "" && (
            <FilterSemester
              title="Filter Berdasarkan Semester"
              listFilter={filter.semester}
              setSelected={setSelected}
              selected={selected}
            />
          )}
        </CardContent>
        <CardActions>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleFilter}
          >
            Save
          </Button>
        </CardActions>
      </Card>
    </Dialog>
  );
};

export default DialogFilter;
