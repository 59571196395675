import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
} from "@material-ui/core";

import { DataProfile, ImageProfile } from "./component";

import { getProfileProdi } from "../../../store/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  title: {
    fontWeight: 700,
    marginBottom: theme.spacing(5),
  },
  boxContainer: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  dataContainer: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(3),
    },
  },
}));

const Profile = ({ getProfileProdi, profileProdi }) => {
  const classes = useStyles();

  useEffect(() => {
    getProfileProdi();
  }, [getProfileProdi]);

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        Profile
      </Typography>
      <Card>
        <CardContent>
          <Grid container className={classes.boxContainer}>
            <Grid item md={8} xs={12} className={classes.dataContainer}>
              <DataProfile data={profileProdi} />
            </Grid>
            <Grid item md={4} xs={12}>
              <ImageProfile data={profileProdi} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  profileProdi: state.profileProdi,
});

export default connect(mapStateToProps, { getProfileProdi })(Profile);
