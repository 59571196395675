import * as actionTypes from "../../actions/admin/actionTypes";
import { updateObject } from "../../../utils";

const initialState = {
  loading: true,
  loadingUpload: false,
  data: [],
};

const setLoading = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const setLoadingUpload = (state, action) => {
  return updateObject(state, {
    loadingUpload: true,
  });
};

const setLoadingDone = (state, action) => {
  return updateObject(state, {
    loading: false,
    loadingUpload: false,
  });
};

const setBanner = (state, action) => {
  return updateObject(state, {
    loading: false,
    loadingUpload: false,
    data: action.payload,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LOADING:
      return setLoading(state, action);
    case actionTypes.SET_LOADING_UPLOAD:
      return setLoadingUpload(state, action);
    case actionTypes.SET_LOADING_DONE:
      return setLoadingDone(state, action);
    case actionTypes.SET_BANNER:
      return setBanner(state, action);
    default:
      return state;
  }
};

export default reducer;
