/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Card, Divider, Grid, Typography } from "@material-ui/core";

import { LoadKeranjang } from "../../../Components/Skeleton";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "none",
    marginTop: theme.spacing(2),
  },
  image: {
    width: "100%",
  },
  media: {
    width: "100%",
    borderRadius: "10xp",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: theme.spacing(2),
  },
  textPerkuliahan: {
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "29px",
  },
  textUniversitas: {
    fontSize: "18px",
    lineHeight: "27px",
    fontWeight: 400,
  },
  divider: {
    marginTop: theme.spacing(1),
    backgroundColor: "#263238",
    [theme.breakpoints.only("xs")]: {
      marginBottom: theme.spacing(4),
    },
  },
  btnContainer: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    [theme.breakpoints.only("xs")]: {
      justifyContent: "center",
      alignItems: "center",
    },
  },
  btnCheckout: {
    width: "150px",
    color: "#fff",
    backgroundColor: "#EE6A0D",
    "&:hover": {
      backgroundColor: "#D63A00",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  btnHapus: {
    marginTop: theme.spacing(1),
    width: "150px",
    color: "#EE6A0D",
    border: "1px solid #EE6A0D",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  btnHome: {
    marginTop: theme.spacing(2),
  },
}));

const message = (status) => {
  if (status === "waiting") {
    return "Menunggu persetujuan Program Studi asal dan Program Studi tujuan";
  }
  if (status === "approved") {
    return "Selamat Program Studi pilihan anda telah disetujui oleh kedua kampus";
  }
  if (status === "rejected") {
    return "Maaf Program Studi pilihan anda tidak mendapatkan persetujuan";
  }
  if (status === "expired") {
    return "Maaf Program studi pilihan anda sudah kadaluarsa, silahkan pilih kelas kembali";
  }
  if (status === "finished") {
    return "Mata kuliah anda sudah selesai, silakan berikan rating dan komentar";
  }
};

const ListItem = ({ list }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      {list.data.map((data) => (
        <div key={data.id}>
          <Card className={classes.card}>
            <Grid container>
              <Grid item xs={3} sm={1} className={classes.image}>
                <img
                  className={classes.media}
                  src={data.image !== "" ? data.image : data.university_logo}
                />
              </Grid>
              <Grid item xs={9} sm={11} className={classes.textContainer}>
                <Typography varinat="h5" className={classes.textPerkuliahan}>
                  {data.course_name}
                </Typography>
                <Typography varinat="h5" className={classes.textUniversitas}>
                  {message(data.status)}
                </Typography>
                <Typography variant="body1" className={classes.textTime}>
                  {data.created_at}
                </Typography>
              </Grid>
            </Grid>
          </Card>
          <Divider className={classes.divider} />
        </div>
      ))}

      {/* Handling Loading Data */}
      {list.loading &&
        Array(3)
          .fill("a")
          .map((_, index) => <LoadKeranjang key={index} />)}

      {/* Handle Empty data */}
      {list.data.length === 0 && !list.loading && (
        <>
          <Typography variant="h1">Oopps.... Tidak ada notifikasi</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push("/")}
            className={classes.btnHome}
          >
            Kembali ke Home
          </Button>
        </>
      )}
    </>
  );
};

export default ListItem;
