import React from "react";
import { Grid } from "@material-ui/core";
import { LoadText } from "../../../../../components/Skeleton";

import TextContent from "./TextContent";

const DataProfile = ({ data }) => {
  return (
    <Grid container>
      <Grid item xs={5}>
        <TextContent>NIM</TextContent>
        <TextContent>Nama Lengkap</TextContent>
        <TextContent>Fakultas</TextContent>
        <TextContent>Jurusan</TextContent>
        <TextContent>Kampus</TextContent>
        <TextContent>Periode Masuk</TextContent>
        <TextContent>Pendidikan Asal</TextContent>
        <TextContent>Kelas</TextContent>
        <TextContent>Kurikulum</TextContent>
        <TextContent>Konsentrasi /Bidang Studi</TextContent>
        <TextContent>Tipe Perkuliahan</TextContent>
      </Grid>
      <Grid item xs={7}>
        {data.loading ? (
          Array(9)
            .fill(1)
            .map((value, index) => (
              <TextContent key={index}>
                <LoadText />
              </TextContent>
            ))
        ) : (
          <>
            <TextContent>{data.data?.username}</TextContent>
            <TextContent>{data.data?.name}</TextContent>
            <TextContent>{data.data?.faculty_name}</TextContent>
            <TextContent>{data.data?.major_name}</TextContent>
            <TextContent>{data.data?.university_name}</TextContent>
            <TextContent>-</TextContent>
            <TextContent>-</TextContent>
            <TextContent>-</TextContent>
            <TextContent>-</TextContent>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default DataProfile;
