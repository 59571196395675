// SHOW BANNER
export const SHOW_BANNER_START = "SHOW_BANNER_START";
export const SHOW_BANNER_FAIL = "SHOW_BANNER_FAIL";
export const SET_SHOW_BANNER = "SET_SHOW_BANNER";

// PROFILE
export const PROFILE_START = "PROFILE_START";
export const PROFILE_FAIL = "PROFILE_FAIL";
export const SET_PROFILE = "SET_PROFILE";

// Artikel
export const SHOW_ARTIKEL_START = "SHOW_ARTIKEL_START";
export const SHOW_ARTIKEL_FAIL = "SHOW_ARTIKEL_FAIL";
export const SET_SHOW_ARTIKEL = "SET_SHOW_ARTIKEL";

export const SHOW_ONE_ARTIKEL_START = "SHOW_ONE_ARTIKEL_START";
export const SHOW_ONE_ARTIKEL_FAIL = "SHOW_ONE_ARTIKEL_FAIL";
export const SET_ONE_SHOW_ARTIKEL = "SET_ONE_SHOW_ARTIKEL";

export const NEWS_ARTIKEL_START = "NEWS_ARTIKEL_START";
export const NEWS_ARTIKEL_FAIL = "NEWS_ARTIKEL_FAIL";
export const SET_NEWS_ARTIKEL = "SET_NEWS_ARTIKEL";

// KATEGORI
export const KATEGORI_UNIVERSITAS_START = "KATEGORI_UNIVERSITAS_START";
export const KATEGORI_UNIVERSITAS_SUCCESS = "KATEGORI_UNIVERSITAS_SUCCESS";
export const KATEGORI_UNIVERSITAS_FAIL = "KATEGORI_UNIVERSITAS_FAIL";
export const KATEGORI_FAKULTAS_START = "KATEGORI_FAKULTAS_START";
export const KATEGORI_FAKULTAS_SUCCESS = "KATEGORI_FAKULTAS_SUCCESS";
export const KATEGORI_FAKULTAS_FAIL = "KATEGORI_FAKULTAS_FAIL";
export const KATEGORI_PRODI_START = "KATEGORI_PRODI_START";
export const KATEGORI_PRODI_SUCCESS = "KATEGORI_PRODI_SUCCESS";
export const KATEGORI_PRODI_FAIL = "KATEGORI_PRODI_FAIL";

// UNIVERSITAS
export const SHOW_UNIVERSITAS_START = "SHOW_UNIVERSITAS_START";
export const SHOW_UNIVERSITAS_SUCCESS = "SHOW_UNIVERSITAS_SUCCESS";
export const SHOW_UNIVERSITAS_FAIL = "SHOW_UNIVERSITAS_FAIL";

// REKOMENDASI
export const SHOW_REKOMENDASI_START = "SHOW_REKOMENDASI_START";
export const SHOW_REKOMENDASI_SUCCESS = "SHOW_REKOMENDASI_SUCCESS";
export const SHOW_REKOMENDASI_FAIL = "SHOW_REKOMENDASI_FAIL";

// PERKULIAHAN
export const PERKULIAHAN_START = "PERKULIAHAN_START";
export const PERKULIAHAN_SUCCESS = "PERKULIAHAN_SUCCESS";
export const PERKULIAHAN_FAIL = "PERKULIAHAN_FAIL";

// PERKULIAHAN
export const DETAIL_PERKULIAHAN_START = "DETAIL_PERKULIAHAN_START";
export const DETAIL_PERKULIAHAN_SUCCESS = "DETAIL_PERKULIAHAN_SUCCESS";
export const DETAIL_PERKULIAHAN_FAIL = "DETAIL_PERKULIAHAN_FAIL";
export const DETAIL_PER_WEEK_START = "DETAIL_PER_WEEK_START";
export const DETAIL_PER_WEEK_SUCCESS = "DETAIL_PER_WEEK_SUCCESS";
export const DETAIL_PER_WEEK_FAIL = "DETAIL_PER_WEEK_FAIL";
export const SET_SEARCH_NAME_PERKULIAHAN = "SET_SEARCH_NAME_PERKULIAHAN";

// KERANJANG
export const KERANJANG_START = "KERANJANG_START";
export const KERANJANG_SUCCESS = "KERANJANG_SUCCESS";
export const KERANJANG_FAIL = "KERANJANG_FAIL";
export const KERANJANG_SEND_LOAD = "KERANJANG_SEND_LOAD";
export const KERANJANG_SEND_FINISH = "KERANJANG_SEND_FINISH";

// FILTER
export const FILTER_UNIVERSITAS_START = "FILTER_UNIVERSITAS_LOAD";
export const FILTER_UNIVERSITAS_SUCCESS = "FILTER_UNIVERSITAS_SUCCESS";
export const FILTER_UNIVERSITAS_FAIL = "FILTER_UNIVERSITAS_FAIL";
export const FILTER_FAKULTAS_START = "FILTER_FAKULTAS_LOAD";
export const FILTER_FAKULTAS_SUCCESS = "FILTER_FAKULTAS_SUCCESS";
export const FILTER_FAKULTAS_FAIL = "FILTER_FAKULTAS_FAIL";
export const FILTER_PRODI_START = "FILTER_PRODI_LOAD";
export const FILTER_PRODI_SUCCESS = "FILTER_PRODI_SUCCESS";
export const FILTER_PRODI_FAIL = "FILTER_PRODI_FAIL";

// Notifikasi
export const NOTIFIKASI_START = "NOTIFIKASI_START";
export const NOTIFIKASI_SUCCESS = "NOTIFIKASI_SUCCESS";
export const NOTIFIKASI_FAIL = "NOTIFIKASI_FAIL";

// Riwayat
export const RIWAYAT_START = "RIWAYAT_START";
export const RIWAYAT_SUCCESS = "RIWAYAT_SUCCESS";
export const RIWAYAT_FAIL = "RIWAYAT_FAIL";
export const RIWAYAT_DETAIL_START = "RIWAYAT_DETAIL_START";
export const RIWAYAT_DETAIL_SUCCESS = "RIWAYAT_DETAIL_SUCCESS";
export const RIWAYAT_DETAIL_FAIL = "RIWAYAT_DETAIL_FAIL";

// ASSESSMENT
export const ASSESSMENT_START = "ASSESSMENT_START"
export const ASSESSMENT_SUCCESS = "ASSESSMENT_SUCCESS"
export const ASSESSMENT_FAIL = "ASSESSMENT_FAIL"