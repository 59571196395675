import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  textContent: {
    padding: theme.spacing(1, 0),
  },
}));

const TextContent = ({ children }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.textContent}>
        <Typography variant="body1">{children}</Typography>
      </div>
      <Divider />
    </>
  );
};

export default TextContent;
