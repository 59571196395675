import {
  SET_TRANSAKSI_START,
  SET_TRANSAKSI_SUCCESS,
  SET_TRANSAKSI_FAIL,
  SET_PRINT_START,
  SET_PRINT_FAIL,
  SET_PRINT_SUCCESS,
} from "../../actions/admin/actionTypes";
import { updateObject } from "../../../utils";

const initialState = {
  loading: false,
  data: [],
  error: false,
  loadingPrint: false,
  errorPrint: false,
};

const transaksiStart = (state, action) => {
  return updateObject(state, {
    ...state,
    loading: true,
    error: false,
  });
};

const transaksiSuccess = (state, action) => {
  return updateObject(state, {
    data: action.payload,
    current_page: action.current_page,
    has_previous_page: action.has_previous_page,
    has_next_page: action.has_next_page,
    previous_page: action.previous_page,
    next_page: action.next_page,
    last_page: action.last_page,
    total_item: action.total_item,
    loading: false,
    error: false,
  });
};

const transaksiFail = (state, action) => {
  return updateObject(state, {
    ...state,
    loading: false,
    error: true,
  });
};

const printStart = (state, action) => {
  return updateObject(state, {
    ...state,
    loadingPrint: true,
    errorPrint: false,
  });
};

const printSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    loadingPrint: false,
    errorPrint: false,
  });
};

const printFail = (state, action) => {
  return updateObject(state, {
    ...state,
    loadingPrint: false,
    errorPrint: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TRANSAKSI_START:
      return transaksiStart(state, action);
    case SET_TRANSAKSI_SUCCESS:
      return transaksiSuccess(state, action);
    case SET_TRANSAKSI_FAIL:
      return transaksiFail(state, action);
    case SET_PRINT_START:
      return printStart(state, action);
    case SET_PRINT_FAIL:
      return printFail(state, action);
    case SET_PRINT_SUCCESS:
      return printSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
