/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Hidden,
  Typography,
  Container,
  Button,
} from "@material-ui/core";
import queryString from "query-string";

import {
  getFilterUniversitas,
  getFilterFakultas,
  getFilterProdi,
  getPerkuliahan,
} from "../../../store/actions/mahasiswa";

import {
  DialogFilter,
  FilterUniversitas,
  FilterFakultas,
  FilterProdi,
  FilterSemester,
} from "./component";
import { ListPerkuliahan } from "../Components";

const useStyles = makeStyles((theme) => ({
  textTitle: {
    fontWeight: "bold",
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  breadcrumb: {
    marginLeft: theme.spacing(1),
  },
  paddingContainer: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down("md")]: {
      padding: "0px",
    },
  },
  btnContainer: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  btnFilter: {
    color: "#fff",
    backgroundColor: "#EE6A0D",
    "&:hover": {
      backgroundColor: "#D63A00",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
}));

const Matakuliah = ({
  filter,
  getFilterUniversitas,
  getFilterFakultas,
  getFilterProdi,
  perkuliahan,
  getPerkuliahan,
}) => {
  const limit = 200
  const classes = useStyles();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const [openDialog, setOpenDialog] = useState(false);
  const [selected, setSelected] = useState({
    universitas: "",
    fakultas: "",
    prodi: "",
    semester: "",
  });

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    getFilterUniversitas();
    if (queryParams.universitas) {
      getFilterFakultas(queryParams.universitas);
      getFilterProdi(queryParams.universitas);
    }
    setSelected({
      universitas: queryParams.universitas || "",
      fakultas: queryParams.fakultas || "",
      prodi: queryParams.prodi || "",
      semester: parseInt(queryParams.semester) || "",
    });
  }, [location.search]);

  useEffect(() => {
    getPerkuliahan(
      selected.universitas,
      selected.fakultas,
      selected.prodi,
      selected.semester,
      perkuliahan.search,
      "",
      limit
    );
  }, [selected, perkuliahan.search]);

  return (
    <Container maxWidth="lg">
      <Grid container>
        <Hidden mdDown>
          <Grid item xs={3}>
            <FilterUniversitas
              title="Filter Berdasarkan Universitas"
              listFilter={filter.universitas}
              setSelected={setSelected}
              selected={selected}
              actionFakultas={getFilterFakultas}
              actionProdi={getFilterProdi}
            />
            {selected.universitas !== "" && (
              <FilterFakultas
                title="Filter Berdasarkan Fakultas"
                listFilter={filter.fakultas}
                setSelected={setSelected}
                selected={selected}
              />
            )}
            {selected.universitas !== "" && (
              <FilterProdi
                title="Filter Berdasarkan Program Studi"
                listFilter={filter.prodi}
                setSelected={setSelected}
                selected={selected}
              />
            )}
            {selected.universitas !== "" && (
              <FilterSemester
                title="Filter Berdasarkan Semester"
                listFilter={filter.semester}
                setSelected={setSelected}
                selected={selected}
              />
            )}
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={9}>
          <Box className={classes.paddingContainer}>
            <Typography variant="h4" className={classes.textTitle}>
              Perkuliahan
            </Typography>
            {/* <Breadcrumb className={classes.breadcrumb} /> */}
            <Hidden lgUp>
              <div className={classes.btnContainer}>
                <Button
                  variant="contained"
                  className={classes.btnFilter}
                  onClick={handleDialogOpen}
                >
                  Filter Perkuliahan
                </Button>
              </div>
            </Hidden>
            <ListPerkuliahan lg={3} md={4} sm={4} xs={6} list={perkuliahan} />
          </Box>
        </Grid>
      </Grid>
      <DialogFilter
        open={openDialog}
        handleClose={handleDialogClose}
        filter={filter}
        setSelected={setSelected}
        selected={selected}
        getFilterFakultas={getFilterFakultas}
        getFilterProdi={getFilterProdi}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  filter: state.filter,
  perkuliahan: state.perkuliahan,
});

export default connect(mapStateToProps, {
  getFilterUniversitas,
  getFilterFakultas,
  getFilterProdi,
  getPerkuliahan,
})(Matakuliah);
