import {
  DETAIL_PERKULIAHAN_START,
  DETAIL_PERKULIAHAN_SUCCESS,
  DETAIL_PERKULIAHAN_FAIL,
  DETAIL_PER_WEEK_START,
  DETAIL_PER_WEEK_SUCCESS,
  DETAIL_PER_WEEK_FAIL,
} from "../../actions/mahasiswa/types";
import { updateObject } from "../../../utils";

const initialState = {
  detail: {
    data: {},
    loading: false,
    error: false,
  },
  table: {
    data: [],
    loading: false,
    error: false,
  },
};

const detailPerkuliahanStart = (state, action) => {
  return updateObject(state, {
    detail: { data: {}, loading: true, error: false },
  });
};

const detailPerkuliahanSuccess = (state, action) => {
  return updateObject(state, {
    detail: { data: action.payload, loading: false, error: false },
  });
};

const detailPerkuliahanFail = (state, action) => {
  return updateObject(state, {
    detail: { data: {}, loading: false, error: true },
  });
};

const detailPerWeekStart = (state, action) => {
  return updateObject(state, {
    table: { data: [], loading: true, error: false },
  });
};

const detailPerWeekSuccess = (state, action) => {
  return updateObject(state, {
    table: { data: action.payload, loading: false, error: false },
  });
};

const detailPerWeekFail = (state, action) => {
  return updateObject(state, {
    table: { data: [], loading: false, error: true },
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DETAIL_PERKULIAHAN_START:
      return detailPerkuliahanStart(state, action);
    case DETAIL_PERKULIAHAN_SUCCESS:
      return detailPerkuliahanSuccess(state, action);
    case DETAIL_PERKULIAHAN_FAIL:
      return detailPerkuliahanFail(state, action);
    case DETAIL_PER_WEEK_START:
      return detailPerWeekStart(state, action);
    case DETAIL_PER_WEEK_SUCCESS:
      return detailPerWeekSuccess(state, action);
    case DETAIL_PER_WEEK_FAIL:
      return detailPerWeekFail(state, action);
    default:
      return state;
  }
};

export default reducer;
