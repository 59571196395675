import { SHOW_BANNER_FAIL, SHOW_BANNER_START, SET_SHOW_BANNER } from "./types";
import axios from "../../../api/axios-orders";
import { setAlert } from "../alert";

export const getShowBanner = () => async (dispatch) => {
  dispatch({
    type: SHOW_BANNER_START,
  });
  try {
    const response = await axios.get("/api/banner", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({
      type: SET_SHOW_BANNER,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({
      type: SHOW_BANNER_FAIL,
      payload: error.response.message,
    });
    dispatch(setAlert("Can't get data banner", "error"));
  }
};
