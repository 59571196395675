import { SET_PROFILE, PROFILE_FAIL, PROFILE_START } from "./types";
import axios from "../../../api/axios-orders";
import { setAlert } from "../alert";

export const getProfile = () => async (dispatch) => {
  dispatch({
    type: PROFILE_START,
  });
  try {
    const response = await axios.get("/student/profile/student", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({
      type: SET_PROFILE,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({
      type: PROFILE_FAIL,
      payload: error.response.message,
    });
    dispatch(setAlert("Can't get data profile", "error"));
  }
};
