import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";

import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import SignOutIcon from "@material-ui/icons/Input";
import MoreIcon from "@material-ui/icons/MoreVert";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { Link } from "react-router-dom";
import {
  Divider,
  Typography,
  AppBar,
  Toolbar,
  Box,
  IconButton,
  MenuItem,
  Avatar,
} from "@material-ui/core";

import { connect } from "react-redux";
import { capitalize } from "../../../../utils";

// Default Avatar
import DefaultAvatar from "../../../../assets/img/default-avatar.png";

const drawerWidth = 240;
const appDrawerBlue = "#0263C4";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  content: {
    flexGrow: 1,
    paddingTop: 56,
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 64,
    },
  },
  name_logo: {
    color: "#000000",
    textDecoration: "none",
  },
  profileName: {
    borderRadius: "10%",
    width: "auto",
    padding: 0,
  },
  logo: {
    width: "auto",
    height: 50,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  logo_min: {
    width: "auto",
    height: 30,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - 73px)`,
    },
    boxShadow: "none",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: appDrawerBlue,
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
    },
  },
  appBarShift: {
    marginLeft: drawerWidth,
    boxShadow: "none",
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: "100%",
    },
  },
  menuButton: {
    marginRight: 36,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    color: "#fff",
  },
  menuButtonMobile: {
    marginRight: 36,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    color: "#fff",
  },
  flexGrow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  profileMenu: {
    marginTop: theme.spacing(2),
  },
  divider: {
    height: 45,
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    color: "#000",
    width: "fit-content",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
  },
  avatar: {
    marginRight: theme.spacing(1),
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  textNone: {
    textDecoration: "none",
  },
  activeAccount: {
    color: "red",
  },
}));

const Appbar = (props) => {
  const { handleDrawerOpen, handleDrawerMobileOpen, open, auth } = props;

  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const onLogoutHandler = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("data");
    localStorage.removeItem("role");
    localStorage.clear();
    history.push("/login-aptisi");
  };

  const onLogout = () => {
    handleMenuClose();
    handleMobileMenuClose();
    onLogoutHandler();
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      className={classes.profileMenu}
    >
      <Link to="/ganti-password" className={classes.textNone}>
        <MenuItem onClick={handleMenuClose}>Ganti Password</MenuItem>
      </Link>
      <MenuItem onClick={onLogout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <Box textAlign="left">
          <Typography style={{ fontWeight: "bold" }} variant="h5">
            {capitalize(auth.data.name)}
          </Typography>
          <Typography variant="h6">{auth.data.role}</Typography>
        </Box>
      </MenuItem>
      <Divider />
      <Link to="/aptisi/profile" className={classes.textNone}>
        <MenuItem onClick={handleMobileMenuClose}>
          <IconButton aria-label="change-pw" color="inherit">
            <AccountCircleIcon />
          </IconButton>
          <p>My Profile</p>
        </MenuItem>
      </Link>
      <MenuItem onClick={onLogout}>
        <IconButton aria-label="logout" color="inherit">
          <SignOutIcon />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar>
        <IconButton
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        {/* Button Drawer for mobile */}
        <IconButton
          aria-label="open drawer"
          onClick={handleDrawerMobileOpen}
          edge="start"
          className={classes.menuButtonMobile}
        >
          <MenuIcon />
        </IconButton>
        <div className={classes.flexGrow} />

        <div className={classes.sectionDesktop}>
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton href="/aptisi/profile" className={classes.profileName}>
            <Avatar
              alt="my-avatar"
              className={classes.avatar}
              src={DefaultAvatar}
            />
            <Box textAlign="left">
              <Typography
                display="block"
                style={{ color: "#fff", fontWeight: "bold" }}
                variant="h5"
              >
                {capitalize(auth.data.name)}
              </Typography>
              <Typography style={{ color: "#fff" }} variant="h6">
                {capitalize(auth.role)}
              </Typography>
            </Box>
          </IconButton>
        </div>
        <div className={classes.sectionMobile}>
          <IconButton
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
          >
            <MoreIcon style={{ color: "#fff" }} />
          </IconButton>
        </div>
      </Toolbar>
      {renderMobileMenu}
      {renderMenu}
    </AppBar>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Appbar);
