import React from "react";
import moment from "moment"
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Typography,
} from "@material-ui/core";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#0263C4",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
});

const TableMateri = ({ data }) => {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <StyledTableCell>No</StyledTableCell>
            <StyledTableCell align="center">Deskripsi</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.data.map((data, index) => (
            <StyledTableRow key={data.id}>
              <StyledTableCell>{index + 1}</StyledTableCell>
              <StyledTableCell>
                <Typography>{`Minggu ke-${data.week}`}</Typography>
                <Typography>{`Tanggal ${moment(data.date).format("DD-MM-YYYY")}`}</Typography>
                <Typography>{data.detail}</Typography>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableMateri;
