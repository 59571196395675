import {
  KERANJANG_START,
  KERANJANG_SUCCESS,
  KERANJANG_FAIL,
  KERANJANG_SEND_LOAD,
  KERANJANG_SEND_FINISH,
} from "./types";
import axios from "../../../api/axios-orders";
import { setAlert } from "../alert";
import { getNotifikasi } from "./notifikasi";
import { getRiwayat } from "./riwayat";

export const getKeranjang = () => async (dispatch) => {
  dispatch({
    type: KERANJANG_START,
  });
  try {
    const response = await axios.get(`/student/cart`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    if (response.data.data === null) {
      dispatch({
        type: KERANJANG_SUCCESS,
        payload: [],
        total: 0,
      });
    } else {
      dispatch({
        type: KERANJANG_SUCCESS,
        payload: response.data.data,
        total: response.data.data.length,
      });
    }
  } catch (error) {
    dispatch({
      type: KERANJANG_FAIL,
      // payload: error.response.message,
    });
    dispatch(setAlert("Can't get data cart", "error"));
  }
};

export const addKeranjang = (data, openDialog) => async (dispatch) => {
  dispatch({
    type: KERANJANG_SEND_LOAD,
  });
  try {
    await axios.post(`/student/cart`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({
      type: KERANJANG_SEND_FINISH,
    });
    dispatch(getKeranjang());
    openDialog();
    dispatch(setAlert("Success add to cart", "success"));
  } catch (error) {
    if (error.response.data.message === "class already in cart") {
      dispatch({
        type: KERANJANG_SEND_FINISH,
      });
      dispatch(setAlert("Kelas sudah ada di keranjang anda", "error"));
    } else if (
      error.response.data.message === "you've already take the class"
    ) {
      dispatch({
        type: KERANJANG_SEND_FINISH,
      });
      dispatch(setAlert("Anda sudah mengambil kelas ini", "error"));
    } else {
      dispatch({
        type: KERANJANG_FAIL,
        payload: error.response.message,
      });
      dispatch(setAlert("Can't add item to cart", "error"));
    }
  }
};

export const deleteKeranjang = (id) => async (dispatch) => {
  try {
    await axios.delete(`/student/cart/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch(getKeranjang());
    dispatch(setAlert("cart item has been deleted", "success"));
  } catch (error) {
    dispatch({
      type: KERANJANG_FAIL,
      payload: error.response.message,
    });
    dispatch(setAlert("something wen't wrong", "error"));
  }
};

export const checkoutKeranjang = (data, openDialog) => async (dispatch) => {
  try {
    await axios.post(`/student/transaction/checkout`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch(getKeranjang());
    dispatch(setAlert("Success", "success"));
    dispatch(getNotifikasi());
    dispatch(getRiwayat());
    openDialog();
  } catch (error) {
    if (error.response.data.message === "Kelas Sudah dipilih") {
      dispatch(setAlert("Anda sudah mengambil kelas ini", "error"));
    } else {
      dispatch(setAlert(error.response.data.message, "error"));
    }
  }
};
