import {
  SET_USER,
  SET_LOADING_DONE,
  SET_LOADING,
} from "../../actions/admin/actionTypes";
import { updateObject } from "../../../utils";

const initialState = {
  data: [],
  loading: false,
  last_page: 1,
};

const setStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const setDone = (state, action) => {
  return updateObject(state, { loading: false });
};

const setUser = (state, action) => {
  return updateObject(state, {
    data: action.payload.data,
    loading: false,
    last_page: action.payload.last_page,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return setUser(state, action);
    case SET_LOADING:
      return setStart(state, action);
    case SET_LOADING_DONE:
      return setDone(state, action);
    default:
      return state;
  }
};

export default reducer;
