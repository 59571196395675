import {
  SET_DATA_MATA_KULIAH,
  SET_DATA_UNIVERSITAS,
  SET_DASHBOARD_CHART_MATA_KULIAH_START,
  SET_DASHBOARD_CHART_MATA_KULIAH_SUCCESS,
  SET_DASHBOARD_CHART_MATA_KULIAH_FAIL,
  SET_DASHBOARD_CHART_UNIVERSITAS_START,
  SET_DASHBOARD_CHART_UNIVERSITAS_SUCCESS,
  SET_DASHBOARD_CHART_UNIVERSITAS_FAIL,
} from "./types";
import axios from "../../../api/axios-orders";
import { setAlert } from "../alert";
import fileDownload from "js-file-download";

export const setDashboardChartMataKuliahStart = () => {
  return {
    type: SET_DASHBOARD_CHART_MATA_KULIAH_START,
  };
};

export const setDashboardChartUniversitasStart = () => {
  return {
    type: SET_DASHBOARD_CHART_UNIVERSITAS_START,
  };
};

export const getDashboardChartMataKuliah = () => async (dispatch) => {
  dispatch(setDashboardChartMataKuliahStart());
  try {
    const res = await axios.get(`/major/transaction/chart/course `, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    dispatch({
      type: SET_DATA_MATA_KULIAH,
      payload: res.data.data,
    });

    let labels = [];
    let data = [];
    let backgroundColor = [];
    let borderColor = [];
    res.data.data.forEach(function (e) {
      var randomColor = Math.floor(Math.random() * 16777215).toString(16);
      let forLabels = e.course_name;
      let forData = e.total;
      let forBackgroundColor = "#" + randomColor;
      let forBorderColor = "#fff";
      labels.push(forLabels);
      data.push(forData);
      backgroundColor.push(forBackgroundColor);
      borderColor.push(forBorderColor);
    });
    const newData = {
      labels: labels,
      datasets: [
        {
          label: "Mata kuliah paling populer / paling banyak diambil perguruan tinggi mitra",
          data: data,
          backgroundColor: backgroundColor,
          borderColor: borderColor,
          borderWidth: 1,
        },
      ],
    };

    dispatch({
      type: SET_DASHBOARD_CHART_MATA_KULIAH_SUCCESS,
      payload: newData,
    });
  } catch (error) {
    dispatch({
      type: SET_DASHBOARD_CHART_MATA_KULIAH_FAIL,
      payload: error.response.data.message,
    });
    dispatch(setAlert("Can't get chart subject!", "error"));
  }
};

export const getDashboardChartUniversitas = () => async (dispatch) => {
  dispatch(setDashboardChartUniversitasStart());
  try {
    const res = await axios.get(`/major/transaction/chart/taker `, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    dispatch({
      type: SET_DATA_UNIVERSITAS,
      payload: res.data.data,
    });

    let labels = [];
    let data = [];
    let backgroundColor = [];
    let borderColor = [];
    res.data.data.forEach(function (e) {
      var randomColor = Math.floor(Math.random() * 16777215).toString(16);
      let forLabels = e.provider_university_name;
      let forData = e.count;
      let forBackgroundColor = "#" + randomColor;
      let forBorderColor = "#fff";
      labels.push(forLabels);
      data.push(forData);
      backgroundColor.push(forBackgroundColor);
      borderColor.push(forBorderColor);
    });

    const newData = {
      labels: labels,
      datasets: [
        {
          label: "Universitas",
          data: data,
          backgroundColor: backgroundColor,
          borderColor: borderColor,
          borderWidth: 1,
        },
      ],
    };

    dispatch({
      type: SET_DASHBOARD_CHART_UNIVERSITAS_SUCCESS,
      payload: newData,
    });
  } catch (error) {
    dispatch({
      type: SET_DASHBOARD_CHART_UNIVERSITAS_FAIL,
      payload: error.response.data.message,
    });
    dispatch(setAlert("Can't get data chart major!", "error"));
  }
};

export const printMataKuliah = () => async (dispatch) => {
  try {
    const res = await axios.get(`major/export/pdf/transaction/course/chart `, {
      headers: {
        "Content-Type": `application/pdf`,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      responseType: "blob",
    });
    fileDownload(res.data, "mata_kuliah.pdf");
  } catch (error) {
    dispatch(setAlert("Export Failed", "error"));
  }
};

export const printUniversitas = () => async (dispatch) => {
  try {
    const res = await axios.get(`major/export/pdf/transaction/taker/chart`, {
      headers: {
        "Content-Type": `application/pdf`,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      responseType: "blob",
    });
    fileDownload(res.data, "mata_kuliah.pdf");
  } catch (error) {
    dispatch(setAlert("Export Failed", "error"));
  }
};
