import {
  AUTH_START,
  AUTH_FAIL,
  AUTH_MAHASISWA_PRODI_SUCCESS,
  AUTH_ADMIN_APTISI_SUCCESS,
  AUTH_GUEST_SUCCESS,
  AUTH_LOGOUT,
  SET_LOADING_PASSWORD_DONE,
  SET_LOADING_PASSWORD,
} from "../actions/actionTypes";
import { updateObject } from "../../utils";

const initialState = {
  token: localStorage.getItem("access_token") || null,
  token_sia: localStorage.getItem("token_sia") || null,
  role: localStorage.getItem("role") || null,
  data: JSON.parse(localStorage.getItem("data")) || null,
  loading: false,
  loadingPassword: false,
  error: null,
};

const authStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const authMahasiswaProdiSuccess = (state, action) => {
  return updateObject(state, {
    token: action.payload.token.access_token,
    token_sia: action.payload.token_sia,
    role: action.payload.role,
    loading: false,
  });
};

const authAdminAptisiSuccess = (state, action) => {
  return updateObject(state, {
    token: action.payload.token.access_token,
    data: action.payload.data,
    role: action.payload.data.role,
    loading: false,
  });
};

const authGuestSuccess = (state, action) => {
  return updateObject(state, {
    token: action.payload.token.access_token,
    data: action.payload.data,
    role: "guest",
  });
};

const authFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    token: null,
    role: null,
    data: null,
    loading: false,
    error: null,
  });
};

const setLoadingPassword = (state, action) => {
  return updateObject(state, { loadingPassword: true });
};

const setLoadingPasswordDone = (state, action) => {
  return updateObject(state, { loadingPassword: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_START:
      return authStart(state, action);
    case AUTH_MAHASISWA_PRODI_SUCCESS:
      return authMahasiswaProdiSuccess(state, action);
    case AUTH_ADMIN_APTISI_SUCCESS:
      return authAdminAptisiSuccess(state, action);
    case AUTH_FAIL:
      return authFail(state, action);
    case AUTH_GUEST_SUCCESS:
      return authGuestSuccess(state, action);
    case AUTH_LOGOUT:
      return authLogout(state, action);
    case SET_LOADING_PASSWORD:
      return setLoadingPassword(state, action);
    case SET_LOADING_PASSWORD_DONE:
      return setLoadingPasswordDone(state, action);
    default:
      return state;
  }
};

export default reducer;
