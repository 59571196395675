import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Hidden } from "@material-ui/core";

import { Footer, AppBarMobile, BottomNavigation, Navbar } from "./component";

import { authLogout } from "../../store/actions";
import { setSearch } from "../../store/actions/mahasiswa";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
  },
  main: {
    paddingTop: "70px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "60px",
    },
  },
  footer: {
    marginTop: theme.spacing(15),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(7),
    },
  },
}));

const MainMahasiswa = ({ children, auth, authLogout, setSearch }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* ---------- NAVBAR WEBSITE ---------- */}
      <header>
        <Hidden smDown>
          <Navbar auth={auth} authLogout={authLogout} />
        </Hidden>
        <Hidden mdUp>
          <AppBarMobile authLogout={authLogout} setSearch={setSearch} />
        </Hidden>
      </header>

      {/* ---------- CONTENT WEBISTE ---------- */}
      <main className={classes.main}>{children}</main>

      {/* ---------- FOOTER WEBSITE */}
      <footer className={classes.footer}>
        <Footer />
      </footer>

      {/* BOTTOM NAVIGATION */}
      <div>
        <Hidden mdUp>
          <BottomNavigation auth={auth} />
        </Hidden>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { authLogout, setSearch })(
  React.memo(MainMahasiswa)
);
