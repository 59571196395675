/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  Typography,
  IconButton,
  Card,
  CardMedia,
  Grid,
  CardContent,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Rating } from "@material-ui/lab";
import LinesEllipsis from "react-lines-ellipsis";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "none",
  },
  cardContent: {
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  image: {
    width: "260px",
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
    },
    position: "relative",
  },
  media: {
    width: "100%",
    borderRadius: "10px",
  },
  textConteiner: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0",
      marginTop: theme.spacing(2),
    },
  },
  textPerkuliahan: {
    fontWeight: 700,
    fontSize: "26px",
    lineHeight: "37px",
  },
  textProdi: {
    fontSize: "16px",
    lineHeight: "25px",
    marginBottom: theme.spacing(1),
    fontWeight: 400,
  },

  containerTextDesc: {
    marginTop: theme.spacing(7),
    [theme.breakpoints.only("xs")]: {
      marginTop: theme.spacing(2),
    },
  },
  containerTextDetail: {
    marginTop: theme.spacing(7),
    [theme.breakpoints.only("xs")]: {
      marginTop: theme.spacing(5),
    },
  },
  textDesc: {
    fontWeight: "bold",
  },
  cardDesc: {
    marginTop: theme.spacing(1),
  },
}));

const DialogPreview = ({ dialog, onClose }) => {
  const classes = useStyles();
  return (
    <Dialog open={dialog.open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle onClose={onClose}>Preview Detail Mata Kuliah</DialogTitle>
      <DialogContent dividers>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Grid container>
              <div className={classes.image}>
                <img className={classes.media} src={dialog.data?.image} />
              </div>
              <div className={classes.textConteiner}>
                <Typography varinat="h5" className={classes.textPerkuliahan}>
                  {dialog.data?.name}
                </Typography>
                <Typography varinat="h5" className={classes.textProdi}>
                  {dialog.data?.major_name}
                </Typography>
              </div>
            </Grid>
          </CardContent>
        </Card>
        <div className={classes.containerTextDesc}>
          <Typography variant="h5" className={classes.textDesc}>
            Deskripsi Perkuliahan
          </Typography>
        </div>
        <Card className={classes.cardDesc}>
          <CardContent>
            <Typography variant="body1" align="justify">
              {dialog.data?.description}
            </Typography>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default DialogPreview;
